import React from "react";
import PropTypes from "prop-types";
import { useMenu } from "hooks/useMenuFooter";

function StatusTag({ color, label, className }) {
  const { isMobile } = useMenu();

  if (isMobile && label === "En attente de validation") {
    label = "En attente";
  }
  return (
    <div
      className={`inline-block px-2.5 py-1.5 rounded text-white text-center whitespace-nowrap text-xs ${color} ${className}`}
    >
      {label}
    </div>
  );
}
StatusTag.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
};

export default StatusTag;
