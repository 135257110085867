import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import iconDelete from "images/icons/delete.svg";
import iconView from "images/icons/view.svg";
import iconDownload from "images/icons/download.svg";
import iconDeleteGray from "images/icons/delete-gray.svg";
import Button from "components/Button";
import { ReactComponent as ArrowIcon } from "images/icons/arrow.svg";
import { useAuth } from "providers/AuthProvider";
import { formatDate, formatFileSize } from "functions";
import DocumentPropDropdown from "components/MyFileTabs/DocumentPropDropdown";
import { ReactComponent as AddIcon } from "images/icons/selectIcon-add.svg";
import { ReactComponent as SendIcon } from "images/icons/send.svg";
import { ReactComponent as LoadingIcon } from "images/icons/loading.svg";
import { ReactComponent as DocsIcon } from "images/icons/selectIcon-docs.svg";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import FieldBlockDocument from "components/FieldBlock/FieldBlockDocument";
import StatusTag from "components/Status/StatusTag";
import {
  typesDocumentsGestionnaire,
  typesDocumentsPartenaire,
} from "constants/typesDocuments";
import { statutsDocuments } from "constants/statutsDocuments";
import useFetchApi from "hooks/useFetchApi";
import { useConfirm } from "components/ConfirmDialog";
import ConditionChecker from "components/helpers/ConditionChecker";
import { useMenu } from "hooks/useMenuFooter";
import TruncatedFileName from "components/helpers/TruncatedFileName";
import documentListLabel from "constants/documentListLabel";

function getStatusBackground(statut) {
  return statutsDocuments.find(({ label }) => label === statut)?.color ?? "";
}

function DocumentList({
  title,
  sendButton,
  withState = true,
  dossier,
  documents,
  fetchFilesData,
  id,
}) {
  const { isManager, isPartner, user } = useAuth();
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortedDocuments, setSortedDocuments] = useState([]);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [sendError, setSendError] = useState(false);
  const [fetchApi] = useFetchApi();
  const typeOptions = useMemo(
    () =>
      [
        ...(isPartner ? typesDocumentsPartenaire : typesDocumentsGestionnaire),
        { label: "–", value: "Ajouter type", Icon: AddIcon, disable: true },
      ].filter((row) => !row.isVisible || row.isVisible(dossier)),
    [dossier, isPartner],
  );
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [confirmDeleteDocument, confirmDeleteDocumentDialog] = useConfirm();
  const { isMobile } = useMenu();
  const documentsSorégies = title === "Les documents Sorégies";

  useEffect(() => {
    // Filter documents based on selected filters
    const filteredDocuments = documents.filter((document) => {
      if (selectedFilters.length === 0) {
        return true; // No filters selected, show all documents
      }
      return selectedFilters.includes(document.attributes.type);
    });

    // Sort filtered documents based on the sorting order and date
    const sorted = [...filteredDocuments].sort((a, b) => {
      return sortOrder === "asc"
        ? a.attributes.createdAt.localeCompare(b.attributes.createdAt)
        : b.attributes.createdAt.localeCompare(a.attributes.createdAt);
    });
    setSortedDocuments(sorted);
  }, [documents, sortOrder, selectedFilters]);

  const handleFilterChange = (selectedOptions) => {
    setSelectedFilters(selectedOptions);
  };

  const toggleSelectAll = () => {
    if (selectedDocuments.length === sortedDocuments.length) {
      setSelectedDocuments([]);
    } else {
      setSelectedDocuments(sortedDocuments.map((doc) => doc.id));
    }
  };

  const handleDocumentSelect = (documentId) => {
    setSelectedDocuments((prevSelected) => {
      if (prevSelected.includes(documentId)) {
        return prevSelected.filter((id) => id !== documentId);
      } else {
        return [...prevSelected, documentId];
      }
    });
  };

  const handleDateImportClick = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleDelete = (documentIdArray, documentName) => {
    if (documentIdArray.length === 0) {
      return;
    }
    confirmDeleteDocument({
      text: documentName ? "Supprimer le fichier" : "Supprimer les fichiers",
      subText: (
        <>
          Êtes-vous{" "}
          {user?.title === "M."
            ? "sûr"
            : user?.title === "Mme"
              ? "sûre"
              : "sûr(e)"}{" "}
          de vouloir supprimer {documentName || "les fichiers sélectionnés"}
          &nbsp;?
        </>
      ),
      confirmLabel: "Supprimer",
      confirmColor: "red",
      cancelLabel: "Annuler",
      action: async () => {
        await fetchApi(`document/delete-documents`, {
          body: { documentIds: documentIdArray },
        });
        fetchFilesData();
      },
    });
  };

  const handleSendFiles = async (documentIdArray) => {
    if (documentIdArray.length === 0) {
      return;
    }
    try {
      setSent(false);
      setSendError(false);
      setSending(true);
      await fetchApi(`envoyer-les-fichiers/${id}`, {
        body: { documentIds: documentIdArray },
      });
      setSending(false);
      setSent(true);
      setSelectedDocuments([]);
    } catch (error) {
      setSendError(true);
      // console.error("Error sending document:", error);
      setSelectedDocuments([]);
    }
  };

  function DocumentListViewMobile() {
    return (
      <div className="flex flex-col gap-2">
        {sortedDocuments.map((document, index) => {
          const matchingType = typesDocumentsPartenaire.find(
            (type) => type.label === document.attributes.type,
          );

          // Si aucune correspondance, utiliser "Autre document"
          const documentType = matchingType || {
            label: documentListLabel["autre-document"],
            Icon: DocsIcon,
          };

          return (
            <div
              className="grid pt-4 pb-6 px-3 rounded-md border border-Gris-500-Soregies grid-cols-auto gap-2 items-center text-xs grid-rows-auto grid-flow-row"
              key={index}
            >
              <div className="flex flex-row gap-2 p-2 text-Bleu-fonc-Soregies row-start-1 col-start-1  border col-span-5 rounded-md border-Bleu-fonc-Soregies items-center justify-start">
                <documentType.Icon className="h-4" />
                {documentType.label}
              </div>
              <TruncatedFileName
                className="row-start-2 col-span-2 text-Bleu-fonc-Soregies whitespace-nowrap"
                filename={document.attributes.nom}
                maxLength={5}
              />
              <span className="col-span-2 text-Gris-500-Soregies">
                {formatDate(document.attributes.createdAt)}
              </span>
              <span className="col-span-1 text-Gris-500-Soregies">
                {formatFileSize(document.attributes.taille)}
              </span>
              <ConditionChecker condition={!documentsSorégies}>
                <StatusTag
                  className="row-start-1 col-span-2 justify-self-end"
                  label={document.attributes.statut}
                  color={getStatusBackground(document.attributes.statut)}
                />
              </ConditionChecker>
              <div
                className={`col-span-1 gap-2 col-start-7 ${documentsSorégies && "row-start-1"}   flex justify-end`}
              >
                <button
                  onClick={() =>
                    window.open(document.attributes.lien, "_blank")
                  }
                >
                  <img src={iconView} alt="view" className="max-w-none" />
                </button>
                <button
                  onClick={() =>
                    window.open(
                      document.attributes.lien_telechargement,
                      "_blank",
                    )
                  }
                >
                  <img
                    src={iconDownload}
                    alt="download"
                    className="max-w-none"
                  />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  if (!documents) {
    return <div>No files</div>;
  } else {
    return (
      <div>
        {confirmDeleteDocumentDialog}

        <div className="w-full flex justify-between mb-2">
          <h1 className=" text-xs md:text-lg mb-3 font-semibold">{title}</h1>
          <MultiSelectDropdown
            options={typeOptions}
            selectedValues={selectedFilters}
            onChange={handleFilterChange}
            placeholder="Filter by document type"
          />
        </div>

        <ConditionChecker condition={sortedDocuments.length === 0}>
          <div className="flex justify-center">
            <p>Il n&apos;y a pas de fichier</p>
          </div>
        </ConditionChecker>

        <ConditionChecker condition={sortedDocuments.length > 0 && !isMobile}>
          <table className="document-list border-separate border-spacing-y-0 w-full">
            <thead>
              <tr className="document header text-xs text-main-color">
                {isManager && (
                  <th className="text-left pr-3 border-b-[1px]">
                    <input
                      type="checkbox"
                      checked={
                        selectedDocuments.length === sortedDocuments.length &&
                        sortedDocuments.length !== 0
                      }
                      onChange={toggleSelectAll}
                    />
                  </th>
                )}
                <th className="text-left p-3 font-semibold border-b-[1px]">
                  Nom du fichier
                </th>
                <th className="text-left p-3 font-semibold border-b-[1px]">
                  Type de document
                </th>
                {withState && (
                  <th className="text-left p-3 font-semibold border-b-[1px]">
                    État
                  </th>
                )}
                <th className="text-left p-3 font-semibold border-b-[1px]">
                  Taille
                </th>
                <th
                  className="text-left p-3 font-semibold cursor-pointer border-b-[1px]"
                  onClick={handleDateImportClick}
                >
                  Date d&apos;import
                  <ArrowIcon
                    className={
                      sortOrder === "asc"
                        ? "w-[9px] inline-block ml-2"
                        : "w-[9px] inline-block ml-2 rotate-180"
                    }
                    alt=""
                  />
                </th>
                <th className="text-right border-b-[1px]" colSpan="3">
                  {isManager && (
                    <button
                      className=""
                      onClick={() => handleDelete(selectedDocuments)}
                    >
                      <img
                        src={
                          selectedDocuments.length !== 0
                            ? iconDelete
                            : iconDeleteGray
                        }
                        alt="delete"
                      />
                    </button>
                  )}
                </th>
              </tr>
            </thead>

            <tbody>
              {sortedDocuments.map((document) => (
                <tr key={document.id} className="document-row text-sm">
                  {isManager && (
                    <td className="w-[30px] border-b-[1px]">
                      <input
                        type="checkbox"
                        checked={selectedDocuments.includes(document.id)}
                        onChange={() => handleDocumentSelect(document.id)}
                      />
                    </td>
                  )}
                  <td className="px-3 py-5 border-b-[1px]">
                    {/* {document.attributes.nom} */}
                    {isManager ? (
                      <FieldBlockDocument
                        name={"nom"}
                        value={document.attributes.nom}
                        id={document.id}
                        fetchFilesData={fetchFilesData}
                      />
                    ) : (
                      document.attributes.nom
                    )}
                  </td>
                  <td className="px-3 py-5 border-b-[1px]">
                    {isManager ? (
                      <DocumentPropDropdown
                        property="type"
                        label="Sélectionner un type"
                        options={typeOptions}
                        value={document.attributes.type}
                        id={document.id}
                        fetchFilesData={fetchFilesData}
                      />
                    ) : document.attributes.type === "Ajouter type" ? (
                      "–"
                    ) : (
                      document.attributes.type
                    )}
                  </td>
                  {withState && (
                    <td className="px-3 py-5 border-b-[1px]">
                      {isManager ? (
                        <DocumentPropDropdown
                          label="Choisissez un état de validation"
                          property="statut"
                          options={statutsDocuments}
                          value={document.attributes.statut}
                          id={document.id}
                          fetchFilesData={fetchFilesData}
                        />
                      ) : (
                        <StatusTag
                          label={document.attributes.statut}
                          color={getStatusBackground(
                            document.attributes.statut,
                          )}
                        />
                      )}
                    </td>
                  )}
                  <td className="w-[110px] text-right px-3 py-5 border-b-[1px]">
                    {formatFileSize(document.attributes.taille)}
                  </td>
                  <td className="w-[130px] px-3 py-5 border-b-[1px] border-b-[1px]">
                    {document.attributes.createdAt.slice(0, 10)}
                  </td>
                  <td className="w-[25px] text-right border-b-[1px]">
                    <button
                      onClick={() =>
                        window.open(
                          document.attributes.lien_telechargement,
                          "_blank",
                        )
                      }
                    >
                      <img
                        src={iconDownload}
                        alt="download"
                        className="max-w-none"
                      />
                    </button>
                  </td>
                  {isManager && (
                    <td className="w-[25px] text-right border-b-[1px]">
                      <button
                        onClick={() =>
                          handleDelete([document.id], document.attributes.nom)
                        }
                      >
                        <img
                          src={iconDelete}
                          alt="delete"
                          className="max-w-none"
                        />
                      </button>
                    </td>
                  )}

                  <td className="w-[25px] text-right border-b-[1px]">
                    <button
                      onClick={() =>
                        window.open(document.attributes.lien, "_blank")
                      }
                    >
                      <img src={iconView} alt="view" className="max-w-none" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ConditionChecker>

        <ConditionChecker condition={sortedDocuments.length > 0 && isMobile}>
          <DocumentListViewMobile />
        </ConditionChecker>

        <ConditionChecker
          condition={sendButton && isManager && sortedDocuments.length > 0}
        >
          <div className="mt-3">
            <Button
              label="Envoyer les fichiers"
              icon={sending ? <LoadingIcon /> : <SendIcon />}
              variant="contained"
              disabled={selectedDocuments.length === 0}
              onClick={() => handleSendFiles(selectedDocuments)}
            />
            {sent && (
              <p className="text-xs mt-2 text-green font-semibold">
                Email envoyé !{" "}
              </p>
            )}
            {sendError && (
              <p className="text-xs mt-2 text-red-500 font-semibold">
                Erreur ! Veuillez réessayer plus tard
              </p>
            )}
          </div>
        </ConditionChecker>
      </div>
    );
  }
}
DocumentList.propTypes = {
  title: PropTypes.string.isRequired,
  sendButton: PropTypes.bool,
  withState: PropTypes.bool,
  dossier: PropTypes.instanceOf(Object).isRequired,
  documents: PropTypes.arrayOf(Object).isRequired,
  fetchFilesData: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

export default DocumentList;
