import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import useFetchApi from "hooks/useFetchApi";
import usePartenairesOptions from "hooks/usePartenairesOptions";
import Button from "components/Button";
import { BlockSelect } from "./UtilisateursDetails";
import Roles from "constants/Roles";
import titlesUser from "constants/titlesUser";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/Form";
import formErrors from "constants/formErrors";
import { z } from "zod";
import {
  formatPhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { zodResolver } from "@hookform/resolvers/zod";
import { PhoneInput } from "components/PhoneInput";

function Input({ label, name, control, className, type = "text" }) {
  return (
    <FormField
      name={name}
      control={control}
      render={({ field }) => (
        <FormItem>
          <FormControl>
            <div
              className={`flex flex-col w-[450px] mt-3 gap-2 ${className ?? ""}`}
            >
              <label className="text-main-color text-sm" htmlFor={name}>
                {label} {name !== "telephone" && "*"}
              </label>
              <input
                type={type}
                id={name}
                className="w-full border border-stone-400 rounded py-3 px-2 text-sm"
                placeholder={label}
                required={name !== "telephone"}
                {...field}
              />
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.object,
  className: PropTypes.string,
  type: PropTypes.string,
};

function Resend({ body }) {
  const [fetchApi] = useFetchApi();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const resendEmail = async () => {
    document.activeElement?.blur();
    try {
      setError("");
      setSuccess(false);
      setLoading(true);
      await fetchApi("re-send-email", { body });
      setSuccess(true);
    } catch ({ message }) {
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <button onClick={resendEmail} className="mt-1 underline">
        Renvoyer un lien de connexion
      </button>
      <div className="mt-1">
        {loading && <p className="text-light-color">Envoi en cours...</p>}
        {success && <p className="text-green">Success! </p>}
        {error && <p>{error}</p>}
      </div>
    </div>
  );
}
Resend.propTypes = {
  body: PropTypes.object,
};

const formSchema = z.object({
  title: z.enum(["M.", "Mme"], {
    message: formErrors.requiredErrorMessage,
  }),
  name: z.string({ message: formErrors.requiredErrorMessage }),
  firstname: z.string({ message: formErrors.requiredErrorMessage }),
  email: z
    .string({ message: formErrors.requiredErrorMessage })
    .email({ message: formErrors.emailErrorMessage }),
  telephone: z
    .string()
    .refine(isValidPhoneNumber, { message: formErrors.phoneErrorMessage }),
  role: z.enum(
    Object.values(Roles).map((role) => role.value),
    {
      message: formErrors.requiredErrorMessage,
    },
  ),
  partenaire: z.number().nullable(),
});
export default function CreateUserDialog({ onClose, refresh }) {
  const form = useForm({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      title: "M.",
      name: "",
      firstname: "",
      email: "",
      telephone: "",
      role: "",
      partenaire: null,
    },
  });
  const [fetchApi] = useFetchApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [body, setBody] = useState("");
  const [tokenAPI, setTokenAPI] = useState("");
  const { partenairesOptions } = usePartenairesOptions();

  const onSubmit = async (data) => {
    // We blur the active form so that the user can't submit it twice
    // by pressing the enter key, when showing the overlay.
    document.activeElement?.blur();
    try {
      data.telephone = formatPhoneNumber(data.telephone).replace(/\s/g, "");
      setError("");
      setSuccess(false);
      setBody(data);
      setLoading(true);
      const res = await fetchApi("create-user", {
        body: data,
      });
      refresh();
      setSuccess(true);
      setTokenAPI(res.data);
    } catch ({ message }) {
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-[80%] max-w-[537px] p-8 bg-white rounded border relative flex items-center flex-col">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <button
            type="button"
            onClick={onClose}
            className="text-xs underline absolute right-4 top-4 z-20"
          >
            Fermer
          </button>
          <div className="w-full text-center">
            <h2 className="text-xl font-semibold text-main-color my-4">
              Ajoutez un nouvel utilisateur
            </h2>
          </div>
          <FormField
            name="title"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <BlockSelect
                    label="Titre"
                    name="title"
                    type="select"
                    options={titlesUser}
                    handleBody={({ value }) => {
                      field.onChange(value);
                    }}
                    edit={true}
                    border={"border-0.5 border-stone-400"}
                    value={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Input
            label="Prénom"
            name="firstname"
            required
            control={form.control}
          />
          <Input label="Nom" name="name" required control={form.control} />
          <Input
            label="Adresse mail"
            name="email"
            type="email"
            control={form.control}
          />
          <FormField
            name="telephone"
            control={form.control}
            render={({ field }) => (
              <FormItem className="mt-3">
                <FormLabel className="text-sm font-normal text-Bleu-fonc-Soregies">
                  Numéro de téléphone*
                </FormLabel>
                <FormControl>
                  <PhoneInput
                    defaultCountry={"FR"}
                    label="Numéro de téléphone"
                    name="telephone"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="role"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <BlockSelect
                    label="Rôle"
                    name="role"
                    type="select"
                    options={Roles}
                    handleBody={({ value }) => {
                      field.onChange(value);
                    }}
                    value={field.value}
                    edit={true}
                    border={"border-0.5 border-stone-400"}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="partenaire"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <BlockSelect
                    label="Entreprise partenaire"
                    name="partenaire"
                    type="select"
                    options={partenairesOptions}
                    handleBody={({ value }) => {
                      field.onChange(value);
                    }}
                    value={field.value}
                    edit={true}
                    border={"border-0.5 border-stone-400"}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="col-span-2">
            <Button
              label="Créer l’utilisateur"
              className="mx-auto mt-7 mb-2"
              variant="contained"
              type="submit"
            />
          </div>
          {/* <span className="absolute left-3.5 bottom-3.5 text-xs text-light-color">
        *champs obligatoire
      </span> */}
        </form>
      </Form>
      {(loading || error || success) && (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-white rounded flex items-center justify-center p-5">
          {loading && (
            <span className="w-12 h-12 border-4 border-blue border-b-transparent rounded-full animate-spin inline-block box-border"></span>
          )}
          {error && (
            <div className="text-red-500 text-center text-lg font-semibold">
              {typeof error === "string"
                ? error
                : "Une erreur est survenue lors de la création de l'utilisateur!"}
            </div>
          )}
          {success && (
            <div className="items-center gap-6 text-center">
              <div className="text-main-color text-xl font-semibold">
                Un lien à bien été envoyé à <br />{" "}
                {body ? body.email : "votre adresse mail"}
              </div>
              <div className="text-xs mt-4">
                <p>Le lien n’a pas été reçu ? </p>

                <Resend
                  body={{
                    email: body.email,
                    token: tokenAPI,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
CreateUserDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};
