import { React, useState } from "react";
import ArrowUp from "../images/icons/arrowUp.svg";
import PropTypes from "prop-types";

export default function Tab({ title, icon, children, borderStyle }) {
  const [open, setOpen] = useState(true);
  return (
    <div className={`${borderStyle} mt-5`}>
      <div
        onClick={() => {
          setOpen(!open);
        }}
        className={`w-full bg-light-color flex justify-between px-4 py-2 cursor-pointer text-main-color ${borderStyle && open ? "rounded-t-md" : "rounded-md"}`}
      >
        <span className="flex space-x-4 whitespace-nowrap">
          {icon && <img src={icon} className="mr-3" alt="icon" />}
          {title}
        </span>

        <img src={ArrowUp} className={open ? "" : "rotate-180"} alt="arrow" />
      </div>
      <div className={open ? "py-4 px-1" : "hidden"}>{children}</div>
    </div>
  );
}

Tab.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.any,
  borderStyle: PropTypes.string,
};
