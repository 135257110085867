import React from "react";
import LastViewFiles from "components/LastViewFiles";
import HomeNews from "components/Pages/HomeNews";
import Infographic from "components/Indicateur/Infographic";
import Main from "components/Main";
import LastViewSimulations from "components/LastViewSimulations";
import { useAuth } from "providers/AuthProvider";
import Dossiers from "components/Dossiers";

export default function Home() {
  const { isCustomer, isManager, isPartner } = useAuth();
  return (
    <div className="flex flex-1 flex-row gap-4 overflow-y-hidden">
      {isPartner ? (
        <Dossiers />
      ) : (
        <Main className="flex-1">
          {isCustomer && <LastViewSimulations />}
          {(isCustomer || isManager) && <LastViewFiles />}
          {isManager && <Infographic layout="home" />}
        </Main>
      )}
      {(isManager || isPartner) && (
        <Main className="flex-0">
          <HomeNews />
        </Main>
      )}
    </div>
  );
}
