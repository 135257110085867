import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Button from "components/Button";
import { ReactComponent as CheckIcon } from "images/icons/check.svg";
import useFetchApi from "hooks/useFetchApi";
import { Navigate } from "react-router-dom";
import { Form, FormLabel } from "./Form";
import { z } from "zod";
import formErrors from "constants/formErrors";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormControl, FormField, FormItem, FormMessage } from "components/Form";
import {
  isValidPhoneNumber,
  formatPhoneNumber,
} from "react-phone-number-input";
import { cn } from "./helpers/utils";
import { PhoneInput } from "./PhoneInput";

const formSchema = z.object({
  civilite: z.enum(["Monsieur", "Madame"]),
  nom: z.string({ message: formErrors.requiredErrorMessage }),
  prenom: z.string({ message: formErrors.requiredErrorMessage }),
  email: z
    .string({ message: formErrors.requiredErrorMessage })
    .email({ message: formErrors.emailErrorMessage }),
  telephone: z
    .string()
    .refine(isValidPhoneNumber, { message: formErrors.phoneErrorMessage }),
  adresse: z.string({ message: formErrors.requiredErrorMessage }),
  code_postal: z
    .string({ message: formErrors.requiredErrorMessage })
    .refine((value) => /^\d{5}$/.test(value), {
      message: "Code postal invalide",
    }),
  ville: z.string({ message: formErrors.requiredErrorMessage }),
  pays: z.string(),
  etat: z.string(),
});

function Input({
  label,
  name,
  className,
  type = "text",
  required = true,
  control,
}) {
  return (
    <FormField
      name={name}
      control={control}
      render={({ field }) => (
        <FormItem>
          <FormControl>
            <div className={cn("flex flex-col gap-2 ", className)}>
              <label className="text-main-color text-sm" htmlFor={name}>
                {label}
                {required ? "*" : ""}
              </label>
              <input
                type={type}
                className="w-full border rounded h-10 px-2 text-sm"
                placeholder={label}
                required={required}
                {...field}
              />
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.boolean,
  control: PropTypes.object,
};

export default function NewFileDialog({ onClose }) {
  const [fetchApi] = useFetchApi();
  const form = useForm({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      civilite: "Madame",
      nom: "",
      prenom: "",
      email: "",
      telephone: "",
      adresse: "",
      code_postal: "",
      ville: "",
      pays: "",
      etat: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [resultID, setResultID] = useState(1);
  const onSubmit = async (data) => {
    // We blur the active form so that the user can't submit it twice
    // by pressing the enter key, when showing the overlay.
    document.activeElement?.blur();
    try {
      setLoading(true);
      const body = {
        ...data,
        telephone: formatPhoneNumber(data.telephone).replace(/ /g, ""), // making sure the phone number is in the right format
      };
      const result = await fetchApi("create-dossier", { body });
      // Trigger a revalidation (refetch) of the "Mes dossiers" list.
      setResultID(result.dossier_id ? result.dossier_id : 1);

      setSuccess(true);
      // onClose();
    } catch ({ message }) {
      setError(message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="w-[80%] max-w-[537px] p-8 grid grid-cols-2 bg-white rounded border relative gap-3"
      >
        <button
          type="button"
          onClick={onClose}
          className="text-xs underline absolute right-4 top-4 z-20"
        >
          Fermer
        </button>
        <div className="col-span-2">
          <h2 className="text-2xl font-semibold text-main-color mb-3">
            Création de dossier
          </h2>
        </div>
        <FormField
          name="civilite"
          control={form.control}
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormLabel className="text-main-color text-sm font-normal">
                Civilité*
              </FormLabel>
              <FormControl>
                <div className="flex gap-6 text-sm text-main-color accent-blue">
                  <label htmlFor="madame" className="flex gap-2">
                    <input
                      {...field}
                      type="radio"
                      value="Madame"
                      id="madame"
                      checked={field.value === "Madame"}
                      className="bg-main-color"
                      required
                    />
                    Madame
                  </label>
                  <label htmlFor="monsieur" className="flex gap-2">
                    <input
                      {...field}
                      type="radio"
                      value="Monsieur"
                      id="monsieur"
                      checked={field.value === "Monsieur"}
                      required
                    />
                    Monsieur
                  </label>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Input label="Prénom" name="prenom" control={form.control} />
        <Input label="Nom" name="nom" control={form.control} />
        <Input
          label="Adresse mail"
          name="email"
          type="email"
          control={form.control}
        />
        <FormField
          name="telephone"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-main-color text-sm font-normal">
                Numéro de Téléphone*
              </FormLabel>
              <FormControl>
                <PhoneInput
                  name="telephone"
                  defaultCountry={"FR"}
                  {...field}
                  national
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Input
          label="Adresse postale"
          name="adresse"
          className="col-span-2"
          control={form.control}
        />
        <Input label="Code postal" name="code_postal" control={form.control} />
        <Input label="Ville" name="ville" control={form.control} />
        <Input
          label="État / Province / Région"
          name="etat"
          control={form.control}
          required={false}
        />
        <Input
          label="Pays"
          name="pays"
          control={form.control}
          required={false}
        />
        <div className="col-span-2">
          <Button
            label="Valider le dossier"
            className="mx-auto mt-4"
            variant="contained"
            type="submit"
          />
        </div>
        <span className="absolute left-3.5 bottom-3.5 text-xs text-light-color">
          *champs obligatoire
        </span>
        {(loading || error || success) && (
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-white rounded flex items-center justify-center p-5">
            {loading && (
              <span className="w-12 h-12 border-4 border-blue border-b-transparent rounded-full animate-spin inline-block box-border"></span>
            )}
            {error && (
              <div className="text-red-500 text-center text-2xl font-semibold">
                Une erreur est survenue lors de la création du dossier !
              </div>
            )}
            {success && (
              <div className="flex text-main-color items-center gap-6">
                <CheckIcon />
                <div className="text-center text-2xl font-semibold">
                  Le dossier a bien été créé
                </div>
                <Navigate to={`/dossiers/${resultID}`} />
              </div>
            )}
          </div>
        )}
      </form>
    </Form>
  );
}
NewFileDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};
