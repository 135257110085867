import { React } from "react";
import Button from "./Button";
import { ReactComponent as RetryIcon } from "../images/icons/retry.svg";
import PropTypes from "prop-types";

export default function Loader({ isLoading, error }) {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="w-full flex-1 flex justify-center items-center text-center min-h-[600px]">
      {error && !isLoading ? (
        <div className="text-red-500">
          <h2 className="font-semibold text-2xl text-red-500">
            Une erreur est survenue
          </h2>
          <p className="mt-2 text-xl font-semibold">
            Veuillez contacter votre administrateur.
          </p>
          <p className="text-xs text-stone-400 my-2">{error.message}</p>
          <div className="flex items-center justify-center">
            <Button
              label="Refresh Page"
              icon={<RetryIcon />}
              variant="contained"
              color="blue"
              onClick={handleRefresh}
              className="text-sm mt-4"
            />
          </div>
        </div>
      ) : null}
      {isLoading && (
        <span className="w-12 h-12 border-4 border-blue border-b-transparent rounded-full animate-spin inline-block box-border"></span>
      )}
    </div>
  );
}

Loader.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.object,
};
