import React, { useState } from "react";
import PropTypes from "prop-types";
import StatusDone from "images/icons/status-done.svg";
import StatusDoneGrey from "images/icons/status-done-grey.svg";
import StatusSelected from "images/icons/status-selected.svg";
import StatusNext from "images/icons/status-next.svg";
import StatusNotYet from "images/icons/status-notyet.png";
import { useAuth } from "providers/AuthProvider";
import { useForm } from "react-hook-form";

// Styles constants
const HR_STYLES = {
  DONE: "mt-[-20px] border-1 border-solid border-green",
  NEXT: "mt-[-20px] border-1 border-dashed border-green",
  NOT_YET: "mt-[-20px] border-1 border-solid border-lightgray",
  TRANSPARENT: "mt-[-20px] border-1 border-solid border-white",
};

// Utils
const getStatusIdByLabel = (label, list) => {
  const status = list.find((status) => status.label === label);
  return status ? status.id : null;
};

// Custom hook to manage status timeline logic
const useStatusTimelineLogic = ({
  id,
  status,
  statusList,
  updateStatus,
  setError,
}) => {
  const { register, handleSubmit } = useForm();
  const [current, setCurrent] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const { isManager, role } = useAuth();
  const canChangeStatus = isManager && role !== "Consultation";
  const currentID = getStatusIdByLabel(status, statusList);

  const handleSelect = (e) => {
    setCurrent(e.target.value);
  };

  const onSubmit = async () => {
    if (isSubmitting || current === status || !current) return;

    setSubmitting(true);
    document.activeElement?.blur();
    try {
      setError("");
      await updateStatus(id, current);
      setCurrent(undefined);
    } catch (error) {
      const errorMessage = `${error?.message || ""} ${error?.data?.join(", ") || ""}`;
      setError(errorMessage);
      console.warn("Error updating file:", error);
    }
    setSubmitting(false);
  };

  return {
    current,
    currentID,
    canChangeStatus,
    register,
    handleSubmit,
    handleSelect,
    onSubmit,
  };
};

// Status Item Component
const StatusItem = ({
  item,
  status,
  current,
  currentID,
  canChangeStatus,
  register,
  handleSelect,
}) => {
  const getStatusImage = () => {
    if (status === "Abandon") return StatusDoneGrey;
    if (current === item.label) return StatusSelected;
    if (currentID === item.id)
      return item.id === item.statusListLength ? StatusDone : StatusNext;
    if (currentID > item.id) return StatusDone;
    return StatusNotYet;
  };

  const getHrStyle = () => {
    if (status === "Abandon") return HR_STYLES.NOT_YET;

    if (currentID - 1 === item.id) {
      return item.id === item.statusListLength - 1
        ? HR_STYLES.DONE
        : HR_STYLES.NEXT;
    }

    if (currentID > item.id) return HR_STYLES.DONE;

    return item.id === item.statusListLength
      ? HR_STYLES.TRANSPARENT
      : HR_STYLES.NOT_YET;
  };

  const getLabelStyle = () => {
    const baseStyle =
      "break-words overflow-hidden max-w-16 text-xs text-start flex flex-shrink";

    if (status === "Abandon")
      return `${baseStyle} text-status-disabled font-semibold`;
    if (current === item.label)
      return `${baseStyle} text-main-color font-semibold`;
    if (currentID >= item.id) return `${baseStyle} text-green font-semibold`;
    if (currentID + 1 === item.id) return `${baseStyle} text-neutral-400`;

    return `${baseStyle} text-neutral-400`;
  };

  return (
    <div className="grow" key={item.id}>
      <label className={canChangeStatus ? "cursor-pointer" : ""}>
        <img src={getStatusImage()} alt="status" />

        <div className="mt-2 mb-5">
          <hr className={getHrStyle()} />
        </div>

        <input
          {...register("statut")}
          type="radio"
          value={item.label}
          name="statut"
          className="hidden"
          onChange={canChangeStatus ? handleSelect : () => null}
        />
        <span className={getLabelStyle()}>{item.label}</span>
      </label>
    </div>
  );
};

StatusItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    label: PropTypes.string.isRequired,
    statusListLength: PropTypes.number.isRequired,
  }).isRequired,
  status: PropTypes.string.isRequired,
  current: PropTypes.string,
  currentID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  canChangeStatus: PropTypes.bool.isRequired,
  register: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default function StatusTimeline({
  id,
  statusList,
  status,
  updateStatus,
  error,
  setError,
}) {
  const {
    current,
    currentID,
    canChangeStatus,
    register,
    handleSubmit,
    handleSelect,
    onSubmit,
  } = useStatusTimelineLogic({
    id,
    status,
    statusList,
    updateStatus,
    setError,
  });

  const renderStatusItems = () => {
    return statusList.map((item) => (
      <StatusItem
        key={item.id}
        item={{ ...item, statusListLength: statusList.length }}
        status={status}
        current={current}
        currentID={currentID}
        canChangeStatus={canChangeStatus}
        register={register}
        handleSelect={handleSelect}
      />
    ));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="grid md:flex grid-flow-col grid-rows-1 overflow-x-auto grid-cols-[repeat(7,6rem)]">
          {renderStatusItems()}
          
          {canChangeStatus && (
            <button
              className="flex text-sm text-main-color font-bold underline"
              type="submit"
            >
              Valider statut
            </button>
          )}
        </div>

        <div className="max-w-[1550px]">
          <p className="text-right text-red-500 text-xs mt-2">{error}</p>
        </div>
      </div>
    </form>
  );
}

StatusTimeline.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  statusList: PropTypes.array,
  status: PropTypes.string,
  updateStatus: PropTypes.func,
  error: PropTypes.any,
  setError: PropTypes.func,
};
