import React, { useEffect, useState, useCallback } from "react";
import useSWR from "swr";
import { ReactComponent as DepotEmmyIcon } from "images/icons/depotEmmy.svg";
import { ReactComponent as LoadingIcon } from "images/icons/loading.svg";
import { useApiEndpoint } from "ApiEndpointContext";
import Button from "components/Button";
import DossierStatus from "components/DossierStatus";
import useFetchApi from "hooks/useFetchApi";
import Main from "components/Main";
import DataTable, {
  useDataTable,
  DataTableProvider,
} from "components/DataTable";
import Search from "components/Search";
import { useAuth } from "providers/AuthProvider";
import axios from "axios";
import { formatDate, formatVolume } from "functions";

const DEPOT_EMMY_FILTERS = { statut: "Documents validés" };

const FILES_PER_PAGE = 10;

const columns = [
  {
    label: "Opération",
    key: "operation",
    selectOptionLabel: "Sélectionner une ou plusieurs opérations",
    filterField: "fosts.numero_fost",
    className: "max-w-[180px]",
    format: (fosts) => (
      <div className="truncate text-black text-ellipsis" title={fosts}>
        {fosts}
      </div>
    ),
  },
  {
    label: "Bénéficiaire",
    key: "beneficiaire",
    sortFields: ["beneficiaire.nom", "beneficiaire.prenom"],
    defaultDirection: "asc",
    format: (_, row) => (
      <div className="flex flex-col">
        <span>{row.beneficiairePrenom}</span>
        <span>{row.beneficiaireNom}</span>
      </div>
    ),
  },
  {
    label: "Code postal",
    key: "codePostal",
  },
  {
    label: "Statut",
    key: "status",
    format: (status) => <DossierStatus status={status} />,
  },
  {
    label: "Date d'engagement",
    key: "dateSignatureDevis",
    sortFields: ["chantiers.date_signature_devis"],
    defaultDirection: "desc",
    format: formatDate,
  },
  {
    label: "Achevé le",
    key: "dateEditFacture",
    sortFields: ["chantiers.date_edition_facture"],
    defaultDirection: "desc",
    format: formatDate,
  },
  {
    label: "Volume (kWhc)",
    key: "volume",
    sortFields: ["Volume_kWWhc"],
    defaultDirection: "desc",
    format: formatVolume,
  },
];

function transformApiResponse(res) {
  // We transform the API response to a format that is easier to use and
  // has a lower memory footprint than the full API response.
  return {
    ...res,
    data: res.data.map((item) => {
      return {
        id: item.id,
        operation: `${item.attributes.fosts.data[0]?.attributes.numero_fost} ${item.attributes.fosts.data[0]?.attributes.libelle_fost ? item.attributes.fosts.data[0].attributes.libelle_fost : ""}`,
        beneficiaireNom:
          item.attributes.beneficiaire?.data.attributes.nom ?? "",
        beneficiairePrenom:
          item.attributes.beneficiaire?.data.attributes.prenom ?? "",
        codePostal:
          item.attributes.chantiers?.data[0].attributes.code_postal ?? "",
        status: item.attributes.statut ?? "",
        // dateCreation: item.attributes.createdAt??'',
        dateMiseAJour: item.attributes.updatedAt ?? "",
        volume: item.attributes.Volume_kWWhc ?? "",
        reference: item.attributes.reference ?? "",
        dateSignatureDevis:
          item.attributes.chantiers?.data[0].attributes.date_signature_devis ??
          "",
        dateEditFacture:
          item.attributes.chantiers?.data[0].attributes.date_edition_facture ??
          "",
        // fosts: item.fosts[0]
      };
    }),
  };
}

export default function DepotEmmy() {
  const [[volume, minVolume], setVolumes] = useState([0, 0]);
  const [checkedQueryParams, setCheckedQueryParams] = useState();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [fetchApi] = useFetchApi();
  const [dataTableProviderValue, refresh, clearCheckboxes] = useDataTable();
  const auth = useAuth();
  const apiEndpoint = useApiEndpoint();

  // Recherche par opération (FOST) ou par nom et prénom du bénéficiaire
  const setFilters = useCallback(() => {
    if (search) {
      const cleanSearch = search.trim();
      const searchWords = cleanSearch.split(/\s+/).filter((str) => str !== "");
      return {
        $or: [
          {
            fosts: {
              $or: [
                { numero_fost: { $containsi: cleanSearch } },
                { libelle_fost: { $containsi: cleanSearch } },
              ],
            },
          },
          {
            $and: searchWords.map((word) => ({
              $or: [
                { beneficiaire: { nom: { $containsi: word } } },
                { beneficiaire: { prenom: { $containsi: word } } },
              ],
            })),
          },
        ],
      };
    }
    return {};
  }, [search]);

  // Vérification du volume sélectionné
  const { filters } = checkedQueryParams ?? {};
  const { data: checkEmmyData, isLoading: checkEmmyIsLoading } = useSWR(
    [filters],
    ([filters]) => fetchApi("check-emmy", { body: { filters } }),
  );
  const checkedVolume = checkEmmyData?.data?.total || 0;
  const checkedMinVolume = checkEmmyData?.data?.required || 0;
  useEffect(() => {
    if (!checkEmmyIsLoading) {
      setVolumes([checkedVolume, checkedMinVolume]);
    }
  }, [checkEmmyIsLoading, checkedVolume, checkedMinVolume]);

  // Export des dossiers pour le dépôt Emmy
  const exporterDossiers = async () => {
    try {
      setLoading(true);
      const apiUrl = `${apiEndpoint}/api/generate-emmy`;
      const response = await axios.post(
        apiUrl,
        { filters },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        },
      );

      // Check if the response is a CSV file
      if (response.headers["content-type"] === "text/csv; charset=utf-8") {
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const a = document.createElement("a");
        a.href = url;
        a.download = "depot_emmy.csv";
        a.click();

        window.URL.revokeObjectURL(url); // Release the object URL

        clearCheckboxes();
        refresh();
      }
    } catch (error) {
      console.warn(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DataTableProvider value={dataTableProviderValue}>
      <Main
        title="Dépôt Emmy"
        header={<Search setSearch={setSearch} placeholder="Recherche…" />}
      >
        <DataTable
          id="depot-emmy"
          entity="dossier"
          endpointUrl="dossiers"
          formatData={transformApiResponse}
          columns={columns}
          defaultActiveSort="dateEditFacture"
          setFilters={setFilters}
          filters={DEPOT_EMMY_FILTERS}
          pageSize={FILES_PER_PAGE}
          flex
          checkbox
          setCheckedQueryParams={setCheckedQueryParams}
          noResultMessage="Il n'y a aucun dossier à déposer."
          footer={
            <>
              <div className="flex flex-row gap-4">
                <div>
                  <Button
                    label="Exporter un fichier"
                    icon={loading ? <LoadingIcon /> : <DepotEmmyIcon />}
                    variant="contained"
                    disabled={
                      loading || !checkedQueryParams || volume < minVolume
                    }
                    onClick={exporterDossiers}
                  />
                </div>
                <div className="pt-1 border-l pl-3">
                  <p className="text-xs text-light-color">
                    Total volume sélectionné :
                  </p>
                  <h1 className="text-main-color font-bold mt-1">
                    {formatVolume(volume)}
                  </h1>
                </div>
              </div>
              {!!minVolume && (
                <p className="text-xs mt-2">
                  Veuillez séléctionner {formatVolume(minVolume)} au minimum.
                </p>
              )}
            </>
          }
          extraParams={{ populate: "*" }}
        />
      </Main>
    </DataTableProvider>
  );
}
