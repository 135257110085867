import React from "react";
import PropTypes from "prop-types";
import { useAuth } from "providers/AuthProvider";
import { Link, useLocation } from "react-router-dom";
import logo from "images/logo_soregies.svg";
import ConditionChecker from "./helpers/ConditionChecker";
import { useMenu } from "hooks/useMenuFooter";
import ButtonSimulation from "./ButtonSimulation";

function MenuItem({
  to,
  logo,
  label,
  phoneLabel,
  activePaths,
  external = false,
  className = "",
}) {
  const Logo = logo;
  const { pathname } = useLocation();
  const { isMobile } = useMenu();
  let active = to === "/" ? pathname === "/" : pathname.startsWith(to);
  if (activePaths && !active) {
    active = activePaths.some((path) => pathname.startsWith(path));
  }
  const extraProps = external ? { target: "_blank", rel: "noreferrer" } : {};

  return (
    <Link
      to={to}
      className={`flex md:flex-row flex-col items-center w-full ${
        active
          ? "md:bg-Bleu-fonc-Soregies md:text-white text-Bleu-fonc-Soregies"
          : "md:text-Bleu-fonc-Soregies text-Gris-500-Soregies"
      } md:gap-4 gap-2 md:h-12 rounded-lg md:pl-2 text-sm ${className}`}
      {...extraProps}
    >
      <Logo className={`shrink-0`} />
      <span
        className={`md:font-medium font-normal text-center text-xs md:text-sm leading-5 tracking-tighter md:tracking-normal`}
      >
        {(isMobile && phoneLabel) || label}
      </span>
    </Link>
  );
}

MenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  logo: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  activePaths: PropTypes.array,
  phoneLabel: PropTypes.string,
  external: PropTypes.bool,
  className: PropTypes.string,
};

export default function Menu({ className }) {
  const { isCustomer, isManager, isPartner } = useAuth();
  const { menuItemList, noShadowStyle, showAddSimulation } = useMenu();

  return (
    <div
      className={`md:bg-lightblue ${!noShadowStyle && "shadow-top"} bg-white md:py-10 flex flex-row md:flex-col md:pl-4 md:pr-2 items-center md:items-start ${className}`}
      style={{ zIndex: "9999999" }}
    >
      <img className="hidden md:block" src={logo} alt="logo Sorégies Groupe" />
      <h1 className=" hidden md:block mb-10 mt-3 font-semibold text-sm text-main-color">
        {(isCustomer && <>Espace bénéficiaire</>) ||
          (isManager && <>Espace gestionnaire CEE</>) ||
          (isPartner && <>Espace partenaire</>) || <></>}
      </h1>

      <ConditionChecker condition={showAddSimulation}>
        <ButtonSimulation plusIcon isMobile={showAddSimulation} />
      </ConditionChecker>

      {menuItemList.map((item) => {
        if (!item.condition) return null;
        if (item.component)
          return (
            <ConditionChecker key={item.key} condition={item.condition}>
              {item.component}
            </ConditionChecker>
          );

        return (
          <ConditionChecker key={item.key} condition={item.condition}>
            {item.items.map((menuItem) => {
              return <MenuItem key={menuItem.label} {...menuItem} />;
            })}
          </ConditionChecker>
        );
      })}
    </div>
  );
}

Menu.propTypes = {
  className: PropTypes.string,
};

Menu.defaultProps = {
  className: "",
};
