import React, { useState } from "react";
import PropTypes from "prop-types";
import { cn } from "./helpers/utils";
import { PhoneInput } from "./PhoneInput";
import { Eye, EyeOff } from "lucide-react";

const Input = React.forwardRef(
  ({ className, children, options, type, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    let component = "";
    switch (type) {
      case "phone":
        component = <PhoneInput defaultCountry={"FR"} ref={ref} {...props} />;
        break;
      case "select":
        component = (
          <select
            ref={ref}
            className={
              "md:w-[415px] border border-stone-300 rounded md:py-5 md:px-4 text-sm"
            }
            {...props}
          >
            {options.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        );
        break;
      case "password":
        component = (
          <label
            htmlFor={props.id || "password"}
            className="flex flex-row items-center justify-between md:w-[415px] border border-stone-300 rounded py-2 px-3 md:py-5 md:px-4 text-xs md:text-sm"
          >
            <input
              ref={ref}
              type={showPassword ? "text" : "password"}
              className="w-full outline-none border-none"
              id={props.id || "password"}
              {...props}
            />
            <button
              type="button"
              className="text-xs md:text-sm font-medium text-blue-600 hover:text-blue-800 focus:outline-none"
              onClick={() => setShowPassword((prev) => !prev)}
              aria-label={
                showPassword
                  ? "Masquer le mot de passe"
                  : "Afficher le mot de passe"
              }
            >
              {showPassword ? (
                <Eye stroke-width="1" />
              ) : (
                <EyeOff stroke-width="1" />
              )}
            </button>
          </label>
        );
        break;
      default:
        component = (
          <input
            ref={ref}
            type={type}
            className="md:w-[415px] border border-stone-300 rounded py-2 px-3 md:py-5 md:px-4 text-xs md:text-sm"
            {...props}
          />
        );
        break;
    }

    return (
      <div className={cn("flex flex-col gap-2", className)}>
        {props.label && (
          <label
            className="text-main-color text-xs md:text-sm "
            htmlFor={props.id}
          >
            {props.label}
            {props.required && <sup>*</sup>}
          </label>
        )}
        {component}
        {children}
      </div>
    );
  },
);
Input.displayName = "Input";

export default Input;

Input.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  type: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  label: PropTypes.string,
  required: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
};

Input.defaultProps = {
  className: "",
  children: null,
  type: "text",
  options: [],
};
