import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FieldBlock from "./FieldBlock";
import useFetchApi from "hooks/useFetchApi";

export default function FieldBlockCheckbox({
  label,
  name,
  value,
  id,
  route,
  sectionID,
  editAll,
  updateGroupAnswers,
  fetchData,
  readonly,
}) {
  const [fetchApi] = useFetchApi();
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState(!!value);

  const onSubmit = async () => {
    setLoading(true);
    document.activeElement?.blur();
    const body = route
      ? { [route]: { [name]: answer, id: sectionID } }
      : { [name]: answer };
    try {
      await fetchApi(`update-dossier/${id}`, { body, method: "PUT" });
      fetchData();
    } catch ({ message }) {
      setAnswer(!!value);
    }
    setLoading(false);
    setEditing(false);
  };

  const onCancel = () => {
    setEditing(!editing);
    setAnswer(value || false);
  };

  const handleAnswer = (item) => {
    setAnswer(item);
    updateGroupAnswers(name, item, route, sectionID);
  };

  useEffect(() => {
    setAnswer(!!value);
  }, [value]);

  useEffect(() => {
    if (!editAll) {
      setEditing(false);
    }
  }, [editAll]);

  const contents = (
    <label className="text-xs flex items-center">
      <input
        type="checkbox"
        checked={!!answer}
        disabled={(!editing && !editAll) || loading} // Disable the checkbox when not editing
        onChange={() => {
          setAnswer(!answer);
          if (editAll) {
            handleAnswer(!answer);
          }
        }}
      />
      <span className="ml-2">{label}</span>
    </label>
  );

  return (
    <FieldBlock
      displayValue={contents}
      loading={loading}
      editing={editing}
      editAll={editAll}
      readonly={readonly}
      onSubmit={onSubmit}
      onCancel={onCancel}
      setEditing={setEditing}
    >
      {contents}
    </FieldBlock>
  );
}
FieldBlockCheckbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  id: PropTypes.number,
  route: PropTypes.string,
  sectionID: PropTypes.number,
  readonly: PropTypes.bool,
  editAll: PropTypes.bool,
  updateGroupAnswers: PropTypes.func,
  fetchData: PropTypes.func,
};
