import React from "react";
import PropTypes from "prop-types";

import DataTable from "components/DataTable";
import DossierStatus from "components/DossierStatus";

import { formatDate, formatVolume } from "functions";

import { useAuth } from "providers/AuthProvider";

function transformApiResponse(res) {
  return {
    ...res,
    data: res?.data?.map(({ id, attributes: item }) => {
      return {
        id,
        fosts: `${item.fosts.data[0]?.attributes?.numero_fost} ${item.fosts.data[0]?.attributes?.libelle_fost ? item.fosts.data[0].attributes?.libelle_fost : ""}`,
        nom_offre: item?.nom_offre,
        adresseChantier: `${item?.chantiers?.data[0]?.attributes?.adresse}, ${item?.chantiers?.data[0]?.attributes?.code_postal} ${item?.chantiers?.data[0]?.attributes?.ville}`,
        cdp: item.fosts.data[0]?.attributes?.numero_fost.includes("Coup")
          ? "Coup de pouce"
          : "Sans coup de pouce",
        status: item.statut,
        statut_precarite: item.statut_precarite ?? "-",
        zone: item.chantiers?.data[0].attributes?.zone_climatique ?? "-",
        energy: item.fosts.data[0]?.attributes?.energie_de_chauffage ?? "-",
        dateSignatureDevis:
          item.chantiers?.data[0].attributes?.date_signature_devis ?? "",
        volume: item.Volume_kWWhc,
        primeCEE: item.Prime_CEE,
        beneficiaireNom: item.beneficiaire?.data.attributes?.nom,
        beneficiairePrenom: item.beneficiaire?.data.attributes?.prenom,
        codePostal: item.chantiers?.data[0].attributes?.code_postal,
        dateCreation: item.createdAt,
        dateMiseAJour: item.updatedAt,
        reference: item.reference,
      };
    }),
  };
}

const columns = [
  {
    label: "Opération",
    key: "fosts",
    onlyManager: true,
    onlyPartner: true,
    selectOptionLabel: "Sélectionner une ou plusieurs opérations",
    filterField: "fosts.numero_fost",
    className: "max-w-[180px]",
    format: (fosts) => (
      <div className="truncate text-black text-ellipsis" title={fosts}>
        {fosts}
      </div>
    ),
  },
  {
    label: "Opération",
    key: "nom_offre",
    onlyCustomer: true,
    selectOptionLabel: "Sélectionner une ou plusieurs opérations",
    filterField: "nom_offre",
    className: "max-w-[180px]",
    format: (fosts) => (
      <div className="truncate text-black text-ellipsis" title={fosts}>
        {fosts}
      </div>
    ),
  },
  {
    label: "Bénéficiaire",
    key: "beneficiaire",
    onlyManager: true,
    onlyPartner: true,
    sortFields: ["beneficiaire.nom", "beneficiaire.prenom"],
    defaultDirection: "asc",
    format: (_, row) => (
      <div className="flex flex-col">
        <span>{row.beneficiairePrenom}</span>
        <span>{row.beneficiaireNom}</span>
      </div>
    ),
  },
  {
    label: "Code postal",
    key: "codePostal",
    onlyManager: true,
    onlyPartner: true,
  },
  {
    label: "Adresse chantier",
    key: "adresseChantier",
    onlyCustomer: true,
    selectOptionLabel: "Sélectionner une ou plusieurs adresses de chantier",
    filterField: "simulation.adresse_chantier",
    sortOptions: (addressA, addressB) => {
      // Tri par nom de rue, puis par numéro
      const [numA, , streetA] = addressA.split(/\s+/, 3);
      const [numB, , streetB] = addressB.split(/\s+/, 3);
      return (
        streetA?.localeCompare(streetB) ||
        +numA - +numB ||
        addressA.localeCompare(addressB)
      );
    },
  },
  {
    label: "Statut",
    key: "status",
    onlyManager: true,
    selectOptionLabel: "Sélectionner un ou plusieurs statuts",
    filterField: "statut",
    format: (status) => <DossierStatus status={status} />,
  },
  {
    label: "Statut",
    key: "status",
    onlyCustomer: true,
    onlyPartner: true,
    filterField: "statut",
    format: (status) => <DossierStatus status={status} />,
  },
  {
    label: "Créé le",
    key: "dateCreation",
    sortFields: ["createdAt"],
    defaultDirection: "desc",
    lastView: false,
    format: formatDate,
  },
  {
    label: "Mis à jour le",
    key: "dateMiseAJour",
    sortFields: ["updatedAt"],
    defaultDirection: "desc",
    onlyManager: true,
    onlyPartner: true,
    lastView: false,
    format: formatDate,
  },
  {
    label: "Créé et mis à jour le",
    key: "dateCreationMiseAJour",
    lastView: true,
    onlyManager: true,
    format: (_, { dateMiseAJour, dateCreation }) => (
      <div>
        <p>Créé le {formatDate(dateCreation)}</p>
        <p>Mis à jour le {formatDate(dateMiseAJour)}</p>
      </div>
    ),
  },
  {
    label: "Volume (kWhc)",
    key: "volume",
    onlyManager: true,
    onlyPartner: true,
    lastView: false,
    sortFields: ["Volume_kWWhc"],
    defaultDirection: "desc",
    format: formatVolume,
  },
  {
    onlyManager: true,
    key: "managerActions",
    lastView: false,
    format: (_, row) => (
      <a className="underline text-black" href={`/dossiers/${row.id}`}>
        Voir détails
      </a>
    ),
  },
  {
    onlyManager: true,
    onlyPartner: true,
    key: "managerActionsLastView",
    lastView: true,
    format: (_, row) => (
      <a className="underline text-black" href={`/dossiers/${row.id}`}>
        Voir ce dossier
      </a>
    ),
  },
  {
    onlyCustomer: true,
    key: "customerActions",
    format: (_, row) => (
      <a className="underline text-black" href={`/mes-projets/${row.id}`}>
        Voir ce projet
      </a>
    ),
  },
];

export function DossierList({
  lastView,
  setFilters,
  filters,
  title,
  pageSize,
  flex,
  headers,
  pagination,
  footer,
  selectedId,
  setSelectedId,
  extraParams,
}) {
  const { isManager } = useAuth();

  const filteredColumns = columns.filter(
    (column) =>
      !isManager ||
      column.lastView === undefined ||
      column.lastView === lastView,
  );

  return (
    <DataTable
      id="dossier-list"
      entity="dossier"
      formatData={transformApiResponse}
      endpointUrl={lastView ? "last-view-dossiers" : "dossiers"}
      columns={filteredColumns}
      defaultActiveSort={lastView ? null : "dateCreation"}
      setFilters={setFilters}
      filters={filters}
      title={title}
      pageSize={pageSize}
      flex={flex}
      headers={headers}
      pagination={pagination}
      footer={footer}
      setSelectedId={setSelectedId}
      selectedId={selectedId}
      extraParams={{ populate: "*", ...extraParams }}
    />
  );
}

DossierList.defaultProps = {
  lastView: false,
  pageSize: 10,
  flex: true,
  headers: true,
  pagination: true,
  filters: {},
};
DossierList.propTypes = {
  lastView: PropTypes.bool,
  title: PropTypes.any,
  setFilters: PropTypes.func,
  filters: PropTypes.instanceOf(Object),
  pageSize: PropTypes.number,
  flex: PropTypes.bool,
  headers: PropTypes.bool,
  pagination: PropTypes.bool,
  footer: PropTypes.any,
  selectedId: PropTypes.number,
  setSelectedId: PropTypes.func,
  extraParams: PropTypes.instanceOf(Object),
};
