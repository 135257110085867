import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "providers/AuthProvider";
import useFetchApi from "hooks/useFetchApi";
import { mutate } from "swr";
import AuthForm from "./AuthForm";
import AuthMessage from "./AuthMessage";
import Input from "components/Input";
import { ReactComponent as SuccessIcon } from "images/icons/success.svg";

import { z } from "zod";
import formErrors from "constants/formErrors";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormControl, FormField, FormItem, FormMessage } from "components/Form";
import { translateMessage } from "functions";

const formSchema = z
  .object({
    password: z
      .string({ message: formErrors.requiredErrorMessage })
      .min(8, { message: formErrors.passwordMinLengthErrorMessage })
      .refine((password) => /[A-Z]/.test(password), {
        message: formErrors.passwordUppercaseErrorMessage,
      })
      .refine((password) => /[a-z]/.test(password), {
        message: formErrors.passwordLowercaseErrorMessage,
      })
      .refine((password) => /[0-9]/.test(password), {
        message: formErrors.passwordNumberErrorMessage,
      })
      .refine((password) => /[!@#$%^&*]/.test(password), {
        message: formErrors.passwordSpecialCharacterErrorMessage,
      }),
    passwordConfirmation: z.string(),
  })
  .superRefine(({ passwordConfirmation, password }, ctx) => {
    if (passwordConfirmation !== password) {
      ctx.addIssue({
        code: "custom",
        message: formErrors.passwordMatchErrorMessage,
        path: ["passwordConfirmation"],
      });
    }
  });

export default function ResetPassword() {
  const [fetchApi] = useFetchApi();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const form = useForm({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
  });

  const submit = async (data) => {
    try {
      setLoading(true);
      await fetchApi(`auth/reset-password`, { body: { ...data, code } });
      // Trigger a revalidation (refetch).
      mutate("auth/local");
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setLoading(false);
      form.setError("passwordConfirmation", {
        type: "manual",
        message: translateMessage(error.message),
      });
    }
  };

  if (success) {
    return (
      <AuthMessage
        title={
          <>
            <SuccessIcon className="mr-2 inline-block align-middle h-5" />
            Mot de passe modifié
          </>
        }
        message="Vous pouvez à présent vous connecter avec votre nouveau mot de passe."
      />
    );
  }

  return (
    <AuthForm
      submit={form.handleSubmit(submit)}
      title="Changement de mot de passe"
      submitText="Valider le mot de passe"
      cancelUrl="/"
      cancelText="Retour à la page de connexion"
      success={success}
      loading={loading}
      onSuccess={() => auth.logout()}
      navigateToOnSuccess="/"
      {...form}
    >
      <FormField
        name="password"
        control={form.control}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                label="Nouveau mot de passe"
                className="w-full"
                type="password"
                autoComplete="new-password"
                placeholder="8 caractères ou plus, dont 1 majuscule, 1 chiffre et un caractère spécial"
                required
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        name="passwordConfirmation"
        control={form.control}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                className="w-full"
                type="password"
                label="Confirmer le nouveau mot de passe"
                autoComplete="new-password"
                required
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </AuthForm>
  );
}
