import { useEffectRefresh } from "providers/RefreshProvider";
import useSWR, { mutate } from "swr";

/**
 * Effectue une requête fetch sur Strapi en corrigeant les meta pagination
 * @param {string} url URL à fetch dont la query string a été retournée par useDataTableQueryString()
 * @param {function} fetch Fonction de fetch (par exemple fetchApi retournée par le hook useFetchApi())
 * @returns {Object} Objet de retour SWR avec meta
 */
export function useSwrStrapi(url, fetch) {
  const {
    data: { data, meta } = { data: [] },
    error,
    isLoading,
    mutate: mutateData,
  } = useSWR(url, async (url) => {
    try {
      const res = await fetch(url);
      const { total, limit, start, ...pagination } = res.meta.pagination;
      const pageCount = pagination?.pageCount ?? Math.ceil(total / limit);
      const currentPage =
        pagination?.currentPage ??
        pagination?.page ??
        Math.floor(start / limit);
      const pageSize = pagination?.pageSize ?? limit;
      return {
        data: res.data,
        meta: {
          ...res.meta,
          pagination: {
            ...res.meta.pagination,
            pageCount,
            currentPage,
            pageSize,
          },
        },
      };
    } catch (e) {
      console.warn(e);
      throw e;
    }
  });

  // Invalide le cache en cas de refresh initié par l'élément parent
  useEffectRefresh(() => mutate(url));

  return { data, meta, error, isLoading, mutateData };
}

// Fonction utilitaire pour découper un tableau en sous-tableaux de taille spécifiée
export function chunk(array, size) {
  return Array.from({ length: Math.ceil(array.length / size) }, (_, i) =>
    array.slice(i * size, (i + 1) * size)
  );
}
