import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { detectConfig, useConfig } from "providers/ConfigProvider";

const { apiUrl } = detectConfig();

async function fetchData(path, options, simple = false) {
  const res = await fetch(`${apiUrl}${path}`, {
    method: "GET",
    ...options,
    headers: {
      "Content-Type": "application/json",
      ...options?.headers,
    },
  });
  if (simple) {
    return res;
  }
  const data = await res.json();
  if (data.error) {
    throw new Error(data.error.message);
  }
  return data;
}

export const AuthContext = createContext(null);

export function useAuth() {
  if (!AuthContext) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return useContext(AuthContext);
}

export function Disconnect() {
  const auth = useAuth();
  useEffect(() => {
    auth.logout();
  }, [auth]);
  return <></>;
}

export function AuthGuard({
  children,
  connected,
  redirectTo,
  forceLogout = false,
}) {
  const auth = useAuth();
  const navigate = useNavigate();

  const passes = (auth.user && connected) || (!auth.user && !connected);
  const shouldBeDisconnected = auth.user && !connected && forceLogout;

  useEffect(() => {
    if (shouldBeDisconnected) {
      auth.logout(false);
    } else if (!passes && redirectTo) {
      navigate(redirectTo);
    }
  }, [passes, redirectTo, navigate, shouldBeDisconnected, auth]);

  return passes && !shouldBeDisconnected ? children : <></>;
}

AuthGuard.propTypes = {
  children: PropTypes.any.isRequired,
  connected: PropTypes.bool.isRequired,
  redirectTo: PropTypes.string,
  forceLogout: PropTypes.bool,
};

export default function AuthProvider({ children }) {
  const [token, setToken] = useLocalStorage("token", null);
  const [user, setUser] = useLocalStorage("user", null);
  const {
    isCustomerUrl,
    isPartnerUrl,
    isManagerUrl,
    customerUrl,
    managerUrl,
    partnerUrl,
  } = useConfig();
  const navigate = useNavigate();

  const [isPending, setIsPending] = useState(false);

  const role = user?.role?.name || "";
  const isCustomer = isCustomerUrl && (!role || role === "Client");
  const isPartner = isPartnerUrl && role === "Partenaire";
  const isAdmin = isManagerUrl && role === "Administrateur";
  const isManager =
    (!isCustomerUrl && !isPartnerUrl && role === "Administrateur") ||
    role === "Gestionnaire" ||
    role === "Consultation";

  useEffect(() => {
    // Properly handle token expiration.
    // Here the token cannot be refreshed, when expired, we log out the user.
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded.exp * 1000 <= Date.now()) {
        setToken(null);
        setUser(null);
        // navigate("login");
      }
    }
  }, [token, setToken, setUser, navigate]);

  const login = async (
    identifier,
    password,
    { simulationId, simulationCode, token: tokenParam } = {},
  ) => {
    let token = tokenParam;

    // Authentification si pas de token fourni
    if (!token) {
      setIsPending(true);
      const authData = await fetchData("/api/auth/local", {
        method: "POST",
        body: JSON.stringify({
          identifier,
          password,
          simulationId,
          simulationCode,
        }),
      });
      token = authData.jwt;
      setIsPending(false);
    }

    // Chargement des infos de l'utilisateur
    setIsPending(true);
    const userData = await fetchData("/api/users/me?populate=role", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setIsPending(false);

    // Redirige l'utilisateur vers le bon espace
    const isCustomerRole = userData.role.name === "Client";
    const isPartnerRole = userData.role.name === "Partenaire";
    const isManagerRole =
      userData.role.name && !isCustomerRole && !isPartnerRole;

    if (isCustomerRole && !isCustomerUrl) {
      window.location.replace(`${customerUrl}/login?token=${token}`);
    } else if (isPartnerRole && !isPartnerUrl) {
      window.location.replace(`${partnerUrl}/login?token=${token}`);
    } else if (isManagerRole && !isManagerUrl) {
      window.location.replace(`${managerUrl}/login?token=${token}`);
    } else {
      setToken(token);
      setUser(userData);
      // Utilisation du replace pour supprimer le token passé en paramètre de l'historique de navigation
      navigate("/", { replace: !!tokenParam });
    }
  };

  const logout = (redirect = true) => {
    setToken(null);
    setUser(null);
    if (redirect && window.location.pathname !== "/login") {
      navigate("/login");
    }
  };

  const deleteUser = async () => {
    try {
      setIsPending(true);
      await fetchData(`/api/client/${user.id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((data) => {
        if (data.success === true) logout();
      });
      setIsPending(false);
    } catch (error) {
      // Handle the error appropriately, e.g., log to an external service
      console.warn(error);
      setIsPending(false);
    }
  };

  const visibilityChange = () => {
    // if (window.location.pathname !== "/login" && token)
    //   fetchData(
    //     "/api/users/me?populate=role",
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     },
    //     true,
    //   ).then(async (res) => {
    //     const data = await res.json();
    //     if (
    //       data?.error &&
    //       data.error.status === 401 &&
    //       data.error.name === "UnauthorizedError" &&
    //       window.location.pathname !== "/login"
    //     ) {
    //       setToken(null);
    //       setUser(null);
    //       navigate("/login?error=expired");
    //     }
    //   });
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", visibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", visibilityChange);
    };
  }, []);

  const value = {
    token,
    user,
    role,
    isCustomer,
    isManager,
    isPartner,
    isAdmin,
    isPending,
    login,
    logout,
    deleteUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
