import React from "react";
import { useConfig } from "providers/ConfigProvider";
import { useAuth } from "providers/AuthProvider";

const Simulateur = () => {
  const auth = useAuth();
  const { simulatorUrl } = useConfig();
  const embeddedUrl = `${simulatorUrl}/?embed=true&token=${auth.token}`;
  return (
    <div className="text-sm mt-4 flex">
      <iframe
        title="Embedded Simulations"
        src={embeddedUrl}
        style={{ width: "100%", height: "700px" }}
        className="rounded rounded-xl border-0.5 border-stone-400"
      />
    </div>
  );
};

export default Simulateur;
