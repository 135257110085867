import React, { useState, useMemo, useCallback } from "react";
import Dialog from "components/Dialog";
import Button from "components/Button";
import { useAuth } from "providers/AuthProvider";
import DataTable, {
  useDataTable,
  DataTableProvider,
} from "components/DataTable";
import Search from "components/Search";
import { getStatusBackground } from "functions";
import { useApiEndpoint } from "ApiEndpointContext";
import axios from "axios";
// import Loader from "components/Loader";

const FILES_PER_PAGE = 8;

function transformApiResponse(res) {
  // We transform the API response to a format that is easier to use and
  // has a lower memory footprint than the full API response.
  return {
    ...res,
    data: res.data.map((item) => {
      return {
        ...item.attributes,
        id: item.id,
        status: item.attributes.blocked === true ? "Inactif" : "Actif",
      };
    }),
  };
}

export default function Partenaires() {
  const [search, setSearch] = useState("");
  const auth = useAuth();
  const [dataTableProviderValue, refresh] = useDataTable();

  const columns = useMemo(
    () => [
      {
        label: "Raison sociale",
        key: "raison_sociale",
        sortFields: ["raison_sociale", "siret", "siren"],
        defaultDirection: "asc",
        className: "text-black",
      },
      {
        label: "Email",
        key: "email",
      },
      {
        label: "Siret",
        key: "siret",
      },
      {
        label: "Siren",
        key: "siren",
      },
      {
        label: "RGE",
        key: "rge",
        format: (rge) => (rge ? "Oui" : "Non"),
      },
      {
        label: "Statut",
        key: "status",
        // selectOptionLabel: "Sélectionner un statut",
        // filterField: "blocked",
        format: (status, partenaire) => (
          <>
            {["Actif", "Inactif"].map((statusValue) => (
              <button
                key={statusValue}
                className={`px-2.5 py-1.5 rounded text-white ${status === statusValue ? getStatusBackground(status) : "bg-status-disabled"}`}
                disabled={status === statusValue}
                id={partenaire.id}
                onClick={() => {
                  setCurrentItem(partenaire);
                  setCurrentID(partenaire.id);
                  setCurrentStatus(statusValue !== "Actif");
                  setOpenDialog(true);
                }}
              >
                {statusValue}
              </button>
            ))}
          </>
        ),
      },
    ],
    [],
  );

  // Recherche par nom et prénom de l'utilisateur
  const setFilters = useCallback(() => {
    if (search) {
      const cleanSearch = search.trim();
      const searchWords = cleanSearch.split(/\s+/).filter((str) => str !== "");
      return {
        $and: searchWords.map((word) => ({
          $or: [
            { raison_sociale: { $containsi: word } },
            { email: { $containsi: word } },
            { siret: { $containsi: word } },
            { siren: { $containsi: word } },
          ],
        })),
      };
    }
    return {};
  }, [search]);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogError, setDialogError] = useState("");
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [currentID, setCurrentID] = useState(1);
  const [currentStatus, setCurrentStatus] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const apiEndpoint = useApiEndpoint();

  const PartenaireActiver = async () => {
    setDialogLoading(true);
    const body = {
      blocked: currentStatus,
    };
    try {
      const apiUrl = `${apiEndpoint}/api/partenaires/${currentID}`;
      await axios.put(apiUrl, body, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth.token}`,
        },
      });
      setDialogSuccess(true);
      refresh();
    } catch (error) {
      setDialogError(typeof error === "string" ? error : error.message);
    } finally {
      setDialogLoading(false);
    }
  };

  const CloseDialog = () => {
    setOpenDialog(false);
    setDialogSuccess(false);
    setDialogLoading(false);
    setDialogError("");
  };

  return (
    <DataTableProvider value={dataTableProviderValue}>
      <div className="bg-white flex-1 flex flex-col overflow-y-hidden">
        <div className="flex flex-row my-4 px-2">
          {openDialog && (
            <Dialog onClose={CloseDialog} loading={dialogLoading}>
              {!dialogError && !dialogLoading && !dialogSuccess && (
                <div>
                  <h2 className="my-5 text-xl font-semibold text-main-color text-center">
                    Êtes-vous sûr de vouloir{" "}
                    {currentStatus ? "désactiver" : "activer"} le partenaire{" "}
                    {currentItem.raison_sociale}{" "}
                    {currentItem.siret ? ` (${currentItem.siret})` : ""} et tous
                    les utilisateurs associés à celui-ci{" "}
                  </h2>
                  <div className="flex my-2 justify-center">
                    <Button
                      label="Oui, je confirme"
                      className="mr-4"
                      onClick={PartenaireActiver}
                    ></Button>
                    <Button
                      label="Non, j’annule"
                      color="disabled"
                      onClick={CloseDialog}
                    ></Button>
                  </div>
                </div>
              )}

              {dialogError && !dialogLoading && (
                <div className="min-h-[150px] flex items-center justify-center">
                  <div className="text-red-500">{dialogError}</div>
                </div>
              )}

              {dialogSuccess && !dialogLoading && !dialogError && (
                <div className="min-h-[150px] flex items-center justify-center">
                  <div className="my-5 text-xl font-semibold text-main-color text-center">
                    Le compte de {currentItem.email} a bien été{" "}
                    {currentStatus ? "désactivé" : "activé"}
                  </div>
                </div>
              )}
            </Dialog>
          )}

          <h1 className="font-semibold text-lg mt-2">Tous les partenaires</h1>
          <div className="flex-1"></div>
          <div className="flex items-center max-w-[340px] w-full">
            <Search setSearch={setSearch} placeholder="Recherche…" />
          </div>
          {/* <div> */}
          {/*  <Button */}
          {/*    label="Créer un utilisateur" */}
          {/*    className="ml-3" */}
          {/*    onClick={() => { */}
          {/*      setOpenDialogPartenaire(true); */}
          {/*    }} */}
          {/*  /> */}
          {/* </div> */}
        </div>

        <DataTable
          id="partenaires"
          entity="partenaires"
          endpointUrl="partenaires"
          formatData={transformApiResponse}
          columns={columns}
          defaultActiveSort="raison_sociale"
          setFilters={setFilters}
          pageSize={FILES_PER_PAGE}
          flex
          noResultMessage="Ce partenaire n'existe pas."
          // extraParams={{ populate: "role" }}
        />
      </div>
    </DataTableProvider>
  );
}
