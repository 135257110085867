export default {
  minLengthErrorMessage: "Ce champ doit contenir au moins {min} caractères.",
  maxLengthErrorMessage: "Ce champ doit contenir au plus {max} caractères.",
  requiredErrorMessage: "Ce champ est requis.",

  emailErrorMessage: "Ce champ doit être un email valide",

  passwordMatchErrorMessage: "Les mots de passe ne correspondent pas.",
  passwordMinLengthErrorMessage:
    "Le mot de passe doit contenir au moins 8 caractères.",
  passwordMaxLengthErrorMessage:
    "Le mot de passe doit contenir au plus 20 caractères.",
  passwordLowercaseErrorMessage:
    "Le mot de passe doit contenir au moins une minuscule.",
  passwordUppercaseErrorMessage:
    "Le mot de passe doit contenir au moins une majuscule.",
  passwordNumberErrorMessage:
    "Le mot de passe doit contenir au moins un chiffre.",
  passwordSpecialCharacterErrorMessage:
    "Le mot de passe doit contenir au moins un caractère spécial.",

  tokenErrorMessage: "Votre session a expiré, veuillez vous reconnecter.",

  phoneErrorMessage: "Ce champ doit être un numéro de téléphone valide.",
};
