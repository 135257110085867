import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  createContext,
  useContext,
} from "react";
import PropTypes from "prop-types";
import useSWR, { mutate } from "swr";
import { useAuth } from "providers/AuthProvider";
import Checkbox from "components/Checkbox";
import useFetchApi from "hooks/useFetchApi";
import { RefreshContext, useEffectRefresh } from "providers/RefreshProvider";
import Cell from "components/DataTable/Cell";
import Column from "components/DataTable/Column";
import SortableColumn from "components/DataTable/SortableColumn";
import FilterableColumn from "components/DataTable/FilterableColumn";
import PageSelector from "components/DataTable/PageSelector";
import { stringifyQueryParams, maxPlus } from "functions";
import Loader from "components/Loader";
import { useMenu } from "hooks/useMenuFooter";
import ConditionChecker from "./helpers/ConditionChecker";
import { useLocation } from "react-router-dom";
import DossierStatus from "./DossierStatus";
import { ReactComponent as ArrowIcon } from "images/icons/arrow.svg";
import { chunk } from "helper/helper";

export const DataTableContext = createContext();

/**
 * Hook du composant parent qui retourne la valeur à fournir au composant <DataTableProvider> ainsi que les fonctions refresh() et clearCheckboxes()
 * @returns {Array<Object, function, function>}
 */
export function useDataTable() {
  const [refreshValue, setRefreshValue] = useState(0);
  const [clearCheckboxValue, setClearCheckboxValue] = useState(0);

  const refresh = useCallback(() => {
    setRefreshValue(new Date().getTime());
  }, [setRefreshValue]);

  const clearCheckbox = useCallback(() => {
    setClearCheckboxValue(new Date().getTime());
  }, [setClearCheckboxValue]);

  const value = useMemo(
    () => ({
      refreshValue,
      clearCheckboxValue,
    }),
    [refreshValue, clearCheckboxValue],
  );

  return [value, refresh, clearCheckbox];
}

/**
 * Composant DataTableProvider
 * @param {object} param0
 * @param {object} param0.children Nœuds enfants
 * @param {object} param0.value Première valeur retournée par useDataTable()
 * @returns
 */
export function DataTableProvider({ children, value }) {
  const { refreshValue } = value ?? {};
  return (
    <RefreshContext.Provider value={refreshValue}>
      <DataTableContext.Provider value={value}>
        {children}
      </DataTableContext.Provider>
    </RefreshContext.Provider>
  );
}
DataTableProvider.propTypes = {
  value: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.any.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

/**
 * Effectue une requête fetch sur Strapi en corrigeant les meta pagination
 * @param {string} url URL à fetch dont la query string a été retournée par useDataTableQueryString()
 * @param {function} fetch Fonction de fetch (par exemple fetchApi retournée par le hook useFetchApi())
 * @returns {Object} Objet de retour SWR avec meta
 */
function useSwrStrapi(url, fetch) {
  const {
    data: { data, meta } = { data: [] },
    error,
    isLoading,
  } = useSWR(url, async (url) => {
    try {
      const res = await fetch(url);
      const { total, limit, start, ...pagination } = res.meta.pagination;
      const pageCount = pagination?.pageCount ?? Math.ceil(total / limit);
      const currentPage =
        pagination?.currentPage ??
        pagination?.page ??
        Math.floor(start / limit);
      const pageSize = pagination?.pageSize ?? limit;
      return {
        data: res.data,
        meta: {
          ...res.meta,
          pagination: {
            ...res.meta.pagination,
            pageCount,
            currentPage,
            pageSize,
          },
        },
      };
    } catch (e) {
      console.warn(e);
      throw e;
    }
  });

  // Invalide le cache en cas de refresh initié par l'élément parent
  useEffectRefresh(() => mutate(url));

  return { data, meta, error, isLoading };
}

function getFieldFilter([fieldName, ...fieldNamePartsRest], selected) {
  if (fieldNamePartsRest.length > 0) {
    return { [fieldName]: getFieldFilter(fieldNamePartsRest, selected) };
  } else {
    const hasNull = selected.has(null);
    const selectedFiltered = [...selected].filter((value) => value !== null);
    const fieldValuesFilter = {
      [fieldName]: { $in: selectedFiltered },
    };
    if (hasNull) {
      return {
        $or: [{ [fieldName]: { $null: true } }, fieldValuesFilter],
      };
    } else {
      return fieldValuesFilter;
    }
  }
}

/**
 * Retourne les query params Strapi pour le tableau de données
 * @param {Object} options Objet d'options
 * @param {Array} options.columns Colonnes du tableau
 * @param {Object} options.activeSorts Tris actifs
 * @param {Object} options.activeFilters Filtres actifs
 * @param {number} options.currentPage Numéro de la page en cours (1, 2, 3...)
 * @param {number} [options.pageSize] Nombre d'éléments par page
 * @param {function} [options.setFilters] Fonction renvoyant des filtres supplémentaires à appliquer à la requête
 * @param {function} [options.setCurrentPage] Fonction définissant la page en cours
 * @param {function} [options.extraParams] Paramètres supplémentaires à intéger à la query string générée
 * @returns {Object} Query params Strapi contenant filters, sort, pagination + les paramètres optionnels
 */
function useDataTableQueryParams({
  id,
  columns,
  activeSorts = {},
  activeFilters = {},
  setActiveFilters,
  currentPage,
  pageSize = 10,
  setFilters,
  setCurrentPage,
  extraParams = {},
}) {
  // Génération du tri
  const sort = useMemo(() => {
    const res = [];
    Object.keys(activeSorts).forEach((sortKey) => {
      const column = columns.find((column) => column.key === sortKey);
      if (!column) {
        throw new Error(
          `Sort column ${sortKey} not found in columns definition.`,
        );
      }
      const direction =
        activeSorts[sortKey] !== "default"
          ? activeSorts[sortKey] === "desc"
            ? "desc"
            : "asc"
          : column.defaultDirection || "asc";
      column.sortFields.forEach((strapiField) =>
        res.push(`${strapiField}:${direction}`),
      );
    });
    return res;
  }, [columns, activeSorts]);

  const saveFilters = useCallback((tableId, filters) => {
    // Convertir les Sets en tableaux pour le stockage JSON
    const filtersToSave = {};
    Object.keys(filters).forEach((key) => {
      const value = filters[key];
      if (value instanceof Set) {
        filtersToSave[key] = Array.from(value);
      } else {
        filtersToSave[key] = value;
      }
    });

    localStorage.setItem(
      `table_filters_${tableId}`,
      JSON.stringify(filtersToSave),
    );
  }, []);

  const loadFilters = (tableId) => {
    const savedFilters = localStorage.getItem(`table_filters_${tableId}`);
    if (!savedFilters) return null;

    // Parse les filtres
    const parsedFilters = JSON.parse(savedFilters);

    // Convertir les valeurs qui devraient être des Sets en Sets réels
    Object.keys(parsedFilters).forEach((key) => {
      const value = parsedFilters[key];
      // Si la valeur est un tableau et que c'est censé être un Set
      if (Array.isArray(value)) {
        parsedFilters[key] = new Set(value);
      }
    });

    return parsedFilters;
  };

  useEffect(() => {
    const savedFilters = loadFilters(id);
    if (savedFilters && Object.keys(savedFilters).length > 0) {
      setActiveFilters(savedFilters);
    }
  }, [id, setActiveFilters]);

  useEffect(() => {
    saveFilters(id, activeFilters);
  }, [id, activeFilters]);

  // Génération des filtres
  const filters = useMemo(() => {
    const res = {};
    Object.keys(activeFilters).forEach((filterKey) => {
      const filterValues = activeFilters[filterKey];
      if (filterValues) {
        const column = columns.find((column) => column.key === filterKey);
        if (!column) {
          throw new Error(
            `Filter column ${filterKey} not found in columns definition.`,
          );
        }
        Object.assign(
          res,
          getFieldFilter(column.filterField.split("."), filterValues),
        );
      }
    });

    if (setFilters) {
      Object.assign(res, setFilters());
    }
    return res;
  }, [columns, activeFilters, setFilters]);

  // Génération de la pagination
  const pagination = useMemo(
    () => ({
      limit: pageSize,
      start: pageSize * Math.max(0, currentPage - 1),
    }),
    [pageSize, currentPage],
  );

  // Revient à la première page si les filtres ont été modifiés
  const stringifiedFilters = stringifyQueryParams({ filters }); // Conversion des filtres en string car l'objet peut être recréé à l'identique
  useEffect(() => {
    if (setCurrentPage) {
      setCurrentPage(1);
    }
  }, [setCurrentPage, stringifiedFilters]);

  // Construction des query params
  const queryParams = {
    filters,
    sort,
    pagination,
    ...extraParams,
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => queryParams, [JSON.stringify(queryParams)]);
}

export default function DataTable({
  id,
  entity,
  endpointUrl,
  extraParams,
  formatData,
  columns,
  defaultActiveSort,
  setFilters,
  filters,
  noFilter,
  noSort,
  title,
  pageSize,
  flex,
  headers,
  pagination,
  footer,
  selectedId,
  setSelectedId,
  checkbox,
  setCheckedQueryParams,
  noResultMessage,
  setQueryParams,
}) {
  const [fetchApi] = useFetchApi();
  const [currentPage, setCurrentPage] = useState(1);
  const [activeSorts, setActiveSorts] = useState(
    defaultActiveSort ? { [defaultActiveSort]: "default" } : {},
  );
  const [activeFilters, setActiveFilters] = useState({});
  const [allChecked, setAllChecked] = useState(false);
  const [checkedIds, setCheckedIds] = useState(new Set());
  const [totalResults, setTotalResults] = useState(0);
  const { isManager, isPartner, isCustomer } = useAuth();
  const { clearCheckboxValue } = useContext(DataTableContext) ?? {};
  const [lastClearCheckboxValue, setLastClearCheckboxValue] =
    useState(clearCheckboxValue);
  const { isMobile } = useMenu();
  const { pathname } = useLocation();

  const onCheckboxChange = useCallback(
    (checked, id) => {
      if (allChecked) {
        setAllChecked(false);
      }
      const newCheckedIds = new Set([...checkedIds]);
      if (checked) {
        newCheckedIds.add(id);
      } else {
        newCheckedIds.delete(id);
      }
      setCheckedIds(newCheckedIds);
    },
    [checkedIds, allChecked],
  );

  const onAllCheckboxChange = useCallback(
    (checked) => {
      if (checked !== allChecked) {
        setAllChecked(checked);
      }
      setCheckedIds(new Set());
    },
    [allChecked],
  );

  // Clear les checkboxes si la valeur clearCheckboxValue passée en context a changé
  useEffect(() => {
    if (clearCheckboxValue !== lastClearCheckboxValue) {
      setLastClearCheckboxValue(clearCheckboxValue);
      onAllCheckboxChange(false);
    }
  }, [clearCheckboxValue, lastClearCheckboxValue, onAllCheckboxChange]);

  const activeColumns = useMemo(
    () => [
      ...(checkbox
        ? [
            {
              label: (
                <label className="inline-block whitespace-nowrap min-w-10">
                  <Checkbox
                    checked={allChecked}
                    onChange={onAllCheckboxChange}
                    indeterminate={!allChecked && checkedIds.size > 0}
                  />
                  {(allChecked || checkedIds.size > 0) &&
                    ` (${maxPlus(allChecked ? totalResults : checkedIds.size, 9999)})`}
                </label>
              ),
              key: "_checkbox",
              format: (_, row) => (
                <Checkbox
                  value={row.id}
                  checked={allChecked || checkedIds.has(row.id)}
                  disabled={allChecked}
                  onChange={(checked) => onCheckboxChange(checked, row.id)}
                />
              ),
            },
          ]
        : []),
      ...columns.filter(
        (column) =>
          (column.onlyCustomer === undefined &&
            column.onlyManager === undefined &&
            column.onlyPartner === undefined) ||
          (column.onlyCustomer && isCustomer) ||
          (column.onlyManager && isManager) ||
          (column.onlyPartner && isPartner),
      ),
    ],
    [
      columns,
      isManager,
      isCustomer,
      isPartner,
      checkbox,
      allChecked,
      checkedIds,
      onCheckboxChange,
      onAllCheckboxChange,
      totalResults,
    ],
  );

  const setFiltersForQuery = useCallback(
    () => ({
      ...(setFilters && setFilters()),
      ...filters,
    }),
    [setFilters, filters],
  );

  const queryParams = useDataTableQueryParams({
    id,
    columns,
    activeSorts,
    activeFilters,
    currentPage,
    pageSize,
    setActiveFilters,
    setFilters: setFiltersForQuery,
    setCurrentPage,
    extraParams,
  });

  const queryString = stringifyQueryParams(queryParams);
  useEffect(() => {
    if (setQueryParams) {
      setQueryParams(queryParams);
    }
  }, [setQueryParams, queryString]); // eslint-disable-line react-hooks/exhaustive-deps

  const { data, meta, error, isLoading } = useSwrStrapi(
    `${endpointUrl}?${queryString}`,
    async (url) => {
      const res = await fetchApi(url);
      return {
        ...res,
        ...(formatData && formatData(res)),
      };
    },
  );
  const pageCount = meta?.pagination?.pageCount;

  // Mise à jour du nombre de résultats total pour la checkbox
  const total = meta?.pagination?.total;
  useEffect(() => setTotalResults(total), [total]);

  // Se replace en dernière page au cas où la page actuelle aurait été supprimée.
  useEffect(() => {
    if (currentPage > pageCount) {
      setCurrentPage(pageCount);
    }
  }, [pageCount, currentPage, setCurrentPage]);

  // Met à jour les critères de recherche des checkboxes
  const checkedIdsString = JSON.stringify([...checkedIds]);
  const queryParamsString = JSON.stringify(queryParams);
  useEffect(() => {
    if (!setCheckedQueryParams) {
      return;
    }

    const {
      sort: querySort,
      filters: queryFilters,
      pagination: queryPagination,
      ...queryExtraParams
    } = queryParams;

    if (allChecked) {
      setCheckedQueryParams({
        filters: queryFilters,
        sort: querySort,
        ...queryExtraParams,
      });
    } else if (checkedIds?.size > 0) {
      setCheckedQueryParams({
        filters: { id: { $in: [...checkedIds] } },
        sort: querySort,
        ...queryExtraParams,
      });
    } else {
      setCheckedQueryParams(null);
    }
  }, [setCheckedQueryParams, allChecked, checkedIdsString, queryParamsString]); // eslint-disable-line react-hooks/exhaustive-deps

  const hasPagination = pagination && pageCount > 1;
  const table = (
    <>
      {title && (
        <h3
          className={`${flex ? "flex flex-col" : ""} mt-6 text-base font-semibold`}
        >
          {title}
        </h3>
      )}
      <div
        className={
          flex ? "flex flex-col flex-1 overflow-y-hidden" : "min-h-[3rem]"
        }
      >
        <div className={flex ? "flex-1 overflow-y-auto" : ""}>
          <table
            className="border-separate border-spacing-y-0 w-full"
            cellPadding="0"
          >
            {headers && (
              <thead
                className={flex ? "sticky" : ""}
                style={{ insetBlockStart: 0 }}
              >
                <tr>
                  {activeColumns.map((column, index) => {
                    const className = `${column.className ?? ""} ${index === 0 ? `${!checkbox ? "pr-4" : "w-2"} pl-4` : "pr-4"}`;
                    const label = column.label ?? "";
                    if (column.sortFields && !noSort) {
                      return (
                        <SortableColumn
                          key={column.key}
                          className={className}
                          column={column}
                          direction={activeSorts[column.key]}
                          setDirection={(direction) =>
                            setActiveSorts({ [column.key]: direction })
                          }
                        >
                          {label}
                        </SortableColumn>
                      );
                    }
                    if (column.filterField && !noFilter) {
                      return (
                        <FilterableColumn
                          key={column.key}
                          className={className}
                          entity={entity}
                          filters={filters}
                          column={column}
                          filterValues={activeFilters[column.key]}
                          setFilterValues={(ids) =>
                            setActiveFilters({
                              ...activeFilters,
                              [column.key]: ids,
                            })
                          }
                        >
                          {label}
                        </FilterableColumn>
                      );
                    }
                    return (
                      <Column key={column.key} className={className}>
                        {label}
                      </Column>
                    );
                  })}
                </tr>
              </thead>
            )}
            <tbody>
              {data.map((row) => (
                <tr
                  className={`text-xs text-light-color ${setSelectedId ? "cursor-pointer" : ""}`}
                  key={`row_${row.id}`}
                  onClick={() =>
                    setSelectedId &&
                    setSelectedId(+row.id !== +selectedId ? +row.id : null)
                  }
                >
                  {activeColumns.map((column, index) => {
                    const isSelectedRow = selectedId && +selectedId === +row.id;
                    let innerClassName = "pr-4";
                    if (index === 0) {
                      innerClassName += " pl-4 rounded-l-lg";
                      innerClassName += isSelectedRow
                        ? " border-l-2"
                        : " border-l-0.5";
                    }
                    if (index === activeColumns.length - 1) {
                      innerClassName += " rounded-r-lg";
                      innerClassName += isSelectedRow
                        ? " border-r-2"
                        : " border-r-0.5";
                    }
                    innerClassName += isSelectedRow
                      ? " border-y-2 border-l-blue border-y-blue border-r-blue"
                      : " border-y";
                    return (
                      <Cell
                        key={column.key}
                        className={column.className ?? ""}
                        innerClassName={innerClassName}
                      >
                        {column.format
                          ? column.format(row[column.key], row)
                          : row[column.key]}
                      </Cell>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
          {error || isLoading ? (
            <Loader isLoading={isLoading} error={error} />
          ) : null}
          {!isLoading && totalResults === 0 && (
            <div className="w-full flex-1 flex justify-center items-center">
              <div className="text-main-color">
                {noResultMessage ?? "Aucun résultat"}
              </div>
            </div>
          )}
        </div>
      </div>
      {(hasPagination || footer) && (
        <div className="flex flex-row mt-4 gap-4">
          <div className="flex-1">{footer}</div>
          {hasPagination && (
            <PageSelector
              currentPage={currentPage}
              totalPages={meta?.pagination?.pageCount}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      )}
    </>
  );

  const isProject = ["mes-projets", "dossiers"].includes(
    pathname.split("/")[1],
  );
  const isSimulation = pathname.includes("mes-simulations");

  function ProjetMobileComponent({ data }) {
    const {
      dateCreation,
      adresseChantier,
      status,
      nom_offre: nomOffre,
      id,
    } = data;

    const date = new Date(dateCreation);
    const formattedDate = date.toLocaleDateString("fr-FR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return (
      <div
        className={`grid  px-2 py-5   rounded-lg border-0.5 border-Gris-500-Soregies ${isProject ? "grid-flow-col" : "grid-flow-row"}`}
      >
        <div
          className={`${isProject ? "col-span-2" : "col-span-4 mb-4"} flex flex-col place-self-start py-1 px-2`}
        >
          <span className="font-inter text-Bleu-fonc-Soregies text-xs font-normal leading-6 tracking-tight">
            {nomOffre}
          </span>
          <span className="font-inter text-Gris-500-Soregies text-2xs font-normal leading-4 tracking-tight">
            {adresseChantier}
          </span>
        </div>
        <div
          className={`p-2 ${!isProject ? "bg-Bleu-clair-Soregies col-start-2 col-span-3 rounded-r-md flex flex-col items-start justify-start gap-2" : "col-span-2 col-start-1 row-start-2"}`}
        >
          {!isProject && (
            <span className="text-3xs  text-Gris-500-Soregies font-normal leading-4 tracking-tight">
              Statut
            </span>
          )}
          <DossierStatus
            className="text-xs  col-start-2 justify-self-start"
            status={status}
          />
        </div>
        <div
          className={`flex  p-2 flex-col items-start justify-start text-start ${isProject ? "col-start-4 row-start-1 " : "col-start-1 row-start-2 bg-Bleu-clair-Soregies rounded-l-md"}`}
        >
          <span className="text-3xs  text-Gris-500-Soregies font-normal leading-4 tracking-tight">
            Créé le
          </span>
          <span className="text-2xs text-Bleu-fonc-Soregies font-normal leading-6 tracking-tight">
            {formattedDate}
          </span>
        </div>
        <div
          className={`flex col-start-4 items-start justify-end text-end ${!isProject && "hidden"}`}
        >
          <a href={`/mes-projets/${id}`}>
            <ArrowIcon className="w-4 h-4 rotate-90" />
          </a>
        </div>
      </div>
    );
  }

  function SimulationMobileComponent({ data }) {
    const {
      dateCreation,
      ville,
      adresse,
      code_postal: codePostal,
      nom_offre: nomOffre,
      prime_mpr: primeMpr,
      primeSoregies,
      reste_a_payer: resteAPayer,
      montant_travaux: montantTravaux,
      surface,
      type_de_logement: typeDeLogement,
      energie_de_chauffage: energieDeChauffage,
      id,
    } = data;

    const date = new Date(dateCreation);
    const formattedDate = date.toLocaleDateString("fr-FR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const mappingData = {
      "Coût des travaux": (montantTravaux ?? 0) + " €",
      "Prime Sorégies": (primeSoregies ?? 0) + " €",
      "Prime MPR": (primeMpr ?? 0) + " €",
      "Reste à payer": (resteAPayer ?? 0) + " €",
      "Energie de chauffage": energieDeChauffage,
      "Type de logement": typeDeLogement,
      Surface: (surface ?? 0) + " m²",
      "Créer le": formattedDate,
    };
    //
    const adresseComplete = `${adresse}, ${codePostal} ${ville}`;
    return (
      <div
        onClick={() => {
          if (!setSelectedId) return;
          setSelectedId((prev) => (prev === id ? null : id));
        }}
        className={`${selectedId === id && "shadow-top border-2"} grid grid-rows-auto gap-2  p-2 rounded-lg border-0.5 border-Gris-500-Soregies grid-flow-row`}
      >
        <div className="col-span-4 flex flex-col place-self-start p-2">
          <span className="text-Bleu-fonc-Soregies text-normal text-xs text-inter leading-6 tracking-tight">
            {nomOffre}
          </span>
          <span className="text-Gris-500-Soregies text-normal text-2xs text-inter leading-4 tracking-tight">
            {adresseComplete}
          </span>
        </div>
        {chunk(Object.entries(mappingData), 4).map((row, rowIndex) => {
          const isEven = rowIndex % 2 === 0;
          const bgColor = isEven ? "bg-Bleu-clair-Soregies" : "";
          return (
            <div
              key={`row-${rowIndex}`}
              className={`grid grid-cols-4 grid-rows-1 p-2  ${bgColor} col-span-4 rounded-md gap-4`}
            >
              {row.map(([key, value], colIndex) => {
                return (
                  <div
                    key={`item-${rowIndex}-${colIndex}`}
                    className={`flex flex-col gap-2  rounded-lg`}
                  >
                    <span className="text-3xs text-Gris-500-Soregies font-normal leading-tight tracking-tight break-words whitespace-normal">
                      {key}
                    </span>
                    <span className="text-2xs break-words whitespace-normal text-Bleu-fonc-Soregies font-normal leading-tight tracking-tight">
                      {value}
                    </span>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }

  ProjetMobileComponent.propTypes = {
    data: PropTypes.shape({
      dateCreation: PropTypes.string,
      adresseChantier: PropTypes.string,
      status: PropTypes.string,
      nom_offre: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
  };

  SimulationMobileComponent.propTypes = {
    data: PropTypes.shape({
      dateCreation: PropTypes.string,
      ville: PropTypes.string,
      adresse: PropTypes.string,
      code_postal: PropTypes.string,
      nom_offre: PropTypes.string,
      prime_mpr: PropTypes.number,
      primeSoregies: PropTypes.number,
      reste_a_payer: PropTypes.number,
      montant_travaux: PropTypes.number,
      surface: PropTypes.number,
      type_de_logement: PropTypes.string,
      energie_de_chauffage: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
  };

  const simulationMargin = hasPagination ? "mb-24" : "mb-20";
  const bottomMargin = isSimulation ? simulationMargin : "";

  return (
    <>
      <ConditionChecker condition={!isMobile}>
        <ConditionChecker condition={!flex}>
          <div>{table}</div>
        </ConditionChecker>
        <ConditionChecker condition={flex}>{table}</ConditionChecker>
      </ConditionChecker>
      <ConditionChecker condition={isMobile}>
        <ConditionChecker condition={isProject || entity === "dossier"}>
          <div className="flex flex-col gap-2 mb-24">
            {data.map((data, index) => {
              return <ProjetMobileComponent key={index} data={data} />;
            })}
          </div>
        </ConditionChecker>
        <ConditionChecker condition={isSimulation || entity === "simulation"}>
          <div className={`flex flex-col  gap-2 ${bottomMargin}`}>
            {data.map((data, index) => {
              return <SimulationMobileComponent key={index} data={data} />;
            })}
          </div>
        </ConditionChecker>
      </ConditionChecker>
      <ConditionChecker condition={isProject || isSimulation}>
        <div
          className={`block md:hidden absolute bottom-0 left-0  bg-white ${hasPagination || footer ? "p-4" : "h-1"} w-full shadow-top`}
        >
          <ConditionChecker condition={hasPagination || footer}>
            <div className=" flex-col  gap-4 flex ">
              <ConditionChecker condition={hasPagination}>
                <PageSelector
                  className="justify-center"
                  currentPage={currentPage}
                  totalPages={meta?.pagination?.pageCount}
                  setCurrentPage={setCurrentPage}
                />
              </ConditionChecker>
              <div className="flex-1">{footer}</div>
            </div>
          </ConditionChecker>
        </div>
      </ConditionChecker>
    </>
  );
}
DataTable.defaultProps = {
  pageSize: 10,
  flex: true,
  headers: true,
  pagination: true,
  filters: {},
  noFilter: false,
  noSort: false,
};
DataTable.propTypes = {
  id: PropTypes.string.isRequired,
  entity: PropTypes.string.isRequired,
  endpointUrl: PropTypes.string.isRequired,
  extraParams: PropTypes.instanceOf(Object),
  formatData: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(Object),
  defaultActiveSort: PropTypes.string,
  title: PropTypes.any,
  setFilters: PropTypes.func,
  filters: PropTypes.instanceOf(Object),
  noFilter: PropTypes.bool,
  noSort: PropTypes.bool,
  pageSize: PropTypes.number,
  flex: PropTypes.bool,
  headers: PropTypes.bool,
  pagination: PropTypes.bool,
  footer: PropTypes.any,
  selectedId: PropTypes.number,
  setSelectedId: PropTypes.func,
  checkbox: PropTypes.bool,
  setCheckedQueryParams: PropTypes.func,
  noResultMessage: PropTypes.any,
  setQueryParams: PropTypes.func,
};
