import React from "react";
import PropTypes from "prop-types";
import { useAuth } from "providers/AuthProvider";
import usePartenairesOptions, {
  getPartenaireLabel,
} from "hooks/usePartenairesOptions";
import Tab from "components/Tab";
import TabForm from "components/TabForm";
import iconID from "images/icons/id.svg";
import { ReactComponent as LoadingIcon } from "images/icons/loading.svg";
import { Link } from "react-router-dom";
import iconDoc from "images/icons/doc.svg";
import iconDate from "images/icons/date.svg";
import optionsFost from "constants/fosts";
import domainesTravaux from "constants/domainesTravaux";

export default function Chantier({ id, data, fetchData }) {
  const { isAdmin } = useAuth();
  const dossier = data?.data?.attributes;
  const chantiers = dossier?.chantiers?.data;
  const firstChantier = chantiers?.[0]?.attributes;
  const firstChantierId = chantiers?.[0]?.id;
  const fosts = dossier?.fosts?.data;
  const firstFost = fosts?.[0]?.attributes;
  const firstFostId = fosts?.[0]?.id;
  const currentFost = firstFost?.numero_fost;
  const entreprise = dossier?.entreprise_de_travaux?.data?.attributes;
  const entrepriseId = dossier?.entreprise_de_travaux?.data?.id;
  const partenaire = dossier?.partenaire?.data?.attributes;
  const partenaireId = dossier?.partenaire?.data?.id;

  const { partenairesOptions, mutate } = usePartenairesOptions();

  const fostFields = {
    nombre_fenetres: {
      name: "nombre_fenetres",
      label: "Nombre de fenêtres ou porte-fenêtre",
      value: firstFost?.nombre_fenetres,
      route: "fost",
      sectionID: firstFostId,
      type: "number",
      maxLength: 4,
    },
    type_de_fenetres: {
      name: "type_de_fenetres",
      label: "Type de fenêtres",
      value: firstFost?.type_de_fenetres,
      route: "fost",
      sectionID: firstFostId,
      type: "select",
      options: ["Fenêtre ou porte-fenêtre", "Doubles fenêtres", "Toiture"],
    },
    surface_de_fenetres: {
      name: "surface_de_fenetres",
      label: "Surface de fenêtres (m2)",
      value: firstFost?.surface_de_fenetres,
      route: "fost",
      sectionID: firstFostId,
      type: "decimalnumber",
      maxLength: 10,
      decimals: 2,
    },
    energie_de_chauffage: {
      name: "energie_de_chauffage",
      label: "Energies de chauffage",
      value: firstFost?.energie_de_chauffage,
      route: "fost",
      sectionID: firstFostId,
      type: "select",
      options:
        currentFost?.indexOf("Coup") !== -1
          ? ["Fioul", "Gaz", "Charbon"]
          : ["Electricité", "Fioul", "Gaz", "Charbon"],
    },
    coefficient_de_transmission_surfacique: {
      name: "coefficient_de_transmission_surfacique",
      label: "Uw (W/m2)",
      value: firstFost?.coefficient_de_transmission_surfacique,
      route: "fost",
      sectionID: firstFostId,
      type: "decimalnumber",
      maxLength: 10,
      decimals: 2,
    },
    facteur_solaire: {
      name: "facteur_solaire",
      label: "Facteur solaire (Sw)",
      value: firstFost?.facteur_solaire,
      route: "fost",
      sectionID: firstFostId,
      type: "decimalnumber",
      maxLength: 10,
      decimals: 2,
    },
    marque: {
      name: "marque",
      label: "Marque",
      value: firstFost?.marque,
      route: "fost",
      sectionID: firstFostId,
      maxLength: 255,
    },
    reference: {
      name: "reference",
      label: "Référence",
      value: firstFost?.reference,
      route: "fost",
      sectionID: firstFostId,
      maxLength: 255,
    },
    surface_chauffee: {
      name: "surface_chauffee",
      label: "Surface chauffée",
      value: firstFost?.surface_chauffee,
      route: "fost",
      sectionID: firstFostId,
      type: "decimalnumber",
      maxLength: 10,
      decimals: 2,
    },
    etas: {
      name: "etas",
      label: "Etas (%)",
      value: firstFost?.etas,
      route: "fost",
      sectionID: firstFostId,
      type: "number",
      maxLength: 4,
    },
    nombre_operations: {
      name: "nombre_operations",
      label: "Nombre d'opérations",
      value: firstFost?.nombre_operations,
      route: "fost",
      sectionID: firstFostId,
      type: "number",
      maxLength: 4,
    },
    pompe_a_chaleur: {
      name: "pompe_a_chaleur",
      label: "PAC",
      value: firstFost?.pompe_a_chaleur,
      route: "fost",
      sectionID: firstFostId,
      type: "select",
      options: ["Air/Eau", "Eau/Eau"],
    },
    type_de_pompe_a_chaleur: {
      name: "type_de_pompe_a_chaleur",
      label: "Type de PAC",
      value: firstFost?.type_de_pompe_a_chaleur,
      route: "fost",
      sectionID: firstFostId,
      type: "select",
      options:
        currentFost === "BAR-TH-159 Coup de pouce"
          ? ["Moyenne température", "Haute température"]
          : ["Basse température", "Moyenne température", "Haute température"],
    },
    note_de_dimensionnement: {
      name: "note_de_dimensionnement",
      label: "Note de dimensionnement",
      value: firstFost?.note_de_dimensionnement,
      route: "fost",
      sectionID: firstFostId,
      type: "checkbox",
    },
    surface_habitable: {
      name: "surface_habitable",
      label: "Surface habitable",
      value: firstFost?.surface_habitable,
      route: "fost",
      sectionID: firstFostId,
      type: "decimalnumber",
      maxLength: 10,
      decimals: 2,
    },
    classe_du_regulateur: {
      name: "classe_du_regulateur",
      label: "Classe du régulateur",
      value: firstFost?.classe_du_regulateur,
      route: "fost",
      sectionID: firstFostId,
      type: "select",
      options: ["IV", "V", "VI", "VII", "VIII"],
    },
    type_appareil_chauffage_bois: {
      name: "type_appareil_chauffage_bois",
      label: "Type d'appareil de chauffage au bois",
      value: firstFost?.type_appareil_chauffage_bois,
      route: "fost",
      sectionID: firstFostId,
      type: "select",
      options: [
        "Poêle",
        "Foyer fermé",
        "Insert de cheminée intérieure",
        "Cuisinière en mode de chauffage",
      ],
    },
    type_de_combustible: {
      name: "type_de_combustible",
      label: "Type de combustible",
      value: firstFost?.type_de_combustible,
      route: "fost",
      sectionID: firstFostId,
      type: "select",
      options: ["Granulés", "Bois autre que sous forme de granulés"],
    },
    label_flamme_verte: {
      name: "label_flamme_verte",
      label: "Label Flamme Verte",
      value: firstFost?.label_flamme_verte,
      route: "fost",
      sectionID: firstFostId,
      type: "select",
      options: ["Oui", "Non"],
    },
    emissions_de_particules: {
      name: "emissions_de_particules",
      label: "Emissions de particules",
      value: firstFost?.emissions_de_particules,
      route: "fost",
      sectionID: firstFostId,
      type: "floatnumber",
      maxLength: 20,
    },
    emissions_de_composes_organiques_gazeux: {
      name: "emissions_de_composes_organiques_gazeux",
      label: "Emissions de composés organiques gazeux",
      value: firstFost?.emissions_de_composes_organiques_gazeux,
      route: "fost",
      sectionID: firstFostId,
      type: "floatnumber",
      maxLength: 20,
    },
    emissions_de_monoxyde_de_carbonne: {
      name: "emissions_de_monoxyde_de_carbonne",
      label: "Emissions de monoxyde de carbone",
      value: firstFost?.emissions_de_monoxyde_de_carbonne,
      route: "fost",
      sectionID: firstFostId,
      type: "floatnumber",
      maxLength: 20,
    },
    emissions_oxyde_azote: {
      name: "emissions_oxyde_azote",
      label: "Emissions d’oxyde d’azote",
      value: firstFost?.emissions_oxyde_azote,
      route: "fost",
      sectionID: firstFostId,
      type: "floatnumber",
      maxLength: 20,
    },
    norme_de_mesure_des_emissions: {
      name: "norme_de_mesure_des_emissions",
      label: "Norme de mesure des émissions",
      value: firstFost?.norme_de_mesure_des_emissions,
      route: "fost",
      sectionID: firstFostId,
      type: "select",
      options: [
        "pour les poêles : norme NF EN 13240 ou NF EN 14785 ou NF EN 15250",
        "pour les foyers fermés, inserts de cheminées intérieures : norme NF EN 13229",
        "pour les cuisinières utilisées comme mode de chauffage : norme NF EN 12815",
      ],
    },
    type_alimentation_chaudiere: {
      name: "type_alimentation_chaudiere",
      label: "Type d'alimentation de la chaudière",
      value: firstFost?.type_alimentation_chaudiere,
      route: "fost",
      sectionID: firstFostId,
      type: "select",
      options: ["Automatique", "Manuelle"],
    },
    installation_silo: {
      name: "installation_silo",
      label: "Installation d'un silo ou d'un ballon tampon",
      value: firstFost?.installation_silo,
      route: "fost",
      sectionID: firstFostId,
      type: "select",
      options: ["Silo", "Ballon tampon", "Aucun"],
    },
    volume_silo: {
      name: "volume_silo",
      label: "Volume du silo le cas échéant",
      value: firstFost?.volume_silo,
      route: "fost",
      sectionID: firstFostId,
      type: "decimalnumber",
      maxLength: 10,
      decimals: 2,
    },
    puissance_nominale: {
      name: "puissance_nominale",
      label: "Puissance nominale",
      value: firstFost?.puissance_nominale,
      route: "fost",
      sectionID: firstFostId,
      type: "decimalnumber",
      maxLength: 10,
      decimals: 2,
    },
    puissance_chaudiere: {
      name: "puissance_chaudiere",
      label: "Puissance chaudière",
      value: firstFost?.puissance_chaudiere,
      route: "fost",
      sectionID: firstFostId,
      type: "decimalnumber",
      maxLength: 10,
      decimals: 2,
    },
    scop: {
      name: "scop",
      label: "SCOP",
      value: firstFost?.scop,
      route: "fost",
      sectionID: firstFostId,
      type: "decimalnumber",
      maxLength: 10,
      decimals: 2,
    },
    productivite_capteurs_solaires: {
      name: "productivite_capteurs_solaires",
      label: "Productivité des capteurs solaires",
      value: firstFost?.productivite_capteurs_solaires,
      route: "fost",
      sectionID: firstFostId,
      type: "decimalnumber",
      maxLength: 10,
      decimals: 2,
    },
    surface_capteurs_solaires: {
      name: "surface_capteurs_solaires",
      label: "Surface des capteurs installés (m²)",
      value: firstFost?.surface_capteurs_solaires,
      route: "fost",
      sectionID: firstFostId,
      type: "decimalnumber",
      maxLength: 10,
      decimals: 2,
    },
    capacite_de_stockage_ballon_eau_chaude: {
      name: "capacite_de_stockage_ballon_eau_chaude",
      label: "Capacité de stockage du ballon d'eau chaude (L)",
      value: firstFost?.capacite_de_stockage_ballon_eau_chaude,
      route: "fost",
      sectionID: firstFostId,
      type: "decimalnumber",
      maxLength: 10,
      decimals: 2,
    },
    certification_capteurs_solaires: {
      name: "certification_capteurs_solaires",
      label: "Certification des capteurs solaires",
      value: firstFost?.certification_capteurs_solaires,
      route: "fost",
      sectionID: firstFostId,
      type: "select",
      options: ["CSTBat", "Soarkeymark", "autre"],
    },
    bonus_coup_de_pouce: {
      name: "bonus_coup_de_pouce",
      label: "bonus coup de pouce",
      value: firstFost?.bonus_coup_de_pouce,
      route: "fost",
      sectionID: firstFostId,
      maxLength: 255,
    },
    cop: {
      name: "cop",
      label: "COP",
      value: firstFost?.cop,
      route: "fost",
      sectionID: firstFostId,
      type: "decimalnumber",
      maxLength: 10,
      decimals: 2,
    },
    type_chauffe_eau: {
      name: "type_chauffe_eau",
      label: "Type de chauffe-eau",
      value: firstFost?.type_chauffe_eau,
      route: "fost",
      sectionID: firstFostId,
      type: "select",
      options: ["Sur air extrait", "Autre"],
    },
    taux_couverture_pompe_a_chaleur: {
      name: "taux_couverture_pompe_a_chaleur",
      label: "Taux de couverture de la PAC (%)",
      value: firstFost?.taux_couverture_pompe_a_chaleur,
      route: "fost",
      sectionID: firstFostId,
      type: "decimalnumber",
      maxLength: 10,
      decimals: 2,
    },
    usage: {
      name: "usage",
      label: "Usage",
      value: firstFost?.usage,
      route: "fost",
      sectionID: firstFostId,
      type: "select",
      options: ["Chauffage", "Chauffage et ECS"],
    },
  };

  if (!data) {
    return (
      <div className="mt-4 w-[727px]">
        <LoadingIcon />
      </div>
    );
  } else if (!firstChantier || !firstFost) {
    return (
      <div className="mt-4 flex justify-center items-center h-full">
        <div className="border border-blue text-main-color text-xl rounded-2xl border-dashed p-10">
          Il n&apos;y a pas d&apos;ID pour le chantier, contactez
          l&apos;administrateur pour en créer un.
        </div>
      </div>
    );
  } else {
    const dataFost = [
      {
        label:
          "BAR-EN-104 Fenêtre ou porte-fenêtre complète avec vitrage isolant",
        value: "BAR-EN-104",
      },
      {
        label: "BAR-TH-104 Pompe à chaleur de type air/eau ou eau/eau",
        value: "BAR-TH-104",
      },
      {
        label:
          "BAR-TH-104 Coup de pouce Pompe à chaleur de type air/eau ou eau/eau",
        value: "BAR-TH-104 Coup de pouce",
      },
      {
        label:
          "BAR-TH-106 Chaudière individuelle à haute performance énergétique",
        value: "BAR-TH-106",
      },
      {
        label: "BAR-TH-112 Appareil indépendant de chauffage au bois",
        value: "BAR-TH-112",
      },
      {
        label: "BAR-TH-113 Chaudière biomasse individuelle",
        value: "BAR-TH-113",
      },
      {
        label: "BAR-TH-113 Coup de pouce Chaudière biomasse individuelle",
        value: "BAR-TH-113 Coup de pouce",
      },
      {
        label: "BAR-TH-129 Pompe à chaleur de type air/air",
        value: "BAR-TH-129",
      },
      {
        label:
          "BAR-TH-143 Coup de pouce Système solaire combiné (France métropolitaine)",
        value: "BAR-TH-143 Coup de pouce",
      },
      {
        label: "BAR-TH-148 Chauffe-eau thermodynamique à accumulation",
        value: "BAR-TH-148",
      },
      {
        label: "BAR-TH-159 Coup de pouce Pompe à chaleur hybride individuelle",
        value: "BAR-TH-159 Coup de pouce",
      },
      {
        label: "BAR-TH-171 Pompe à chaleur de type air/eau",
        value: "BAR-TH-171",
      },
      {
        label: "BAR-TH-171 Coup de pouce Pompe à chaleur de type air/eau",
        value: "BAR-TH-171 Coup de pouce",
      },
      {
        label: "BAR-TH-172 Pompe à chaleur de type eau/eau",
        value: "BAR-TH-172",
      },
      {
        label: "BAR-TH-172 Coup de pouce Pompe à chaleur de type eau/eau",
        value: "BAR-TH-172 Coup de pouce",
      },
    ];

    const getLabelByValue = (valueToFind) => {
      for (const item of dataFost) {
        if (item.value === valueToFind) {
          return item.label;
        }
      }
      return null; // Return null if the value is not found
    };

    const dynamiqueFostFields = {
      "BAR-EN-104": [
        // fostFields.nombre_operations,
        fostFields.nombre_fenetres,
        fostFields.type_de_fenetres,
        fostFields.surface_de_fenetres,
        fostFields.energie_de_chauffage,
        fostFields.coefficient_de_transmission_surfacique,
        fostFields.facteur_solaire,
        fostFields.marque,
        fostFields.reference,
      ],
      "BAR-TH-104": [
        fostFields.surface_chauffee,
        fostFields.marque,
        fostFields.reference,
        fostFields.etas,
        fostFields.nombre_operations,
        fostFields.pompe_a_chaleur,
        fostFields.type_de_pompe_a_chaleur,
        fostFields.note_de_dimensionnement,
      ],
      "BAR-TH-104 Coup de pouce": [
        fostFields.marque,
        fostFields.reference,
        fostFields.etas,
        fostFields.type_de_pompe_a_chaleur,
        fostFields.energie_de_chauffage,
        // fostFields.bonus_coup_de_pouce,
        fostFields.pompe_a_chaleur,
        // fostFields.type_de_fenetres,
        fostFields.surface_chauffee,
        fostFields.note_de_dimensionnement,
      ],
      "BAR-TH-106": [
        fostFields.marque,
        fostFields.reference,
        fostFields.surface_habitable,
        fostFields.etas,
        fostFields.classe_du_regulateur,
        fostFields.nombre_operations,
      ],
      "BAR-TH-112": [
        fostFields.nombre_operations,
        fostFields.marque,
        fostFields.reference,
        fostFields.type_appareil_chauffage_bois,
        fostFields.type_de_combustible,
        fostFields.etas,
        fostFields.label_flamme_verte,
        fostFields.emissions_de_particules,
        fostFields.emissions_de_composes_organiques_gazeux,
        fostFields.emissions_de_monoxyde_de_carbonne,
        fostFields.emissions_oxyde_azote,
        fostFields.norme_de_mesure_des_emissions,
      ],
      "BAR-TH-113": [
        fostFields.nombre_operations,
        fostFields.marque,
        fostFields.reference,
        fostFields.type_alimentation_chaudiere,
        fostFields.installation_silo,
        fostFields.volume_silo,
        fostFields.puissance_nominale,
        fostFields.etas,
        fostFields.classe_du_regulateur,
        fostFields.label_flamme_verte,
        fostFields.emissions_de_particules,
        fostFields.emissions_de_composes_organiques_gazeux,
        fostFields.emissions_de_monoxyde_de_carbonne,
        fostFields.emissions_oxyde_azote,
        fostFields.note_de_dimensionnement,
      ],
      "BAR-TH-113 Coup de pouce": [
        fostFields.nombre_operations,
        fostFields.marque,
        fostFields.reference,
        fostFields.type_alimentation_chaudiere,
        fostFields.installation_silo,
        fostFields.volume_silo,
        fostFields.puissance_nominale,
        fostFields.etas,
        fostFields.classe_du_regulateur,
        fostFields.label_flamme_verte,
        fostFields.emissions_de_particules,
        fostFields.emissions_de_composes_organiques_gazeux,
        fostFields.emissions_de_monoxyde_de_carbonne,
        fostFields.emissions_oxyde_azote,
        fostFields.note_de_dimensionnement,
        fostFields.energie_de_chauffage,
        // fostFields.bonus_coup_de_pouce,
      ],
      "BAR-TH-129": [
        fostFields.surface_chauffee,
        fostFields.nombre_operations,
        fostFields.marque,
        fostFields.reference,
        fostFields.puissance_nominale,
        fostFields.scop,
      ],
      "BAR-TH-143 Coup de pouce": [
        fostFields.marque,
        fostFields.reference,
        fostFields.productivite_capteurs_solaires,
        fostFields.surface_capteurs_solaires,
        fostFields.capacite_de_stockage_ballon_eau_chaude,
        fostFields.certification_capteurs_solaires,
        fostFields.energie_de_chauffage,
        // fostFields.bonus_coup_de_pouce,
      ],
      "BAR-TH-148": [
        fostFields.nombre_operations,
        fostFields.marque,
        fostFields.reference,
        fostFields.cop,
        fostFields.type_chauffe_eau,
      ],
      "BAR-TH-159 Coup de pouce": [
        fostFields.surface_chauffee,
        fostFields.marque,
        fostFields.reference,
        fostFields.classe_du_regulateur,
        fostFields.etas,
        fostFields.taux_couverture_pompe_a_chaleur,
        fostFields.type_de_pompe_a_chaleur,
        fostFields.note_de_dimensionnement,
        fostFields.energie_de_chauffage,
        // fostFields.bonus_coup_de_pouce,
      ],
      "BAR-TH-171": [
        fostFields.surface_chauffee,
        fostFields.marque,
        fostFields.reference,
        fostFields.etas,
        fostFields.nombre_operations,
        fostFields.type_de_pompe_a_chaleur,
        fostFields.note_de_dimensionnement,
        fostFields.usage,
      ],
      "BAR-TH-171 Coup de pouce": [
        fostFields.surface_chauffee,
        fostFields.marque,
        fostFields.reference,
        fostFields.etas,
        fostFields.energie_de_chauffage,
        fostFields.nombre_operations,
        fostFields.type_de_pompe_a_chaleur,
        fostFields.note_de_dimensionnement,
        fostFields.usage,
      ],
      "BAR-TH-172": [
        fostFields.surface_chauffee,
        fostFields.marque,
        fostFields.reference,
        fostFields.etas,
        fostFields.nombre_operations,
        fostFields.type_de_pompe_a_chaleur,
        fostFields.note_de_dimensionnement,
        fostFields.usage,
      ],
      "BAR-TH-172 Coup de pouce": [
        fostFields.surface_chauffee,
        fostFields.marque,
        fostFields.reference,
        fostFields.etas,
        fostFields.energie_de_chauffage,
        fostFields.nombre_operations,
        fostFields.type_de_pompe_a_chaleur,
        fostFields.note_de_dimensionnement,
        fostFields.usage,
      ],
    };
    const fostUnknown = firstFost?.numero_fost === "FOST à renseigner";
    const tabData = [
      {
        title: "Informations chantier",
        icon: iconID,
        fields: [
          {
            name: "numero_fost",
            label: "FOST",
            value: firstFost?.numero_fost,
            type: "select",
            route: "fost",
            sectionID: firstFostId,
            options: optionsFost,
            emptyLabel: "FOST à renseigner",
          },
          {
            name: "adresse",
            label: "Adresse chantier",
            value: firstChantier.adresse,
            route: "chantier",
            sectionID: firstChantierId,
            maxLength: 255,
          },
          {
            name: "ville",
            label: "Ville du chantier",
            value: firstChantier.ville,
            route: "chantier",
            sectionID: firstChantierId,
            maxLength: 255,
          },
          {
            name: "code_postal",
            label: "Code postal du chantier",
            value: firstChantier.code_postal,
            route: "chantier",
            sectionID: firstChantierId,
            nospace: true,
            type: "number",
            minLength: 5,
            maxLength: 5,
          },
          {
            name: "parcelle_cadastrale",
            label: "Parcelle cadastrale",
            value: firstChantier.parcelle_cadastrale,
            route: "chantier",
            sectionID: firstChantierId,
            maxLength: 255,
          },
          {
            name: "motif_abandon",
            label: "Motif de l'abandon",
            value: dossier?.motif_abandon,
            visible: dossier?.statut === "Abandon",
          },
          {
            name: "type_de_logement",
            label: "Type de Logement",
            value: firstChantier.type_de_logement,
            type: "select",
            route: "chantier",
            sectionID: firstChantierId,
            options:
              currentFost === "BAR-TH-172" ||
              currentFost === "BAR-TH-172 Coup de pouce"
                ? ["Maison individuelle"]
                : ["Maison individuelle", "Appartement"],
          },
          {
            name: "motif_non_conformite",
            label: "Motif de non-conformité",
            value: firstChantier.motif_non_conformite,
            route: "chantier",
            sectionID: firstChantierId,
            visible: dossier?.statut === "Chantier non conforme",
          },
          {
            name: "plus_de_deux_ans",
            label: "Bâtiment construit depuis plus de 2 ans",
            value: firstChantier.plus_de_deux_ans,
            route: "chantier",
            sectionID: firstChantierId,
            type: "checkbox",
          },
          {
            name: "Commentaire_Emmy",
            label: "Commentaire Emmy",
            value: firstChantier.Commentaire_Emmy,
            route: "chantier",
            sectionID: firstChantierId,
          },
          // {
          //   name: "etape_du_litige",
          //   label: "Etape du litige",
          //   value: dossier?.etape_du_litige,
          //   type: "select",
          //   visible: dossier?.statut === "Litige",
          //   options: ["Avant travaux", "Après travaux"],
          // },
          {
            name: "motif_du_litige",
            label: "Motif du litige",
            value: dossier?.motif_du_litige,
            visible: dossier?.statut === "Litige",
          },
        ],
      },
      ...(fostUnknown
        ? []
        : [
            {
              title: getLabelByValue(firstFost?.numero_fost),
              icon: iconDoc,
              fields: dynamiqueFostFields[firstFost?.numero_fost],
              fostTitle: firstFost?.numero_fost,
            },
          ]),
      {
        title: "Entreprise partenaire",
        icon: iconDoc,
        fields: [
          {
            name: "partenaire",
            label: "Entreprise partenaire",
            value: partenaireId,
            type: "select",
            options: partenairesOptions,
            noEditAll: true,
            emptyLabel:
              dossier?.partenaire?.data &&
              !partenairesOptions.find(({ value }) => value === partenaireId)
                ? getPartenaireLabel(dossier?.partenaire?.data)
                : "Aucune",
          },
          {
            name: "users",
            label: "Comptes utilisateur de l'entreprise partenaire",
            visible: !!partenaire?.users?.data,
            value: (
              <ul>
                {partenaire?.users?.data?.map(
                  ({ id, attributes: { name, firstname } }) => (
                    <li key={id}>
                      {isAdmin ? (
                        <Link
                          to={`/parametres/utilisateurs/${id}`}
                          className="underline"
                        >
                          {firstname} {name}
                        </Link>
                      ) : (
                        <>
                          {firstname} {name}
                        </>
                      )}
                    </li>
                  ),
                )}
              </ul>
            ),
            type: "readonly",
          },
        ],
      },
      {
        title: "Informations pro",
        icon: iconDoc,
        fields: [
          // { name: "siren", label: "Numéros Volet", value: entreprise?.siren, route: 'compte', sectionID: entrepriseId },
          {
            name: "siret",
            label: "Siret professionnel",
            value: entreprise?.siret,
            route: "compte",
            sectionID: entrepriseId,
            nospace: true,
            maxLength: 14,
          },
          {
            name: "raison_sociale",
            label: "Raison sociale professionnel",
            value: entreprise?.raison_sociale,
            route: "compte",
            sectionID: entrepriseId,
            maxLength: 255,
          },
          {
            name: "rge",
            label: "RGE",
            value: entreprise?.rge,
            type: "select",
            route: "compte",
            sectionID: entrepriseId,
            options: ["Oui", "Non", "A vérifier"],
          },
          // { name: "Adresse mail", label: "Adresse mail", value: "jeandupont@soregies.com"},
          // { name: "Nombre de personne composant le ménage", label: "Nombre de personnes composant le ménage", value: "Nombre"},
          {
            name: "date_certification_rge",
            label: "Date certification RGE",
            value: entreprise?.date_certification_rge,
            type: "date",
            route: "compte",
            sectionID: entrepriseId,
          },
          {
            name: "domaines_de_travaux",
            label: "Domaine de travaux",
            value: entreprise?.domaines_de_travaux,
            type: "select",
            route: "compte",
            sectionID: entrepriseId,
            multiple: true,
            options: domainesTravaux,
          },
          {
            name: "Siren_organisme_de_controle",
            label: "Siren organisme de controle",
            value: entreprise?.Siren_organisme_de_controle,
            route: "compte",
            sectionID: entrepriseId,
            nospace: true,
            maxLength: 9,
          },
          {
            name: "Raison_sociale_organisme_de_controle",
            label: "Raison sociale organisme de controle",
            value: entreprise?.Raison_sociale_organisme_de_controle,
            route: "compte",
            sectionID: entrepriseId,
            maxLength: 255,
          },
          {
            name: "Siret_sous_traitant",
            label: "Siret sous traitant",
            value: entreprise?.Siret_sous_traitant,
            route: "compte",
            sectionID: entrepriseId,
            nospace: true,
            maxLength: 14,
          },
          {
            name: "Raison_sociale_sous_traitant",
            label: "Raison sociale sous traitant",
            value: entreprise?.Raison_sociale_sous_traitant,
            route: "compte",
            sectionID: entrepriseId,
            maxLength: 255,
          },
        ],
      },
      {
        title: "Dates",
        icon: iconDate,
        fields: [
          {
            name: "date_envoi_rai",
            label:
              "Date d'envoi du RAI et date de proposition du cadre de contribution",
            value: dossier?.date_envoi_rai,
            type: "date",
          },
          {
            name: "date_signature_devis",
            label: "Date de signature du devis",
            value: firstChantier.date_signature_devis,
            type: "date",
            route: "chantier",
            sectionID: firstChantierId,
          },
          {
            name: "date_signature_ah",
            label: "Date de signature de l’AH",
            value: dossier?.date_signature_ah,
            type: "date",
          },
          {
            name: "date_edition_facture",
            label: "Date d’édition de la facture",
            value: firstChantier.date_edition_facture,
            type: "date",
            route: "chantier",
            sectionID: firstChantierId,
          },
          {
            name: "date_versement_prime",
            label: "Date de versement de la prime",
            value: dossier?.date_versement_prime,
            type: "date",
          },
          {
            name: "createdAt",
            label: "Date de création",
            value: dossier?.createdAt,
            type: "date",
          },
          // { name: "updatedAt", label: "Date de modification", value: dossier?.updatedAt, type: 'date' },
        ],
      },
    ];

    return (
      <div className="mt-4 w-[727px]">
        {tabData.map((tab) => (
          <Tab title={tab.title} icon={tab.icon} key={tab.title}>
            <TabForm
              tab={tab}
              id={id}
              fetchData={() => {
                mutate();
                fetchData();
              }}
            ></TabForm>
          </Tab>
        ))}
      </div>
    );
  }
}
Chantier.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  fetchData: PropTypes.func.isRequired,
};
