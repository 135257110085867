import React, { useEffect, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { translateMessage } from "functions";
import { mutate } from "swr";
import AuthMessage from "./AuthMessage";
import useFetchApi from "hooks/useFetchApi";
import { ReactComponent as SuccessIcon } from "images/icons/success.svg";

export default function EmailConfirmation() {
  const [fetchApi] = useFetchApi();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const confirmation = searchParams.get("confirmation") ?? "";

  const confirmEmail = useCallback(async () => {
    if (confirmation !== "") {
      try {
        setLoading(true);
        await fetchApi("auth/email-confirmation", {
          params: { confirmation },
        });
        // Trigger a revalidation (refetch).
        mutate("/api/auth/local");
        setLoading(false);
      } catch (e) {
        setError(translateMessage(e.message));
      }
    }
    setSuccess(true);
  }, [confirmation, fetchApi]);

  useEffect(() => {
    // Effectue la confirmation sur la prochaine frame pour éviter un envoi double en strict mode
    const confirmTimeout = setTimeout(confirmEmail, 0);
    return () => clearTimeout(confirmTimeout);
  }, [confirmEmail]);

  const message = success ? (
    <>
      Votre adresse e-mail est confirmée.
      <br />
      Vous pouvez à présent vous connecter.
    </>
  ) : (
    error ?? "Adresse en cours de confirmation…"
  );

  return (
    <AuthMessage
      title={
        <>
          {success && (
            <SuccessIcon className="mr-2 inline-block align-middle h-5" />
          )}
          Confirmation de votre adresse e-mail
        </>
      }
      message={!error && message}
      error={error}
      loading={loading}
      button={success}
    />
  );
}
