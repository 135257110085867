import React, { useState, useCallback, createContext, useContext } from "react";
import PropTypes from "prop-types";
import RefreshProvider, { useRefresh } from "providers/RefreshProvider";
import { useAuth } from "providers/AuthProvider";
import SimulationList from "components/Simulation/SimulationList";
import Main from "components/Main";
import Search from "components/Search";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/Button";
import useFetchApi from "hooks/useFetchApi";
import { useConfirm } from "components/ConfirmDialog";
import { removeAccents } from "utils/string";

const SimulationsContext = createContext();

const ManagerLinks = () => {
  const { archive, isManager } = useContext(SimulationsContext);
  if (isManager) return null;
  return (
    <div className="md:border-b-2 capitalize md:pb-2 md:text-base text-sm flex flex-row gap-4 leading-5 font-normal ">
      <Link to={`/mes-simulations`}>
        <button
          className={
            !archive
              ? "inline md:mx-4 text-main-color font-medium underline decoration-2 md:underline-offset-[14px]"
              : "inline md:mx-4 font-medium text-[#9E9E9E]"
          }
        >
          Simulation
        </button>
      </Link>
      <Link to={`/mes-simulations/archives`}>
        <button
          className={
            archive
              ? "inline md:mx-4 text-main-color font-medium underline decoration-2 md:underline-offset-[14px]"
              : "inline md:mx-4 font-medium text-[#9E9E9E]"
          }
        >
          Archives
        </button>
      </Link>
    </div>
  );
};

const Footer = () => {
  const {
    isManager,
    selectedId,
    setSelectedId,
    confirmConversion,
    refresh,
    user,
    navigation,
    fetchApi,
  } = useContext(SimulationsContext);

  if (isManager) return null;
  return (
    <Button
      className="font-inter text-xs md:text-sm font-semibold leading-5 "
      label="Initier ma demande de prime"
      variant="contained"
      disabled={!selectedId}
      onClick={() =>
        confirmConversion({
          text: (
            <>
              Êtes-vous{" "}
              {user.title === "M."
                ? "sûr"
                : user.title === "Mme"
                  ? "sûre"
                  : "sûr(e)"}{" "}
              d&apos;initier votre
              <br />
              demande de prime&nbsp;?
            </>
          ),
          subText: (
            <>
              En confirmant initier votre demander de prime, <br />
              vous acceptez les{" "}
              <span
                onClick={() => {
                  navigation("/cgu");
                }}
                className="underline cursor-pointer"
              >
                CGU
              </span>{" "}
              et{" "}
              <span
                onClick={() => {
                  window.open(
                    "https://www.soregies.fr/particuliers/primes-et-financements/conditions-generales-de-ventes-cgv-de-loffre-cee/",
                    "_blank",
                  );
                }}
                className="underline cursor-pointer"
              >
                CGV
              </span>
              .
            </>
          ),
          successText: (
            <>
              Votre projet a bien été envoyé à Sorégies.
              <br />
              Vous pouvez retrouver votre simulation dans l&apos;onglet
              Archives, en attente de validation.
            </>
          ),
          action: async () => {
            await fetchApi(`simulation/request-conversion/${selectedId}`);
            refresh();
            setSelectedId(null);
          },
        })
      }
    />
  );
};

const SimulationListSelector = () => {
  const { archive, setFilters, selectedId, setSelectedId, isManager } =
    useContext(SimulationsContext);

  if (archive)
    return (
      <>
        <SimulationList
          setFilters={setFilters}
          filters={{ statut: { $in: ["Demande de transformation"] } }}
          pageSize={5}
          flex={false}
          title="En attente de validation"
        />
        <SimulationList
          setFilters={setFilters}
          filters={{ statut: { $in: ["Converti", "Abandon"] } }}
          pageSize={5}
          flex={false}
          title="Archivées"
        />
      </>
    );

  if (!archive)
    return (
      <>
        {!isManager && (
          <p className="font-semibold text-xs mt-6 mb-3 text-main-color">
            Choisissez une simulation pour initier votre demande de prime
            Sorégies
          </p>
        )}
        <SimulationList
          setFilters={setFilters}
          filters={
            isManager ? {} : { statut: { $in: ["Nouveau", "Contacté"] } }
          }
          setSelectedId={!isManager ? setSelectedId : null}
          selectedId={!isManager ? selectedId : null}
          footer={<Footer isManager={isManager} selectedId={selectedId} />}
        />
      </>
    );
};

export default function Simulations({ archive }) {
  const [fetchApi] = useFetchApi();
  const [refreshValue, refresh] = useRefresh();
  const [search, setSearch] = useState("");
  const [selectedId, setSelectedId] = useState();
  const { user, isManager } = useAuth();
  const [confirmConversion, confirmConversionDialog] = useConfirm();
  const navigation = useNavigate();

  const setFilters = useCallback(() => {
    if (search) {
      const searchWords = search.split(/\s+/).filter((str) => str !== "");

      if (isManager) {
        // Recherche par nom et prénom du bénéficiaire
        return {
          $and: searchWords.map((word) => ({
            slug: { $containsi: removeAccents(word) },
          })),
        };
      }

      // Recherche par type d'opération ou adresse du chantier
      return {
        $and: searchWords.map((word) => ({
          $or: [
            { slug: { $containsi: removeAccents(word) } },
            { operation: { $containsi: search } },
            { adresse_chantier: { $containsi: search } },
          ],
        })),
      };
    }
    return {};
  }, [isManager, search]);

  const SimulationContextValue = {
    fetchApi,
    refreshValue,
    refresh,
    selectedId,
    user,
    isManager,
    archive,
    confirmConversionDialog,
    confirmConversion,
    setSelectedId,
    navigation,
    setFilters,
  };

  return (
    <RefreshProvider value={refreshValue}>
      <SimulationsContext.Provider value={SimulationContextValue}>
        <Main
          title={isManager ? "Simulations clients" : "Mes simulations"}
          header={
            <Search
              setSearch={setSearch}
              className="hidden md:flex"
              placeholder={
                isManager
                  ? "Recherche nom du bénéficiaire…"
                  : "Recherche opération ou chantier…"
              }
            />
          }
        >
          <ManagerLinks />

          <SimulationListSelector />

          {confirmConversionDialog}
        </Main>
      </SimulationsContext.Provider>
    </RefreshProvider>
  );
}

Simulations.defaultProps = {
  archive: false,
};

Simulations.propTypes = {
  archive: PropTypes.bool,
};
