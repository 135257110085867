import { React, useState } from "react";
import PropTypes from "prop-types";
import Main from "components/Main";
import { ReactComponent as FilterIcon } from "images/icons/filter.svg";
import { ReactComponent as DepotEmmyIcon } from "images/icons/depotEmmy.svg";
import { ReactComponent as LoadingIcon } from "images/icons/loading.svg";
import Button from "./Button";
import { useAuth } from "providers/AuthProvider";
import useFetchApi from "hooks/useFetchApi";
import useSWR from "swr";
import Loader from "components/Loader";
import axios from "axios";
import { useApiEndpoint } from "ApiEndpointContext";
// import XLSX from 'xlsx';

function Row({ statut, indicateur, color = "green", A, B, C, D, E, F }) {
  const fostClass = "text-right min-w-[132px] px-2 text-sm font-bold";
  const isMontant = indicateur.includes("Montant");
  return (
    <div className="border rounded h-[78px] flex items-center px-4 my-3 w-[1230px]">
      <div className="space-between flex items-center">
        <div className={`text-xs  w-[200px] pr-2`}>
          <span
            className={`${color === "green" ? "bg-green" : "bg-status-warning"} text-white px-2.5 py-1.5 rounded`}
            style={{ fontSize: "11px" }}
          >
            {statut}
          </span>
        </div>
        <div className="text-xs w-[180px]">{indicateur}</div>
        <div className={fostClass}>
          {isMontant
            ? new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(A)
            : A}
        </div>
        <div className={fostClass}>
          {isMontant
            ? new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(B)
            : B}
        </div>
        <div className={fostClass}>
          {isMontant
            ? new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(C)
            : C}
        </div>
        <div className={fostClass}>
          {isMontant
            ? new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(D)
            : D}
        </div>
        <div className={fostClass}>
          {isMontant
            ? new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(E)
            : E}
        </div>
        <div className={fostClass}>
          {isMontant
            ? new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(F)
            : F}
        </div>
      </div>
    </div>
  );
}
Row.propTypes = {
  statut: PropTypes.any.isRequired,
  indicateur: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  A: PropTypes.number.isRequired,
  B: PropTypes.number.isRequired,
  C: PropTypes.number.isRequired,
  D: PropTypes.number.isRequired,
  E: PropTypes.number.isRequired,
  F: PropTypes.number.isRequired,
};

export default function Reporting() {
  const monthList = {
    short: [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ],
    long: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
  };
  const apiEndpoint = useApiEndpoint();
  const currentMonth = monthList.short[new Date().getMonth()];
  const currentYear = new Date().getFullYear();
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const trimmedYear = year ? String(year).slice(-2) : year;
  const [filterMonth, setFilterMonth] = useState(currentMonth);
  const [filterYear, setFilterYear] = useState(currentYear);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetchApi] = useFetchApi();

  const startYear = currentYear - 4;
  const yearList = Array.from(
    { length: new Date().getFullYear() - startYear + 1 },
    (_, index) => startYear + index,
  ).reverse();
  const auth = useAuth();
  const { data } = useSWR(`reportings?date=${month}-${trimmedYear}`, fetchApi);

  const GetMonthIndex = (monthAbbreviation) => {
    const shortList = monthList.short.map((month) => month.toLowerCase());
    const index = shortList.indexOf(monthAbbreviation.toLowerCase());
    return index !== -1 ? (index + 1).toString().padStart(2, "0") : null;
  };

  const ExporterDossier = async () => {
    // console.log('selectedFiles', selectedFiles)
    try {
      setLoading(true);
      const apiUrl = `${apiEndpoint}/api/export-reporting-coup-de-pouce/`;
      const response = await axios.post(apiUrl, null, {
        headers: { Authorization: `Bearer ${auth.token}` },
        responseType: "blob",
      });

      // console.log(response);
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      // console.log("a", a);
      a.download = `SOREGIES - CdP SOREGIES - Bilan CdP Chauffage - ${year}${GetMonthIndex(month)}.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      // console.log("Error:", error);
      setLoading(false);
    } finally {
      setLoading(false);
      // console.log("finally");
    }
  };

  const FilterDateBox = () => {
    return (
      <div className="absolute bg-white w-[445px] h-[385px]  right-10 border rounded-md top-12 mt-4 p-6">
        <button
          className="text-xs absolute right-5 top-3 underline"
          onClick={() => setOpen(false)}
        >
          Fermer
        </button>
        <h1 className="font-semibold text-l text-main-color my-3">
          Sélectionner un mois
        </h1>
        {/* {filterMonth} / {filterYear} */}
        {monthList.short.map((short, index) => (
          <button
            key={short}
            onClick={() => {
              setFilterMonth(short);
            }}
            className={`border mb-2 mr-1 h-[40px] px-2 rounded-md text-xs py-2 ${short === filterMonth ? "text-main-color border-blue" : "text-lightgray"}`}
          >
            {monthList.long[index]}
          </button>
        ))}
        <h1 className="font-semibold text-l text-main-color my-3">
          Sélectionner une année
        </h1>
        {yearList.map((item, index) => (
          <button
            key={item}
            onClick={() => {
              setFilterYear(item);
            }}
            className={`border mb-2 mr-1 h-[40px] px-2 rounded-md text-xs py-2 ${item === filterYear ? "text-main-color border-blue" : "text-lightgray"}`}
          >
            {item}
          </button>
        ))}

        <div className="w-full flex flex-col justify-center my-5">
          <div className="w-full flex justify-center">
            <Button
              label="Valider les filtres"
              color="blue"
              className={"h-[40px] w-fit"}
              onClick={() => {
                setMonth(filterMonth);
                setYear(filterYear);
                setOpen(false);
              }}
            />
          </div>
          <button
            className="underline text-xs my-3"
            onClick={() => {
              setFilterMonth(currentMonth);
              setFilterYear(currentYear);
              setMonth(currentMonth);
              setYear(currentYear);
              setOpen(false);
            }}
          >
            Réinitialiser les filtres
          </button>
        </div>
      </div>
    );
  };

  // console.log(yearList)
  const filterNum =
    year !== "" && month !== "" ? 2 : year !== "" || month !== "" ? 1 : 0;
  // console.log('data..', data)
  if (!data) {
    return <Loader isLoading={true} error={false} />;
  } else {
    const fostClass = "text-right min-w-[132px] px-2";
    const rowMapper = [
      {
        status: "Offres proposées",
        color: "green",
        indicateur: "Nombre d’offres proposées",
        A: data?.data.rai_envoye["BAR-TH-104 Coup de pouce"]?.nombre ?? 0,
        B: data?.data.rai_envoye["BAR-TH-171 Coup de pouce"]?.nombre ?? 0,
        C: data?.data.rai_envoye["BAR-TH-172 Coup de pouce"]?.nombre ?? 0,
        D: data?.data.rai_envoye["BAR-TH-113 Coup de pouce"]?.nombre ?? 0,
        E: data?.data.rai_envoye["BAR-TH-143 Coup de pouce"]?.nombre ?? 0,
        F: data?.data.rai_envoye["BAR-TH-159 Coup de pouce"]?.nombre ?? 0,
      },
      {
        status: "Offres proposées",
        color: "green",
        indicateur: "Montant des offres proposées",
        A:
          data?.data.rai_envoye["BAR-TH-104 Coup de pouce"]?.montant_total ?? 0,
        B:
          data?.data.rai_envoye["BAR-TH-171 Coup de pouce"]?.montant_total ?? 0,
        C:
          data?.data.rai_envoye["BAR-TH-172 Coup de pouce"]?.montant_total ?? 0,
        D:
          data?.data.rai_envoye["BAR-TH-113 Coup de pouce"]?.montant_total ?? 0,
        E:
          data?.data.rai_envoye["BAR-TH-143 Coup de pouce"]?.montant_total ?? 0,
        F:
          data?.data.rai_envoye["BAR-TH-159 Coup de pouce"]?.montant_total ?? 0,
      },
      {
        status: "Travaux engagés",
        color: "orange",
        indicateur: "Nombre d’offres en remplacement du charbon",
        A:
          data?.data.travaux_en_cours["BAR-TH-104 Coup de pouce"]
            ?.nombre_charbon ?? 0,
        B:
          data?.data.travaux_en_cours["BAR-TH-171 Coup de pouce"]
            ?.nombre_charbon ?? 0,
        C:
          data?.data.travaux_en_cours["BAR-TH-172 Coup de pouce"]
            ?.nombre_charbon ?? 0,
        D:
          data?.data.travaux_en_cours["BAR-TH-113 Coup de pouce"]
            ?.nombre_charbon ?? 0,
        E:
          data?.data.travaux_en_cours["BAR-TH-143 Coup de pouce"]
            ?.nombre_charbon ?? 0,
        F:
          data?.data.travaux_en_cours["BAR-TH-159 Coup de pouce"]
            ?.nombre_charbon ?? 0,
      },
      {
        status: "Travaux engagés",
        color: "orange",
        indicateur: "Nombre d’offres en remplacement du fioul",
        A:
          data?.data.travaux_en_cours["BAR-TH-104 Coup de pouce"]
            ?.nombre_fioul ?? 0,
        B:
          data?.data.travaux_en_cours["BAR-TH-171 Coup de pouce"]
            ?.nombre_fioul ?? 0,
        C:
          data?.data.travaux_en_cours["BAR-TH-172 Coup de pouce"]
            ?.nombre_fioul ?? 0,
        D:
          data?.data.travaux_en_cours["BAR-TH-113 Coup de pouce"]
            ?.nombre_fioul ?? 0,
        E:
          data?.data.travaux_en_cours["BAR-TH-143 Coup de pouce"]
            ?.nombre_fioul ?? 0,
        F:
          data?.data.travaux_en_cours["BAR-TH-159 Coup de pouce"]
            ?.nombre_fioul ?? 0,
      },
      {
        status: "Travaux engagés",
        color: "orange",
        indicateur: "Nombre d’offres en remplacement du gaz",
        A:
          data?.data.travaux_en_cours["BAR-TH-104 Coup de pouce"]?.nombre_gaz ??
          0,
        B:
          data?.data.travaux_en_cours["BAR-TH-171 Coup de pouce"]?.nombre_gaz ??
          0,
        C:
          data?.data.travaux_en_cours["BAR-TH-172 Coup de pouce"]?.nombre_gaz ??
          0,
        D:
          data?.data.travaux_en_cours["BAR-TH-113 Coup de pouce"]?.nombre_gaz ??
          0,
        E:
          data?.data.travaux_en_cours["BAR-TH-143 Coup de pouce"]?.nombre_gaz ??
          0,
        F:
          data?.data.travaux_en_cours["BAR-TH-159 Coup de pouce"]?.nombre_gaz ??
          0,
      },
      {
        status: "Travaux engagés",
        color: "orange",
        indicateur:
          "Nombre d’offres pour des ménages modestes y compris Grands précaires",
        A:
          data?.data.travaux_en_cours["BAR-TH-104 Coup de pouce"]
            ?.nombre_modeste ?? 0,
        B:
          data?.data.travaux_en_cours["BAR-TH-171 Coup de pouce"]
            ?.nombre_modeste ?? 0,
        C:
          data?.data.travaux_en_cours["BAR-TH-172 Coup de pouce"]
            ?.nombre_modeste ?? 0,
        D:
          data?.data.travaux_en_cours["BAR-TH-113 Coup de pouce"]
            ?.nombre_modeste ?? 0,
        E:
          data?.data.travaux_en_cours["BAR-TH-143 Coup de pouce"]
            ?.nombre_modeste ?? 0,
        F:
          data?.data.travaux_en_cours["BAR-TH-159 Coup de pouce"]
            ?.nombre_modeste ?? 0,
      },
      {
        status: "Travaux engagés",
        color: "orange",
        indicateur: "Nombre d’offres pour les ménages Grands précaires",
        A:
          data?.data.travaux_en_cours["BAR-TH-104 Coup de pouce"]
            ?.nombre_grands_precaires ?? 0,
        B:
          data?.data.travaux_en_cours["BAR-TH-171 Coup de pouce"]
            ?.nombre_grands_precaires ?? 0,
        C:
          data?.data.travaux_en_cours["BAR-TH-172 Coup de pouce"]
            ?.nombre_grands_precaires ?? 0,
        D:
          data?.data.travaux_en_cours["BAR-TH-113 Coup de pouce"]
            ?.nombre_grands_precaires ?? 0,
        E:
          data?.data.travaux_en_cours["BAR-TH-143 Coup de pouce"]
            ?.nombre_grands_precaires ?? 0,
        F:
          data?.data.travaux_en_cours["BAR-TH-159 Coup de pouce"]
            ?.nombre_grands_precaires ?? 0,
      },
      {
        status: "Travaux achevés",
        color: "orange",
        indicateur: "Nombre d’offres en remplacement du charbon",
        A:
          data?.data.documents_reçus["BAR-TH-104 Coup de pouce"]
            ?.nombre_charbon ?? 0,
        B:
          data?.data.documents_reçus["BAR-TH-171 Coup de pouce"]
            ?.nombre_charbon ?? 0,
        C:
          data?.data.documents_reçus["BAR-TH-172 Coup de pouce"]
            ?.nombre_charbon ?? 0,
        D:
          data?.data.documents_reçus["BAR-TH-113 Coup de pouce"]
            ?.nombre_charbon ?? 0,
        E:
          data?.data.documents_reçus["BAR-TH-143 Coup de pouce"]
            ?.nombre_charbon ?? 0,
        F:
          data?.data.documents_reçus["BAR-TH-159 Coup de pouce"]
            ?.nombre_charbon ?? 0,
      },
      {
        status: "Travaux achevés",
        color: "orange",
        indicateur: "Nombre d’offres en remplacement du fioul",
        A:
          data?.data.documents_reçus["BAR-TH-104 Coup de pouce"]
            ?.nombre_fioul ?? 0,
        B:
          data?.data.documents_reçus["BAR-TH-171 Coup de pouce"]
            ?.nombre_fioul ?? 0,
        C:
          data?.data.documents_reçus["BAR-TH-172 Coup de pouce"]
            ?.nombre_fioul ?? 0,
        D:
          data?.data.documents_reçus["BAR-TH-113 Coup de pouce"]
            ?.nombre_fioul ?? 0,
        E:
          data?.data.documents_reçus["BAR-TH-143 Coup de pouce"]
            ?.nombre_fioul ?? 0,
        F:
          data?.data.documents_reçus["BAR-TH-159 Coup de pouce"]
            ?.nombre_fioul ?? 0,
      },
      {
        status: "Travaux achevés",
        color: "orange",
        indicateur: "Nombre d’offres en remplacement du gaz",
        A:
          data?.data.documents_reçus["BAR-TH-104 Coup de pouce"]?.nombre_gaz ??
          0,
        B:
          data?.data.documents_reçus["BAR-TH-171 Coup de pouce"]?.nombre_gaz ??
          0,
        C:
          data?.data.documents_reçus["BAR-TH-172 Coup de pouce"]?.nombre_gaz ??
          0,
        D:
          data?.data.documents_reçus["BAR-TH-113 Coup de pouce"]?.nombre_gaz ??
          0,
        E:
          data?.data.documents_reçus["BAR-TH-143 Coup de pouce"]?.nombre_gaz ??
          0,
        F:
          data?.data.documents_reçus["BAR-TH-159 Coup de pouce"]?.nombre_gaz ??
          0,
      },
      {
        status: "Travaux achevés",
        color: "orange",
        indicateur:
          "Nombre d’offres pour des ménages modestes y compris Grands précaires",
        A:
          data?.data.documents_reçus["BAR-TH-104 Coup de pouce"]
            ?.nombre_modeste ?? 0,
        B:
          data?.data.documents_reçus["BAR-TH-171 Coup de pouce"]
            ?.nombre_modeste ?? 0,
        C:
          data?.data.documents_reçus["BAR-TH-172 Coup de pouce"]
            ?.nombre_modeste ?? 0,
        D:
          data?.data.documents_reçus["BAR-TH-113 Coup de pouce"]
            ?.nombre_modeste ?? 0,
        E:
          data?.data.documents_reçus["BAR-TH-143 Coup de pouce"]
            ?.nombre_modeste ?? 0,
        F:
          data?.data.documents_reçus["BAR-TH-159 Coup de pouce"]
            ?.nombre_modeste ?? 0,
      },
      {
        status: "Travaux achevés",
        color: "orange",
        indicateur: "Nombre d’offres pour les ménages Grands précaires",
        A:
          data?.data.documents_reçus["BAR-TH-104 Coup de pouce"]
            ?.nombre_grands_precaires ?? 0,
        B:
          data?.data.documents_reçus["BAR-TH-171 Coup de pouce"]
            ?.nombre_grands_precaires ?? 0,
        C:
          data?.data.documents_reçus["BAR-TH-172 Coup de pouce"]
            ?.nombre_grands_precaires ?? 0,
        D:
          data?.data.documents_reçus["BAR-TH-113 Coup de pouce"]
            ?.nombre_grands_precaires ?? 0,
        E:
          data?.data.documents_reçus["BAR-TH-143 Coup de pouce"]
            ?.nombre_grands_precaires ?? 0,
        F:
          data?.data.documents_reçus["BAR-TH-159 Coup de pouce"]
            ?.nombre_grands_precaires ?? 0,
      },
      {
        status: "Prime versées",
        color: "green",
        indicateur:
          "Nombre d’incitations financières versées pour des ménages modestes y compris Grands précaires",
        A:
          data?.data.prime_versee["BAR-TH-104 Coup de pouce"]?.nombre_modeste ??
          0,
        B:
          data?.data.prime_versee["BAR-TH-171 Coup de pouce"]?.nombre_modeste ??
          0,
        C:
          data?.data.prime_versee["BAR-TH-172 Coup de pouce"]?.nombre_modeste ??
          0,
        D:
          data?.data.prime_versee["BAR-TH-113 Coup de pouce"]?.nombre_modeste ??
          0,
        E:
          data?.data.prime_versee["BAR-TH-143 Coup de pouce"]?.nombre_modeste ??
          0,
        F:
          data?.data.prime_versee["BAR-TH-159 Coup de pouce"]?.nombre_modeste ??
          0,
      },
      {
        status: "Prime versées",
        color: "green",
        indicateur:
          "Nombre d'incitations financières versées pour les ménages Grands précaires",
        A:
          data?.data.prime_versee["BAR-TH-104 Coup de pouce"]
            ?.nombre_grands_precaires ?? 0,
        B:
          data?.data.prime_versee["BAR-TH-171 Coup de pouce"]
            ?.nombre_grands_precaires ?? 0,
        C:
          data?.data.prime_versee["BAR-TH-172 Coup de pouce"]
            ?.nombre_grands_precaires ?? 0,
        D:
          data?.data.prime_versee["BAR-TH-113 Coup de pouce"]
            ?.nombre_grands_precaires ?? 0,
        E:
          data?.data.prime_versee["BAR-TH-143 Coup de pouce"]
            ?.nombre_grands_precaires ?? 0,
        F:
          data?.data.prime_versee["BAR-TH-159 Coup de pouce"]
            ?.nombre_grands_precaires ?? 0,
      },
      {
        status: "Prime versées",
        color: "green",
        indicateur: "Montant total des incitations financières versées",
        A:
          data?.data.prime_versee["BAR-TH-104 Coup de pouce"]?.montant_total ??
          0,
        B:
          data?.data.prime_versee["BAR-TH-171 Coup de pouce"]?.montant_total ??
          0,
        C:
          data?.data.prime_versee["BAR-TH-172 Coup de pouce"]?.montant_total ??
          0,
        D:
          data?.data.prime_versee["BAR-TH-113 Coup de pouce"]?.montant_total ??
          0,
        E:
          data?.data.prime_versee["BAR-TH-143 Coup de pouce"]?.montant_total ??
          0,
        F:
          data?.data.prime_versee["BAR-TH-159 Coup de pouce"]?.montant_total ??
          0,
      },
    ];
    return (
      <Main className="fadeIn flex-1">
        <div>
          <div className="flex justify-between relative">
            <h1 className="font-semibold text-base md:text-2xl text-main-color mb-9">
              Reporting coup de pouce
            </h1>
            {/* Month: {month} / Year: {year} */}
            <Button
              label={filterNum === 0 ? "Filtres" : `Filtres (${filterNum})`}
              icon={<FilterIcon />}
              variant="noborder"
              color="lightblue"
              className={"h-[50px] mr-10"}
              onClick={() => setOpen(!open)}
            />
            {open && <FilterDateBox />}
          </div>

          <div className="flex w-[1200px] space-between">
            <div className="text-sm font-semibold w-[200px] pr-2 ml-1">
              Statut
            </div>
            <div className="text-xs w-[200px]">Indicateur</div>
            <div className={fostClass}>
              <h1 className="text-xs">BAR-TH-104</h1>
              <p className="text-xxs text-slate-500">
                Pompe à chaleur de <br /> type air/eau ou eau/eau
              </p>
            </div>
            <div className={fostClass}>
              <h1 className="text-xs">BAR-TH-171</h1>
              <p className="text-xxs text-slate-500">
                Pompe à chaleur de <br />
                type air/eau
              </p>
            </div>
            <div className={fostClass}>
              <h1 className="text-xs">BAR-TH-172</h1>
              <p className="text-xxs text-slate-500">
                Pompe à chaleur <br /> eau/eau
              </p>
            </div>
            <div className={fostClass}>
              <h1 className="text-xs">BAR-TH-113</h1>
              <p className="text-xxs text-slate-500">Chaudière biomasse</p>
            </div>
            <div className={fostClass}>
              <h1 className="text-xs">BAR-TH-143</h1>
              <p className="text-xxs text-slate-500">Système solaire combiné</p>
            </div>
            <div className={fostClass}>
              <h1 className="text-xs"> BAR-TH-159</h1>
              <p className="text-xxs text-slate-500">Pompe à chaleur hybride</p>
            </div>
          </div>

          {rowMapper.map((rowData, index) => (
            <Row
              key={index}
              statut={rowData.status}
              color={rowData.color}
              indicateur={rowData.indicateur}
              A={rowData.A} // Assuming you want to add 1 to the original value
              B={rowData.B} // Adjust this logic based on your requirements
              C={rowData.C}
              D={rowData.D}
              E={rowData.E}
              F={rowData.F}
            />
          ))}
        </div>

        <div className="mt-4">
          <Button
            label="Exporter le tableau"
            icon={loading ? <LoadingIcon /> : <DepotEmmyIcon />}
            variant="contained"
            onClick={ExporterDossier}
          />
        </div>
      </Main>
    );
  }
}
