import React, { useState, useMemo, useCallback } from "react";
import Dialog from "components/Dialog";
import Button from "components/Button";
import { useAuth } from "providers/AuthProvider";
import DataTable, {
  useDataTable,
  DataTableProvider,
} from "components/DataTable";
import Search from "components/Search";
import { getStatusBackground } from "functions";
import { useApiEndpoint } from "ApiEndpointContext";
import axios from "axios";
// import Loader from "components/Loader";
import CreateUserDialog from "./CreateUserDialog";

const FILES_PER_PAGE = 8;

function transformApiResponse(res) {
  // We transform the API response to a format that is easier to use and
  // has a lower memory footprint than the full API response.
  return {
    ...res,
    data: res.data.map((item) => {
      return {
        id: item.id,
        nom: `${item.firstname} ${item.name}`,
        telephone: item.telephone,
        email: item.email,
        profil: item?.role?.name,
        status: item.blocked ? "Inactif" : "Actif",
      };
    }),
  };
}

export default function Utilisateurs() {
  const [search, setSearch] = useState("");
  const auth = useAuth();
  const [dataTableProviderValue, refresh] = useDataTable();

  const columns = useMemo(
    () => [
      {
        label: "Nom",
        key: "nom",
        sortFields: ["name", "firstname", "id"],
        defaultDirection: "asc",
        className: "text-black",
      },
      {
        label: "Téléphone",
        key: "telephone",
      },
      {
        label: "E-mail",
        key: "email",
        format: (email) => <a href={`mailto:${email}`}>{email}</a>,
      },
      {
        label: "Profil",
        key: "profil",
        selectOptionLabel: "Sélectionner une ou plusieurs profils",
        filterField: "role.name",
      },
      {
        label: "Statut",
        key: "status",
        selectOptionLabel: "Sélectionner une ou plusieurs statuts",
        filterField: "blocked",
        formatOption: (option) => (option ? "Actif" : "Inactif"),
        format: (status, user) => (
          <>
            {["Actif", "Inactif"].map((statusValue) => (
              <button
                key={statusValue}
                className={`px-2.5 py-1.5 rounded text-white ${status === statusValue ? getStatusBackground(status) : "bg-status-disabled"}`}
                disabled={status === statusValue}
                id={user.id}
                onClick={() => {
                  setCurrentItem(user);
                  setCurrentID(user.id);
                  setCurrentStatus(statusValue !== "Actif");
                  setOpenDialog(true);
                }}
              >
                {statusValue}
              </button>
            ))}
          </>
        ),
      },
      {
        key: "actions",
        format: (_, { id }) => (
          <a
            className="underline text-black"
            href={`/parametres/utilisateurs/${id}`}
          >
            Voir détails
          </a>
        ),
      },
    ],
    [],
  );

  // Recherche par nom et prénom de l'utilisateur
  const setFilters = useCallback(() => {
    if (search) {
      const cleanSearch = search.trim();
      const searchWords = cleanSearch.split(/\s+/).filter((str) => str !== "");
      return {
        $and: searchWords.map((word) => ({
          $or: [
            { name: { $containsi: word } },
            { firstname: { $containsi: word } },
          ],
        })),
      };
    }
    return {};
  }, [search]);

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogUser, setOpenDialogUser] = useState(false);
  const [dialogError, setDialogError] = useState("");
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [currentID, setCurrentID] = useState(1);
  const [currentStatus, setCurrentStatus] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const apiEndpoint = useApiEndpoint();

  const UserActiver = async () => {
    setDialogLoading(true);
    const body = {
      blocked: currentStatus,
    };
    try {
      const apiUrl = `${apiEndpoint}/api/users/${currentID}`;
      await axios.put(apiUrl, body, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth.token}`,
        },
      });
      setDialogSuccess(true);
      refresh();
    } catch (error) {
      setDialogError(error);
    } finally {
      setDialogLoading(false);
    }
  };

  const CloseDialog = () => {
    setOpenDialog(false);
    setDialogSuccess(false);
    setDialogLoading(false);
    setDialogError("");
  };

  return (
    <DataTableProvider value={dataTableProviderValue}>
      <div className="bg-white flex-1 flex flex-col overflow-y-hidden">
        <div className="flex flex-row my-4 px-2">
          {openDialog && (
            <Dialog onClose={CloseDialog} loading={dialogLoading}>
              {!dialogError && !dialogLoading && !dialogSuccess && (
                <div>
                  <h2 className="my-5 text-xl font-semibold text-main-color text-center">
                    Êtes-vous sur de vouloir{" "}
                    {currentStatus ? "désactiver" : "activer"} le compte{" "}
                    {currentItem.email}
                  </h2>
                  <div className="flex my-2 justify-center">
                    <Button
                      label="Oui, je confirme"
                      className="mr-4"
                      onClick={UserActiver}
                    ></Button>
                    <Button
                      label="Non, j’annule"
                      color="disabled"
                      onClick={CloseDialog}
                    ></Button>
                  </div>
                </div>
              )}

              {dialogError && !dialogLoading && (
                <div className="min-h-[150px] flex items-center justify-center">
                  <div className="text-red-500">{dialogError}</div>
                </div>
              )}

              {dialogSuccess && !dialogLoading && !dialogError && (
                <div className="min-h-[150px] flex items-center justify-center">
                  <div className="my-5 text-xl font-semibold text-main-color text-center">
                    Le compte de {currentItem.email} a bien été{" "}
                    {currentStatus ? "désactivé" : "activé"}
                  </div>
                </div>
              )}
            </Dialog>
          )}

          {openDialogUser ? (
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-90 flex items-center justify-center z-50">
              <CreateUserDialog
                onClose={() => setOpenDialogUser(false)}
                refresh={refresh}
              />
            </div>
          ) : null}

          <h1 className="font-semibold text-lg mt-2">Tous les utilisateurs</h1>
          <div className="flex-1"></div>
          <div className="flex items-center max-w-[340px] w-full">
            <Search setSearch={setSearch} placeholder="Recherche…" />
          </div>
          <div>
            <Button
              label="Créer un utilisateur"
              className="ml-3"
              onClick={() => {
                setOpenDialogUser(true);
              }}
            />
          </div>
        </div>

        <DataTable
          id="utilisateurs"
          entity="users"
          endpointUrl="users"
          formatData={transformApiResponse}
          columns={columns}
          defaultActiveSort="nom"
          setFilters={setFilters}
          pageSize={FILES_PER_PAGE}
          flex
          noResultMessage="Cet utilisateur n'existe pas."
          extraParams={{ populate: "role" }}
        />
      </div>
    </DataTableProvider>
  );
}
