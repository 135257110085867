import React from "react";
import { ReactComponent as PlusIcon } from "images/icons/plus.svg";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

export default function ButtonSimulation(props) {
  const navigate = useNavigate();
  const { isMobile } = props;
  return (
    <Button
      {...props}
      label={!isMobile && "Faire une nouvelle simulation"}
      icon={<PlusIcon />}
      variant="contained"
      color="orange"
      onClick={() => {
        if (location.pathname.includes("mes-simulations/simulateur")) {
          window.location.reload();
        } else {
          navigate("mes-simulations/simulateur");
        }
      }}
      className={`text-sm md:flex absolute md:relative z-50 right-4 md:right-auto bottom-20 md:bottom-auto ${!isMobile && "hidden"}`}
    />
  );
}

ButtonSimulation.propTypes = {
  isMobile: PropTypes.bool,
  plusIcon: PropTypes.bool,
};
