import React, { useState, useEffect } from "react";
import Button from "./components/Button";
import { ReactComponent as RetryIcon } from "./images/icons/retry.svg";
import PropTypes from "prop-types";

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState("");

  const handleRefresh = () => {
    window.location.reload();
  };

  useEffect(() => {
    const errorHandler = (error) => {
      console.error("Error caught by error boundary:", error);
      setError(error.message);
      setHasError(true);
    };

    // Set up error handler for unhandled errors
    window.addEventListener("error", errorHandler);

    return () => {
      // Clean up the error handler
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  if (hasError) {
    // Fallback UI when there's an error
    return (
      <div className="w-full justify-center items-center flex flex-col h-full">
        <h2 className="font-semibold text-2xl text-red-500">
          Une erreur est survenue
        </h2>
        <p className="mt-3">Merci de bien vouloir réessayer plus tard.</p>
        <p>{error}</p>

        <Button
          label="Refresh Page"
          icon={<RetryIcon />}
          variant="contained"
          color="blue"
          onClick={handleRefresh}
          className="text-sm mt-4"
        />
      </div>
    );
  } else {
    return children;
  }
};

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
