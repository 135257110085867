import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  statutsDossierGestionnaire,
  statutsDossierClient,
  statutsDossierGestionnaireToClient,
} from "constants/statutsDossier";
import useFetchApi from "hooks/useFetchApi";
import { useAuth } from "providers/AuthProvider";
import StatusTimeline from "components/Status/StatusTimeline";

const mapStatus = ({ name, wider }, index) => ({
  id: index + 1,
  label: name,
  wider,
});

const statusListManager = statutsDossierGestionnaire.map(mapStatus);
const statusListClient = statutsDossierClient.map(mapStatus);

export default function Status({ status: currentStatus, id, data, fetchData }) {
  const [fetchApi] = useFetchApi();
  const [error, setError] = useState("");
  const { isManager } = useAuth();

  const statusList = isManager
    ? statusListManager
    : statusListClient.filter(
        (status) => status.label !== "Abandon" || currentStatus === "Abandon",
      );

  const status = isManager
    ? currentStatus
    : statutsDossierGestionnaireToClient[currentStatus] ?? currentStatus;

  const updateStatus = async (id, newStatus) => {
    await fetchApi(`update-dossier/${id}`, {
      method: "PUT",
      body: { statut: newStatus },
    });
    fetchData();
  };

  function setDefaultError() {
    const checkedInput = {
      "Prime CEE": data?.data?.attributes?.Prime_CEE,
      IBAN: data?.data?.attributes?.beneficiaire?.data?.attributes?.IBAN,
      BIC: data?.data?.attributes?.beneficiaire?.data?.attributes?.BIC,
    };

    const anyNull = Object.values(checkedInput).some((value) => value === null);
    const listOfNull = Object.keys(checkedInput).filter(
      (key) => checkedInput[key] === null,
    );
    if (anyNull && data?.data?.attributes?.statut === "Dossier validé") {
      setError(
        `Veuillez renseigner les champs ${listOfNull.join(", ")} afin de valider le dossier.`,
      );
    }
  }

  useEffect(() => {
    setDefaultError();
  });

  return (
    <StatusTimeline
      id={id}
      statusList={statusList}
      status={status}
      updateStatus={updateStatus}
      error={error}
      setError={setError}
    />
  );
}
Status.propTypes = {
  status: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  fetchData: PropTypes.func.isRequired,
};
