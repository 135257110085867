import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useFetchApi from "hooks/useFetchApi";
import { mutate } from "swr";
import AuthForm from "./AuthForm";
import AuthMessage from "./AuthMessage";
import Input from "components/Input";
import { ReactComponent as SuccessIcon } from "images/icons/success.svg";
import { z } from "zod";
import { useForm } from "react-hook-form";
import formErrors from "constants/formErrors";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormControl, FormField, FormItem, FormMessage } from "components/Form";
import {
  formatPhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

const formSchema = z
  .object({
    title: z.enum(["M.", "Mme"]),
    name: z.string({ message: formErrors.requiredErrorMessage }),
    firstname: z.string({ message: formErrors.requiredErrorMessage }),
    email: z
      .string({ message: formErrors.requiredErrorMessage })
      .email({ message: formErrors.emailErrorMessage }),
    telephone: z
      .string()
      .refine(isValidPhoneNumber, { message: formErrors.phoneErrorMessage }),
    password: z
      .string({ message: formErrors.requiredErrorMessage })
      .min(8, { message: formErrors.passwordMinLengthErrorMessage })
      .refine((password) => /[A-Z]/.test(password), {
        message: formErrors.passwordUppercaseErrorMessage,
      })
      .refine((password) => /[a-z]/.test(password), {
        message: formErrors.passwordLowercaseErrorMessage,
      })
      .refine((password) => /[0-9]/.test(password), {
        message: formErrors.passwordNumberErrorMessage,
      })
      .refine((password) => /[!@#$%^&*]/.test(password), {
        message: formErrors.passwordSpecialCharacterErrorMessage,
      }),
    passwordConfirmation: z.string(),
  })
  .superRefine(({ passwordConfirmation, password }, ctx) => {
    if (passwordConfirmation !== password) {
      ctx.addIssue({
        code: "custom",
        message: formErrors.passwordMatchErrorMessage,
        path: ["passwordConfirmation"],
      });
    }
  });

export default function CreateAccount() {
  const [fetchApi] = useFetchApi();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultValues] = useState({
    title: searchParams.get("title") ?? "M.",
    name: searchParams.get("name") ?? "",
    firstname: searchParams.get("firstname") ?? "",
    email: searchParams.get("email") ?? "",
    telephone: searchParams.get("telephone") ?? "",
    simulationId: searchParams.get("simulationId"),
    simulationCode: searchParams.get("simulationCode"),
    password: "",
    passwordConfirmation: "",
  });
  const placeholders = {
    telephone: "00 00 00 00 00",
    password:
      "8 caractères ou plus, dont 1 majuscule, 1 chiffre et un caractère spécial",
  };

  useEffect(() => {
    setSearchParams({});
  }, [setSearchParams]);

  const form = useForm({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
    },
  });

  const submit = async (data) => {
    try {
      setLoading(true);
      await fetchApi(`auth/local/register`, {
        body: {
          ...data,
          telephone: formatPhoneNumber(data.telephone).replace(/ /g, ""), // format the phone number
          username: data?.email,
          simulationId: defaultValues.simulationId,
          simulationCode: defaultValues.simulationCode,
        },
      });
      // Trigger a revalidation (refetch).
      mutate("auth/local");
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setLoading(false);
      form.setValue("password", "");
      form.setValue("passwordConfirmation", "");
      form.setError("passwordConfirmation", {
        type: "manual",
        message: error.message,
      });
    }
  };

  if (success) {
    return (
      <AuthMessage
        title={
          <>
            <SuccessIcon className="mr-2 inline-block align-middle h-5" />
            Votre compte a bien été créé
          </>
        }
        message={
          <>
            Un lien d&apos;activation vient de vous être envoyé par e-mail.
            <br />
            Pour finaliser l&apos;accès à votre compte, consultez votre boite de
            réception.
            <br />
            <br />
            Vous n&apos;avez pas reçu cet e-mail&nbsp;?
            <br />
            Vérifiez vos courriers indésirables.
          </>
        }
      />
    );
  }

  const inputList = [
    {
      label: "Civilité",
      name: "title",
      type: "select",
      options: [
        { value: "M.", label: "M." },
        { value: "Mme", label: "Mme" },
      ],
      autoComplete: "honorific-prefix",
      required: true,
    },
    {
      label: "Nom",
      name: "name",
      type: "text",
      autoComplete: "family-name",
      required: true,
    },
    {
      label: "Prénom",
      name: "firstname",
      type: "text",
      autoComplete: "given-name",
      required: true,
    },
    {
      label: "E-mail",
      name: "email",
      type: "email",
      autoComplete: "email",
      required: true,
    },
    {
      label: "Numéro de téléphone",
      name: "telephone",
      type: "phone",
      autoComplete: "tel",
      required: true,
    },
    {
      label: "Mot de passe",
      name: "password",
      type: "password",
      autoComplete: "new-password",
      required: true,
    },
    {
      label: "Confirmer mot de passe",
      name: "passwordConfirmation",
      type: "password",
      autoComplete: "new-password",
      required: true,
    },
  ];

  return (
    <AuthForm
      submit={form.handleSubmit(submit)}
      title="Créer mon compte"
      submitText="Créer mon compte"
      cancelUrl="/"
      cancelText="J'ai déjà un compte, me connecter."
      loading={loading}
      success={success}
      {...form}
    >
      {inputList.map((input, index) => (
        <FormField
          key={index}
          name={input.name}
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  label={input.label}
                  type={input.type}
                  placeholder={placeholders[input.name] ?? input.label}
                  options={input.options}
                  autoComplete={input.autoComplete}
                  disabled={form.formState.isSubmitting}
                  required={input.required}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      ))}
    </AuthForm>
  );
}
