import React, { useState } from "react";
import Menu from "components/Menu";
import { ReactComponent as PlusIcon } from "images/icons/plus.svg";
import { ReactComponent as Logo } from "images/logo_simple.svg";
import { Outlet, useLocation } from "react-router-dom";
import Button from "components/Button";
import NewFileDialog from "components/NewFileDialog";
import { useAuth } from "providers/AuthProvider";
import NotificationButton from "components/NotificationButton";
import ConditionChecker from "./helpers/ConditionChecker";
import { MenuProvider } from "hooks/useMenuFooter";
import ButtonSimulation from "./ButtonSimulation";

export default function App() {
  const [showNewFileDialog, setShowNewFileDialog] = useState(false);
  const { role, isCustomer, isManager, user } = useAuth();
  const location = useLocation();

  const isMesSimulations = location.pathname.includes("mes-simulations");

  return (
    <div className="h-[calc(100vh-4rem)] md:h-full flex flex-row items-center justify-center">
      <MenuProvider>
        <Menu className="md:w-[14rem] md:h-full h-16 absolute md:relative w-full bottom-0 " />
        <div className="flex-1 h-full md:bg-stone-100 overflow-y-auto flex flex-col">
          <div className="h-full md:p-4 flex flex-col flex-1 gap-4 relative">
            <div className="flex justify-between top-0 mb:relative md:bg-transparent bg-white items-center md:justify-end p-5 md:p-0">
              <div className="flex items-center justify-center gap-2">
                <Logo className="w-8 h-8 md:hidden" />
                <span className="text-Bleu-fonc-Soregies text-center font-poppins text-xs font-semibold leading-5 tracking-tighter md:hidden">
                  Espace bénéficiare
                </span>
              </div>
              <ConditionChecker
                condition={
                  isManager && role !== "Consultation" && !isMesSimulations
                }
              >
                <Button
                  label="Créer un dossier"
                  icon={<PlusIcon />}
                  variant="contained"
                  color="orange"
                  onClick={() => setShowNewFileDialog(true)}
                  className="text-sm"
                />
              </ConditionChecker>
              <ConditionChecker condition={isCustomer}>
                <ButtonSimulation />
              </ConditionChecker>
              <NotificationButton userID={user.id} />
            </div>
            <Outlet context={role} />
            <ConditionChecker condition={showNewFileDialog}>
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-90 flex items-center justify-center">
                <NewFileDialog onClose={() => setShowNewFileDialog(false)} />
              </div>
            </ConditionChecker>
          </div>
        </div>
      </MenuProvider>
    </div>
  );
}
