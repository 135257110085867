import React from "react";
import PropTypes from "prop-types";
import { useApiEndpoint } from "ApiEndpointContext";
import axios from "axios"; // Import axios
import { useAuth } from "providers/AuthProvider";
import DropDown from "components/Dropdown";

export default function DocumentPropDropdown({
  property,
  label,
  options,
  value,
  id,
  fetchFilesData,
}) {
  const auth = useAuth();
  const apiEndpoint = useApiEndpoint();
  const handleOptionSelect = async (option) => {
    // Call the API using Axios to update the document
    try {
      const apiUrl = `${apiEndpoint}/api/document/modify-document/${id}`;
      const response = await axios.put(
        apiUrl,
        { [property]: option },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        },
      );

      if (response.status !== 200) {
        throw new Error("Failed to update the document");
      }

      fetchFilesData();
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };
  return (
    <DropDown
      options={options}
      label={label}
      value={value}
      setValue={handleOptionSelect}
    />
  );
}
DocumentPropDropdown.propTypes = {
  property: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(Object).isRequired,
  value: PropTypes.string,
  id: PropTypes.number.isRequired,
  fetchFilesData: PropTypes.func.isRequired,
};
