import { createContext, useContext } from "react";

export const dataContext = createContext();

export function useData() {
  const context = useContext(dataContext);
  if (context === undefined) {
    throw new Error("useData must be used within a DataProvider");
  }

  return context;
}
