import React, { useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { translateMessage, formatFileSize, formatDate } from "functions";
import { ReactComponent as LoadingIcon } from "images/icons/loading.svg";
import { ReactComponent as CheckIcon } from "images/icons/check.svg";
import { ReactComponent as UploadIcon } from "images/icons/upload.svg";
import { ReactComponent as CheckDownloadIcon } from "images/icons/check-download.svg";
import Button from "components/Button";
import axios from "axios";
import { useAuth } from "providers/AuthProvider";
import { useApiEndpoint } from "ApiEndpointContext";
import {
  RefreshContext,
  useRefresh,
  useEffectRefresh,
} from "providers/RefreshProvider";
import {
  typesDocumentsClient,
  typesDocumentsClientsStatuts,
  typesDocumentsClientToGestionnaire,
} from "constants/typesDocuments";
import { statutsDossierGestionnaireToClient } from "constants/statutsDossier";
import InfoTooltip from "components/InfoTooltip";
import StatusTag from "components/Status/StatusTag";
import { ReactComponent as IconDelete } from "images/icons/delete.svg";
import { NON_CONFORME } from "constants/statutsDocuments";
import ConditionChecker from "components/helpers/ConditionChecker";
import { useMenu } from "hooks/useMenuFooter";
import TruncatedFileName from "components/helpers/TruncatedFileName";

function validateFileSize(file) {
  if (file.size > 25 * 1024 * 1024) {
    return {
      code: "file-too-large",
      message: `Le fichier fait plus de 25 Mo.`,
    };
  }
  return null;
}

function FileRow({ type, setFilesForType, loading, success, uploadFiles }) {
  const { Icon, label, invalid } = type;
  const { isMobile } = useMenu();

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFilesForType(label, acceptedFiles);
    },
    [setFilesForType, label],
  );

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "application/pdf": [".pdf"],
    },
    validator: validateFileSize,
  });

  const handleDelete = useCallback(() => {
    while (acceptedFiles.pop()) {
      // Il faut que ça pop pop pop !
    }
    setFilesForType(label, []);
  }, [acceptedFiles, setFilesForType, label]);

  useEffectRefresh(handleDelete);

  const errors = [];
  fileRejections.forEach((rejectedFile) => {
    rejectedFile?.errors?.forEach((error) => {
      switch (error.code) {
        case "file-invalid-type":
          errors.push("Le fichier doit être au format PDF.");
          break;
        default:
          errors.push(error.message);
          break;
      }
    });
  });

  const hasFiles = acceptedFiles.length > 0;

  return (
    <tr
      {...(!hasFiles && !loading && !success && getRootProps())}
      className={`border  border-x-0 border-t-0  border-b-1 dropzone w-full cursor-pointer items-center ${
        isDragActive ? "bg-light-color" : ""
      } ${!hasFiles ? "cursor-pointer" : ""} ${success ? "text-green border-green " : "text-main-color border-gray"}`}
    >
      <ConditionChecker condition={!(isMobile && (success || hasFiles))}>
        <td className={`p-4 md:w--80  ${loading ? "opacity-50" : ""}`}>
          <input {...getInputProps()} />
          <div
            className={`border rounded-md bg-white px-4 py-2 text-xs md:text-sm min-w-56  ${success ? " text-green" : "border-blue text-main-color"}`}
          >
            <Icon className="inline-block vertical-align h-4 -mt-1 mr-2" />
            {label}
          </div>
        </td>
      </ConditionChecker>

      <ConditionChecker condition={isMobile && hasFiles}>
        <td
          className={`p-5  flex flex-row gap-2 ${loading ? "opacity-50" : ""}`}
        >
          <TruncatedFileName filename={acceptedFiles[0]?.name || "Sans nom"} />
          <span className="text-Gris-500-Soregies">
            {formatDate(acceptedFiles[0]?.lastModified)}
          </span>
          <span className="text-Gris-500-Soregies">
            {formatFileSize(acceptedFiles[0]?.size)}
          </span>
        </td>
      </ConditionChecker>

      <ConditionChecker condition={success}>
        <td colSpan="4" className={`p-5  ${loading ? "opacity-50" : ""}`}>
          <p className="text-center">
            <CheckIcon className="inline-block vertical-align  h-4 -mt-1 -mr-1" />{" "}
            Fichier téléchargé avec succès.
          </p>
        </td>
      </ConditionChecker>

      <ConditionChecker condition={!hasFiles && !success}>
        <td
          colSpan="4"
          className={`hidden md:block ${loading ? "opacity-50" : ""}`}
        >
          <div className="flex flex-row items-center">
            <ConditionChecker condition={invalid}>
              <div className={`flex-0 whitespace-nowrap w-32`}>
                <StatusTag
                  label={NON_CONFORME.label}
                  color={NON_CONFORME.color}
                />{" "}
                <InfoTooltip text="Ce fichier est non conforme, veuillez le redéposer." />
              </div>
            </ConditionChecker>
            <p className={`flex-1  ${invalid ? "text-right" : "text-center"}`}>
              Glissez votre fichier ou{" "}
              <span className="font-semibold underline">
                parcourez vos fichiers
              </span>
              .
              <ConditionChecker condition={errors.length > 0}>
                {errors.map((error) => (
                  <>
                    <br />
                    <span
                      key={error}
                      className="font-semibold text-xs md:text-sm text-red-500"
                    >
                      {error}
                    </span>
                  </>
                ))}
              </ConditionChecker>
            </p>
          </div>
        </td>
      </ConditionChecker>

      <ConditionChecker condition={!success && hasFiles}>
        <td className={`hidden md:block${loading ? "opacity-50" : ""}`}>
          <ul>
            {acceptedFiles.map((file) => (
              <li key={file.path}>{file.name}</li>
            ))}
          </ul>
        </td>
        <td className={`hidden md:block${loading ? "opacity-50" : ""}`}>
          {formatFileSize(acceptedFiles[0]?.size)}
        </td>
        <td className={`hidden md:block${loading ? "opacity-50" : ""}`}>
          {formatDate(acceptedFiles[0]?.lastModified)}
        </td>
        <ConditionChecker condition={isMobile}>
          <td className={`${loading ? "opacity-50" : ""}`}>
            <ConditionChecker condition={!loading}>
              <button
                onClick={() => uploadFiles(acceptedFiles[0]?.name ?? null)}
              >
                <CheckDownloadIcon />
              </button>
            </ConditionChecker>
          </td>
        </ConditionChecker>
        <td className={`md:w-[25px] `}>
          <ConditionChecker condition={!loading}>
            <button onClick={handleDelete}>
              <IconDelete />
            </button>
          </ConditionChecker>
          <ConditionChecker condition={loading}>
            <LoadingIcon />
          </ConditionChecker>
        </td>
      </ConditionChecker>

      <ConditionChecker condition={!hasFiles && !success && isMobile}>
        <td>
          <UploadIcon />
        </td>
      </ConditionChecker>
    </tr>
  );
}
FileRow.propTypes = {
  type: PropTypes.instanceOf(Object).isRequired,
  setFilesForType: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  uploadFiles: PropTypes.func.isRequired,
};

export default function MultiFileUploader({
  dossier,
  files: existingFiles,
  fetchFilesData,
}) {
  const apiEndpoint = useApiEndpoint();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [files, setFiles] = useState(new Map());
  const [refreshValue, refresh] = useRefresh();

  const statut =
    statutsDossierGestionnaireToClient[dossier?.attributes?.statut];
  const existingTypes = useMemo(
    () =>
      existingFiles
        .filter(
          (file) =>
            file.attributes.dossier_statut === dossier?.attributes?.statut &&
            file.attributes.statut !== "Non conforme",
        )
        .map(({ attributes: file }) => file.type),
    [existingFiles, dossier?.attributes?.statut],
  );
  const invalidTypes = useMemo(
    () =>
      existingFiles
        .filter((file) => file.attributes.statut === "Non conforme")
        .map(({ attributes: file }) => file.type),
    [existingFiles],
  );
  const fileTypeNames = typesDocumentsClientsStatuts[statut];
  const fileTypes = useMemo(
    () =>
      typesDocumentsClient
        .filter(({ label }) => fileTypeNames?.includes(label))
        .filter(({ isVisible }) => !isVisible || isVisible(dossier))
        .map(({ label, ...props }) => ({
          label,
          ...props,
          success: existingTypes.includes(
            typesDocumentsClientToGestionnaire[label],
          ),
          invalid: invalidTypes.includes(
            typesDocumentsClientToGestionnaire[label],
          ),
        })),
    [dossier, existingTypes, invalidTypes, fileTypeNames],
  );
  const filesToUploadCount = useMemo(
    () => fileTypes.filter(({ success }) => !success).length,
    [fileTypes],
  );

  const setFilesForType = useCallback(
    (label, filesForType) => {
      const type = typesDocumentsClientToGestionnaire[label];
      const newFiles = new Map([...files]);
      if (filesForType.length > 0) {
        newFiles.set(type, filesForType);
      } else {
        newFiles.delete(type);
      }
      setFiles(newFiles);
    },
    [files],
  );

  const uploadFiles = useCallback(
    async (fileName = null) => {
      try {
        setLoading(true);
        const formData = new FormData();
        if (fileName !== null) {
          let fileFound = false;
          for (const [type, [file]] of files.entries()) {
            if (file.name === fileName) {
              formData.append("file1", file);
              formData.append("type1", type);
              fileFound = true;
              break;
            }
          }

          if (!fileFound) {
            throw new Error("Fichier non trouvé");
          }
        } else {
          // Upload de tous les fichiers (comportement original)
          [...files].forEach(([type, [file]], index) => {
            formData.append(`file${index + 1}`, file);
            formData.append(`type${index + 1}`, type);
          });
        }

        const apiUrl = `${apiEndpoint}/api/dossier/upload-documents/${dossier.id}`;
        await axios.post(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`,
          },
        });

        if (fileName !== null) {
          // Supprimer uniquement le fichier uploadé de la Map
          const newFiles = new Map(files);
          newFiles.delete(fileName);
          setFiles(newFiles);
        } else {
          // Vider complètement la Map
          setFiles(new Map());
        }

        refresh();
      } catch (error) {
        setError(translateMessage(error.message));
      } finally {
        await fetchFilesData();
        setLoading(false);
      }
    },
    [refresh, apiEndpoint, files, dossier.id, auth.token, fetchFilesData],
  );

  return fileTypes.length > 0 ? (
    <RefreshContext.Provider value={refreshValue}>
      <div className="w-full mb-12">
        <h1 className="md:text-lg mt-4 mb-3 font-semibold text-xs font-poppins">
          Mes documents à déposer
        </h1>
        <table className="mt-4 border border-x-0 border-t-1 border-b-0 w-full border-gray text-xs md:text-lg font-inter">
          <tbody>
            {fileTypes.map((type) => (
              <FileRow
                uploadFiles={uploadFiles}
                key={type.label}
                loading={loading}
                success={type.success}
                type={type}
                setFilesForType={setFilesForType}
              />
            ))}
          </tbody>
        </table>

        <div className="w-full mt-4 flex flex-row-reverse">
          {filesToUploadCount > 0 && (
            <Button
              className="hidden md:block"
              onClick={() => uploadFiles()}
              disabled={loading || files.size === 0}
              label="Je valide mes fichiers"
            />
          )}
          {error && (
            <div className="p-4 font-semibold text-xs md:text-md text-red-500">
              {error}
            </div>
          )}
        </div>
      </div>
    </RefreshContext.Provider>
  ) : null;
}
MultiFileUploader.propTypes = {
  dossier: PropTypes.instanceOf(Object).isRequired,
  files: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  fetchFilesData: PropTypes.func.isRequired,
};
