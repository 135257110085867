import React, { useState } from "react";
import useFetchApi from "hooks/useFetchApi";
import { mutate } from "swr";
import AuthForm from "./AuthForm";
import AuthMessage from "./AuthMessage";
import Input from "components/Input";
import { ReactComponent as SuccessIcon } from "images/icons/success.svg";
import { z } from "zod";
import { useForm } from "react-hook-form";
import formErrors from "constants/formErrors";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormControl, FormField, FormItem, FormMessage } from "components/Form";
import { translateMessage } from "functions";

const formSchema = z.object({
  email: z
    .string({ message: formErrors.requiredErrorMessage })
    .email({ message: formErrors.emailErrorMessage }),
});

export default function ForgotPassword() {
  const [fetchApi] = useFetchApi();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useForm({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  const submit = async (data) => {
    try {
      setLoading(true);
      await fetchApi("auth/forgot-password", {
        body: data,
      });
      // Trigger a revalidation (refetch).
      mutate("/api/auth/local");
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setLoading(false);
      form.setValue("email", "");
      form.setError("email", {
        type: "manual",
        message: translateMessage(error.message),
      });
    }
  };

  if (success) {
    return (
      <AuthMessage
        title={
          <>
            <SuccessIcon className="mr-2 inline-block align-middle h-5" />
            Réinitialisation du mot de passe
          </>
        }
        message={
          <>
            Nous vous avons envoyé un e-mail pour réinitialiser votre mot de
            passe.
            <br />
            Pour créer votre nouveau mot de passe, il vous suffit de cliquer sur
            le lien contenu dans l&apos;e-mail et d&apos;en saisir un nouveau.
            <br />
            <br />
            Vous n&apos;avez pas reçu cet e-mail&nbsp;?
            <br />
            Vérifiez vos courriers indésirables.
          </>
        }
      />
    );
  }

  return (
    <AuthForm
      submit={form.handleSubmit(submit)}
      title="Mot de passe oublié"
      submitText="Recevoir le lien"
      cancelUrl="/"
      cancelText="Retour à la page de connexion"
      success={success}
      loading={loading}
      {...form}
    >
      <p className="text-sm">
        Veuillez saisir votre email de connexion afin de recevoir le lien de
        réinitialisation de votre mot de passe.
      </p>
      <FormField
        name="email"
        control={form.control}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                className="w-full"
                label="Email de connexion"
                disabled={form.formState.isSubmitting}
                placeholder={"Email de connexion"}
                required
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </AuthForm>
  );
}
