import React, { useState, useEffect } from "react";
import xml2js from "xml2js";

function getFeatureImage(info) {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = info;
  const imageElement = tempElement.querySelector(".hs-featured-image");
  return imageElement ? imageElement.src : null;
}

function getParagraph(info) {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = info;
  const paragraphElement = tempElement.querySelector("p");
  return paragraphElement ? paragraphElement.innerText : null;
}

export default function HomeNews() {
  const [rssData, setRssData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRSSFeed = async () => {
      try {
        const response = await fetch(
          "https://www.hellio.com/actualites/rss.xml",
        );
        const xmlData = await response.text();

        // Convert XML data to JSON using xml2js
        xml2js.parseString(xmlData, (err, result) => {
          if (err) {
            console.error("Error parsing RSS feed:", err);
            setError(err);
          } else {
            setRssData(result);
          }
        });
      } catch (error) {
        console.error("Error fetching RSS feed:", error);
      }
    };
    fetchRSSFeed();
  }, []);

  // console.log(rssData)

  if (!rssData) {
    return <div>{error}</div>;
  } else {
    const articleItems = rssData.rss.channel[0].item.map((item, index) =>
      index < 5 ? (
        <div
          className="pl-4 border-l border-blue relative pb-3"
          key={item.title}
        >
          <div
            className="p-2 bg-main-color rounded-full absolute"
            style={{ left: "-8px" }}
          ></div>
          <div className="w-[207px]">
            <h1 className="text-main-color text-sm font-bold">{item.title} </h1>
            <p className="text-main-color text-xs my-1 max-w-[200px] line-clamp-2">
              {getParagraph(item["content:encoded"])}
            </p>
            <img src={getFeatureImage(item["content:encoded"])} alt="title" />
            <button
              role="link"
              onClick={() => window.open(item.link, "_blank", "noreferrer")}
              className="bg-main-color w-full rounded text-white text-sm py-1 my-2"
            >
              Voir l&apos;article
            </button>
          </div>
        </div>
      ) : (
        <div key={item.title}></div>
      ),
    );

    return (
      <div className="flex flex-col">
        <div className="flex">
          <h1 className="font-semibold text-base md:text-2xl text-main-color">
            Actualités
          </h1>
          <div className="flex-1"></div>
          <div className="flex items-center max-w-[60px] w-full">
            <button
              className="text-sm text-main-color underline"
              role="link"
              onClick={() =>
                window.open(
                  "https://www.hellio.com/actualites",
                  "_blank",
                  "noreferrer",
                )
              }
            >
              Voir tout
            </button>
          </div>
        </div>
        <div className="flex-1 overflow-auto">
          <div className="m-6">{articleItems}</div>
          {/* flex flex-col items-center */}

          {/* {rssData.rss.channel[0].item[0].title}
                <div dangerouslySetInnerHTML={{ __html: `${rssData.rss.channel[0].item[0]["content:encoded"]}` }}></div> */}
        </div>
      </div>
    );
  }
}
