import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Button from "components/Button";
import ValidIcon from "images/icons/valide.svg";
import { useAuth } from "providers/AuthProvider";
import { mutate } from "swr";
import axios from "axios";
import { useApiEndpoint } from "ApiEndpointContext";
import { ReactComponent as LoadingIcon } from "images/icons/loading.svg";

function Champ({ label, value }) {
  return (
    <div className="border-b p-2">
      <p className="text-xs text-[#212121]">{label}</p>
      <p>{value || "Aucun"}</p>
    </div>
  );
}
Champ.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
};

export default function RaiDialog({ onClose, id, fetchFilesData, data }) {
  const apiEndpoint = useApiEndpoint();
  const { handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  //   const [resultID, setResultID] = useState(1);
  const auth = useAuth();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  const onSubmitStep = async (data) => {
    // Store data from the current step
    setFormData({ ...formData, ...data });

    if (step < 3) {
      // Proceed to the next step
      setStep(step + 1);
    } else {
      try {
        setLoading(true);

        const apiResult = await axios.post(
          `${apiEndpoint}/api/generate-rai/${id}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          },
        );

        // Check if both API calls were successful
        if (apiResult.data) {
          // Trigger a revalidation (refetch)
          mutate(
            `/api/documents?filters[dossier][id][$eq]=${id}&pagination[limit]=100`,
          );
          setSuccess(true);
          onClose();
        }
      } catch (error) {
        // console.log('error',error.response.data.data)
        setError(error.response.data.data);
        // console.log("error", error);
      } finally {
        setLoading(false);
        fetchFilesData();
      }
    }
  };

  // console.log("dataaaaaaa", data);
  const heightSteps = {
    1: { div: "h-[1200px]", form: "h-[1050px]" },
    2: { div: "h-[950px]", form: "h-[800px]" },
    3: { div: "h-[1000px]", form: "h-[900px]" },
  };
  return (
    <div className="fixed inset-0 z-50 flex justify-center overflow-scroll">
      <div
        className={`absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-90 flex items-center justify-center ${heightSteps[step].div}`}
      ></div>
      <form
        onSubmit={handleSubmit(onSubmitStep)}
        className={`w-[80%] max-w-[689px] mt-20 p-8 grid- bg-white rounded border relative gap-3  ${heightSteps[step].form}`}
      >
        <button
          type="button"
          onClick={onClose}
          className="text-xs underline absolute right-4 top-4 z-20"
        >
          Fermer
        </button>
        <div className="">
          <h2 className="text-xl font-semibold text-main-color mb-3">
            Est-ce que les informations ci-dessous sont correctes ?
          </h2>
        </div>

        {/* {step} */}

        {step === 2 && (
          <div className="col-span-2 flex flex-col gap-1">
            <div className="bg-green text-white px-4 py-3 rounded mb-2 font-semibold flex justify-between">
              <span>Attestation sur l’honneur</span>
              <img src={ValidIcon} alt="valide" width="15" />
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="col-span-2 flex flex-col gap-1">
            <div className="bg-green text-white px-4 py-3 rounded mb-2 font-semibold flex justify-between">
              <span>Attestation sur l’honneur</span>
              <img src={ValidIcon} alt="valide" width="15" />
            </div>

            <div className="bg-green text-white px-4 py-3 rounded mb-2 font-semibold flex justify-between">
              <span>Cadre de contribution</span>
              <img src={ValidIcon} alt="valide" width="15" />
            </div>
          </div>
        )}

        {step === 1 && (
          <div className="col-span-2 flex flex-col gap-1">
            <div className="bg-main-color text-white px-4 py-3 rounded mb-2 font-semibold flex justify-between">
              <span>Attestation sur l’honneur</span>
            </div>

            <Champ
              label="Date de signature du devis"
              value={
                data.data.attributes.chantiers.data[0].attributes
                  .date_signature_devis
              }
            />
            <Champ
              label="Date d’édition de la facture"
              value={
                data.data.attributes.chantiers.data[0].attributes
                  .date_edition_facture
              }
            />
            <Champ
              label="Nom"
              value={data.data.attributes.beneficiaire?.data?.attributes?.nom}
            />
            <Champ
              label="Prénom"
              value={
                data.data.attributes.beneficiaire?.data?.attributes?.prenom
              }
            />
            <Champ
              label="Adresse du chantier ou Parcelle cadastrale"
              value={data.data.attributes.chantiers.data[0].attributes.adresse}
            />
            <Champ
              label="Code postal du chantier"
              value={
                data.data.attributes.chantiers.data[0].attributes.code_postal
              }
            />
            <Champ
              label="Ville du chantier"
              value={data.data.attributes.chantiers.data[0].attributes.ville}
            />
            <Champ
              label="Bâtiment construit depuis plus de 2 ans"
              value={
                data.data.attributes.chantiers.data[0].attributes
                  .plus_de_deux_ans
                  ? "Oui"
                  : "Non"
              }
            />
            <Champ
              label="Type de logement"
              value={
                data.data.attributes.chantiers.data[0].attributes
                  .type_de_logement
              }
            />
            <Champ
              label="Information sur le professionnel"
              value={
                data.data.attributes.entreprise_de_travaux?.data?.attributes
                  ?.raison_sociale
              }
            />
            <Champ
              label="FOST"
              value={data.data.attributes.fosts.data[0].attributes.numero_fost}
            />
          </div>
        )}
        {step === 2 && (
          <div className="col-span-2 flex flex-col gap-1">
            <div className="bg-main-color text-white px-4 py-3 rounded mb-5 font-semibold">
              Cadre de contribution
            </div>
            <Champ
              label="Montant de la prime"
              value={data.data.attributes.Prime_CEE}
            />
            {/* <Champ label="Type de versement de la prime" value={data.data.attributes.beneficiaire.data.attributes.Type_de_versement_de_la_prime} /> */}
            <Champ
              label="Type d’opération"
              value={data.data.attributes.fosts.data[0].attributes.libelle_fost}
            />
            <Champ
              label="Fiche CEE"
              value={data.data.attributes.fosts.data[0].attributes.numero_fost}
            />
            <Champ
              label="Civilité"
              value={data.data.attributes.beneficiaire.data.attributes.civilite}
            />
            <Champ
              label="Nom"
              value={data.data.attributes.beneficiaire.data.attributes.nom}
            />
            <Champ
              label="Prénom"
              value={data.data.attributes.beneficiaire.data.attributes.prenom}
            />
          </div>
        )}
        {step === 3 && (
          <div className="col-span-2 flex flex-col gap-1">
            <div className="bg-main-color text-white px-4 py-3 rounded mb-5 font-semibold">
              Lettre d’engagement
            </div>
            <Champ
              label="Référence du dossier"
              value={data.data.attributes.reference}
            />
            <Champ
              label="Gestionnaire du dossier"
              value={`${data.data.attributes.gestionnaire.data.attributes.firstname} ${data.data.attributes.gestionnaire.data.attributes.name}`}
            />
            <Champ
              label="Civilité"
              value={
                data.data.attributes.beneficiaire.data?.attributes?.civilite
              }
            />
            <Champ
              label="Nom"
              value={data.data.attributes.beneficiaire.data?.attributes?.nom}
            />
            <Champ
              label="Prénom"
              value={data.data.attributes.beneficiaire.data?.attributes?.prenom}
            />
            <Champ
              label="Adresse du chantier ou Parcelle cadastrale"
              value={data.data.attributes.chantiers.data[0].attributes.adresse}
            />
            <Champ
              label="Code postal du chantier"
              value={
                data.data.attributes.chantiers.data[0].attributes.code_postal
              }
            />
            <Champ
              label="Ville du chantier"
              value={data.data.attributes.chantiers.data[0].attributes.ville}
            />
          </div>
        )}

        <div className="col-span-2 text-center">
          <Button
            label={
              step < 3 ? "Valider les informations" : "Générer les documents"
            }
            className="mx-auto mt-4"
            variant="contained"
            color={step < 3 ? "green" : "blue"}
            type="submit"
            icon={loading ? <LoadingIcon /> : ""}
            disabled={!!error?.length}
          />

          {error &&
            error.map((item) => (
              <p key={item} className="text-red-500 text-xs mx-auto mt-1">
                {item}
              </p>
            ))}

          {success && (
            <p className="text-green text-xs mx-auto mt-3">succès !</p>
          )}
        </div>

        {step === 1 && (
          <div>
            <div className="bg-lightergray text-white px-4 py-3 rounded mt-3 font-semibold">
              Cadre de contribution
            </div>
            <div className="bg-lightergray text-white px-4 py-3 rounded mt-3 font-semibold">
              Lettre d’engagement
            </div>
          </div>
        )}

        {step === 2 && (
          <div>
            <div className="bg-lightergray text-white px-4 py-3 rounded mt-3 font-semibold">
              Lettre d’engagement
            </div>
          </div>
        )}

        {/* {(loading || error || success) && (
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-white rounded flex items-center justify-center p-5">
            {loading && (
              <span className="w-12 h-12 border-4 border-blue border-b-transparent rounded-full animate-spin inline-block box-border"></span>
            )}
            {error && (
              <div className="text-red-500 text-center text-2xl font-semibold">
                Une erreur est survenue lors de la création du dossier !
              </div>
            )}
          </div>
        )} */}
      </form>
    </div>
  );
}
RaiDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  fetchFilesData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};
