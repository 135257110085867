import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PageArrow from "./PageArrow";
import { ReactComponent as ArrowIcon } from "images/icons/arrow.svg";
import { cn } from "components/helpers/utils";

export default function PageSelector({
  currentPage,
  totalPages,
  setCurrentPage,
  className = "",
}) {
  const pagesToShow = 10; // Number of pages to show at a time
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(pagesToShow);

  const canGoBack = startPage > 1;
  const canGoForward = endPage < totalPages;

  useEffect(() => {
    // Calculate the new startPage based on the current page
    const newStartPage = Math.max(
      1,
      Math.floor((currentPage - 1) / pagesToShow) * pagesToShow + 1,
    );
    const newEndPage = Math.max(
      1,
      Math.min(newStartPage + pagesToShow - 1, totalPages),
    );
    setStartPage(newStartPage);
    setEndPage(newEndPage);
  }, [currentPage, totalPages, pagesToShow]);

  if (totalPages <= 1) return null;

  return (
    <div className={cn(`flex items-center pr-4 gap-3`, className)}>
      <PageArrow
        disabled={!canGoBack}
        icon={<ArrowIcon className="-rotate-90 h-2.5" />}
        onClick={() => {
          setCurrentPage?.(startPage - 1);
        }}
      />
      {Array.from({ length: pagesToShow }, (_, i) => startPage + i)
        .filter((page) => page <= totalPages)
        .map((page) => (
          <button
            key={page}
            className={`font-semibold text-sm ${
              currentPage === page ? "text-main-color" : "text-stone-300"
            }`}
            onClick={() => setCurrentPage?.(page)}
          >
            {page}
          </button>
        ))}
      <PageArrow
        disabled={!canGoForward}
        icon={<ArrowIcon className="rotate-90 h-2.5" />}
        onClick={() => {
          setCurrentPage?.(endPage + 1);
        }}
      />
    </div>
  );
}
PageSelector.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  className: PropTypes.string,
};
PageSelector.defaultProps = {
  currentPage: 1,
  totalPages: 0,
};
