import React from "react";
import propTypes from "prop-types";

import { useSwrStrapi } from "helper/helper";
import { dataContext } from "hooks/useData";

const { default: useFetchApi } = require("hooks/useFetchApi");
const { useState, useEffect } = require("react");

export default function DataProvider({ children }) {
  const [queryParams, setQueryParams] = useState(null);
  const [formatData, setFormatData] = useState(null);
  const [endpointUrl, setEndpointUrl] = useState("");

  const [fetchApi] = useFetchApi();

  const { data, meta, error, isLoading, mutateData } = useSwrStrapi(
    `${endpointUrl}?${queryParams}`,
    async (url) => {
      const res = await fetchApi(url);
      return {
        ...res,
        ...(formatData && formatData(res)),
      };
    },
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (queryParams && endpointUrl) {
      mutateData();
    }
  }, [queryParams, formatData, endpointUrl, mutateData]);

  const contextValue = {
    data,
    meta,
    error,
    isLoading,
    setQueryParams,
    setFormatData,
    setEndpointUrl,
    mutateData,
  };

  DataProvider.propTypes = {
    children: propTypes.node.isRequired,
  };

  return (
    <dataContext.Provider value={contextValue}>{children}</dataContext.Provider>
  );
}
