import React from "react";
import SimulationList from "./Simulation/SimulationList";
import { Link } from "react-router-dom";

export default function LastViewSimulations() {
  return (
    <div className="w-full pr-3 mb-20">
      <div className="flex flex-row md:mb-5 mb-4">
        <h3 className="font-semibold text-base md:text-2xl text-main-color">
          Mes dernières simulations
        </h3>
        <div className="flex-1"></div>
        <div className="flex items-center max-w-[60px] w-full">
          <Link to="/mes-simulations">
            <button className="text-sm text-main-color underline">
              Voir tout
            </button>
          </Link>
        </div>
      </div>

      <SimulationList
        headers
        noFilter
        noSort
        pagination={false}
        pageSize={5}
        flex={false}
      />
    </div>
  );
}
