import PropTypes from "prop-types";

function ConditionChecker({ condition, children }) {
  if (!condition) return null;
  return children;
}

export default ConditionChecker;

ConditionChecker.propTypes = {
  condition: PropTypes.bool,
  children: PropTypes.any,
};
