import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as ArrowUp } from "images/icons/selection-arrowUp.svg";
import { ReactComponent as ArrowDown } from "images/icons/selection-arrowDown.svg";

export default function MultiSelectDropdown({
  options,
  selectedValues,
  onChange,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      onChange(options.map((option) => option.label));
    } else {
      onChange([]);
    }
  };

  const handleOptionSelect = (optionLabel) => {
    const updatedValues = selectedValues.includes(optionLabel)
      ? selectedValues.filter((value) => value !== optionLabel)
      : [...selectedValues, optionLabel];

    onChange(updatedValues);
  };

  return (
    <div
      className={`dropdown hidden md:block cursor-pointer relative ${
        isDropdownOpen ? "open" : ""
      }`}
    >
      <div
        className={`flex items-center bg-white rounded-md md:w-[350px] border-0.5 px-3 py-1 mb-2 ${
          isDropdownOpen ? "border-stone-400" : "text-main-color border-blue"
        }`}
        onClick={toggleDropdown}
      >
        <div className={`flex flex-1 items-center`}>
          <span className={"inline-block mr-3 text-xs py-2"}>
            Sélectionner un ou plusieurs type(s) de fichier(s)
          </span>
        </div>
        <div>{isDropdownOpen ? <ArrowUp /> : <ArrowDown />}</div>
      </div>
      {isDropdownOpen && (
        <ul className="bg-white rounded-md absolute z-10 md:w-[400px] left-[-50px] border-0.5 p-3 border-stone-400 flex-1 flex flex-col">
          <li
            onClick={toggleSelectAll}
            className="font-semibold text-main-color mb-3 px-3 py-1 text-sm"
          >
            <span className={`flex items-center`}>
              <input
                type="checkbox"
                checked={selectAll}
                onChange={toggleSelectAll}
              />
              <span className="ml-2 text-sm">
                Sélectionner un ou plusieurs type(s) de fichier
              </span>
            </span>
          </li>
          {options.map(({ label, value, Icon }, index) => (
            <li
              key={index}
              onClick={() => handleOptionSelect(value ?? label)}
              className={
                selectedValues.includes(value ?? label)
                  ? "text-main-color font-semibold bg-light-color rounded-md border-0.5 px-3 py-1 mb-2 border-stone-400"
                  : "rounded-md border-0.5 px-3 py-1 mb-2 border-stone-400"
              }
            >
              <span className={`flex items-center`}>
                <input
                  type="checkbox"
                  checked={selectedValues.includes(value ?? label)}
                  onChange={() => handleOptionSelect(value ?? label)}
                />
                <span className="ml-3 text-sm flex items-center py-1">
                  <span className="inline-block mr-3">
                    <Icon />
                  </span>
                  {label}
                </span>
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
MultiSelectDropdown.propTypes = {
  options: PropTypes.arrayOf(Object).isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};
