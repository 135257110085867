import React, { createContext, useEffect } from "react";
import PropTypes from "prop-types";
import { ReactComponent as AccountIcon } from "images/icons/account.svg";
import { ReactComponent as AideIcon } from "images/icons/aide.svg";
import { ReactComponent as FileIcon } from "images/icons/file.svg";
import { ReactComponent as HomeIcon } from "images/icons/home.svg";
import { ReactComponent as LogoutIcon } from "images/icons/logout.svg";
import { ReactComponent as ClientIcon } from "images/icons/client.svg";
import { ReactComponent as SettingsIcon } from "images/icons/settings.svg";
import { ReactComponent as DepotIcon } from "images/icons/depot.svg";
import { ReactComponent as EuroIcon } from "images/icons/prime.svg";
import { ReactComponent as IndicateurIcon } from "images/icons/indicateur.svg";
import { ReactComponent as ReportingIcon } from "images/icons/reporting.svg";
import { ReactComponent as SimulationIcon } from "images/icons/simulation.svg";
import { ReactComponent as RgeIcon } from "images/icons/rge.svg";
import { ReactComponent as EmailIcon } from "images/icons/c-mail.svg";
import { ReactComponent as PhoneIcon } from "images/icons/c-phone.svg";
import { useAuth } from "providers/AuthProvider";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

const menuContext = createContext();

export const useMenu = () => {
  const context = React.useContext(menuContext);
  if (context === undefined) {
    throw new Error("useMenu must be used within a MenuProvider");
  }
  return context;
};

export const MenuProvider = ({ children }) => {
  const { isCustomer, isManager, isPartner, role } = useAuth();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [menuItemList, setMenuItemList] = React.useState([
    {
      condition: true,
      key: "home",
      items: [
        {
          activePaths: isPartner ? ["/dossiers"] : undefined,
          to: "/",
          logo: HomeIcon,
          label: "Accueil",
          active: true,
        },
      ],
    },
    {
      key: "menu",
      condition: isManager,
      items: [
        {
          to: "/simulations-clients",
          logo: ClientIcon,
          label: "Simulations Clients",
        },
        {
          to: "/dossiers",
          logo: FileIcon,
          label: "Mes dossiers",
        },
        {
          to: "/reporting",
          logo: ReportingIcon,
          label: "Reporting",
        },
        {
          to: "/indicateurs",
          logo: IndicateurIcon,
          label: "Indicateurs",
        },
      ],
    },
    {
      key: "customer",
      condition: isCustomer,
      items: [
        {
          to: "/mes-simulations",
          logo: SimulationIcon,
          label: "Mes simulations",
          phoneLabel: "Simulations",
        },
        {
          to: "/mes-projets",
          logo: FileIcon,
          label: "Mes projets",
          phoneLabel: "Projets",
        },
      ],
    },
    {
      key: "emmy",
      condition: isManager && role !== "Consultation",
      items: [
        {
          to: "/depot-emmy",
          logo: DepotIcon,
          label: "Dépôt Emmy",
        },
        {
          to: "/verser-une-prime",
          logo: EuroIcon,
          label: "Verser une prime",
        },
      ],
    },
    {
      key: "contact",
      condition: isPartner,
      component: function () {
        return (
          <>
            <div
              key="dfsv"
              className={"w-full text-xs items-center flex gap-4 mb-2"}
            >
              <EmailIcon />
              <a
                className={"text-main-color"}
                href="mailto:soregies-cee@soregies.fr"
              >
                soregies-cee@soregies.fr
              </a>
            </div>
            <div
              key="dsfb"
              className={"w-full text-xs  items-center flex gap-4 mb-4"}
            >
              <PhoneIcon className="w-[25px] h-[25px]" />
              <div className=" items-start justify-center flex  flex-col">
                <a className={"text-main-color"} href="tel:+330549605424">
                  05 49 60 54 24
                </a>
                <a className={"text-main-color"} href="tel:+330549447085">
                  05 49 44 70 85
                </a>
              </div>
            </div>
          </>
        );
      },
    },
    {
      key: "flex",
      condition: true,
      component: <div className="flex-grow"></div>,
    },
    {
      key: "compte",
      switch: "compte",
      condition: true,
      items: [
        {
          to: "/compte",
          logo: AccountIcon,
          label: "Mon compte",
          phoneLabel: "Compte",
        },
      ],
    },
    {
      key: "parametres",
      condition: isManager && role === "Administrateur",
      items: [
        {
          to: "/parametres/",
          logo: SettingsIcon,
          label: "Paramètres",
        },
      ],
    },
    {
      key: "aide",
      switch: "aide",
      condition: isCustomer,
      items: [
        {
          className: "hidden md:flex",
          external: true,
          to: "https://www.soregies.fr/concretiser-vos-travaux-partenaires-rge/",
          logo: RgeIcon,
          label: "Nos partenaires RGE",
        },
        {
          external: true,
          to: "https://www.soregies.fr/faq/",
          logo: AideIcon,
          label: "Aide",
        },
      ],
    },
    {
      key: "deconnexion",
      condition: true,
      items: [
        {
          className: "hidden md:flex",
          to: "/deconnexion",
          logo: LogoutIcon,
          label: "Se déconnecter",
        },
      ],
    },
  ]);
  const [currentMenuState, setCurrentMenuState] = React.useState("pc");
  const { pathname } = useLocation();
  const noShadowStyle =
    pathname.includes("mes-simulations") || pathname.includes("mes-projets");
  const showAddSimulation =
    isMobile &&
    !["compte", "cgu", "policy"].some((path) => pathname.includes(path));

  useEffect(() => {
    if (isMobile && currentMenuState === "pc") {
      // Switch postion of "Mon compte" and "aide" when in mobile view
      const indexMonCompte = menuItemList.findIndex(
        (item) => item.switch === "compte",
      );
      const indexAide = menuItemList.findIndex(
        (item) => item.switch === "aide",
      );

      [menuItemList[indexMonCompte], menuItemList[indexAide]] = [
        menuItemList[indexAide],
        menuItemList[indexMonCompte],
      ];

      setMenuItemList([...menuItemList]);
      setCurrentMenuState("mobile");
    } else if (!isMobile && currentMenuState === "mobile") {
      // Switch back postion of "Mon compte" and "aide" when in pc view
      const indexMonCompte = menuItemList.findIndex(
        (item) => item.switch === "compte",
      );
      const indexAide = menuItemList.findIndex(
        (item) => item.switch === "aide",
      );

      [menuItemList[indexMonCompte], menuItemList[indexAide]] = [
        menuItemList[indexAide],
        menuItemList[indexMonCompte],
      ];

      setMenuItemList([...menuItemList]);
      setCurrentMenuState("pc");
    }
  }, [isMobile, menuItemList, currentMenuState, setMenuItemList]);

  const ContextValue = {
    menuItemList,
    noShadowStyle,
    currentMenuState,
    isMobile,
    showAddSimulation,
  };

  return (
    <menuContext.Provider value={ContextValue}>{children}</menuContext.Provider>
  );
};

MenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
