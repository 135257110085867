import React from "react";
import ReactDOM from "react-dom/client";
import "index.scss";
import App from "components/App";
import Login from "components/Auth/Login";
import reportWebVitals from "reportWebVitals";
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import AuthProvider, { AuthGuard, Disconnect } from "providers/AuthProvider";
import Home from "components/Pages/Home";
import Dossiers from "components/Dossiers";
import MyFilesDetails from "components/MyFilesDetails";
import Simulateur from "components/Simulation/Simulateur";
import SimulationClientsDetails from "components/Simulation/SimulationClientsDetails";
import Indicateurs from "components/Indicateur/Indicateurs";
import DepotEmmy from "components/DepotEmmy";
import VerserUnePrime from "components/VerserUnePrime";
import MyAccount from "components/Pages/MyAccount";
import Parametres from "components/Parametres";
import ForgotPassword from "components/Auth/ForgotPassword";
import ResetPassword from "components/Auth/ResetPassword";
import CreateAccount from "components/Auth/CreateAccount";
import EmailConfirmation from "components/Auth/EmailConfirmation";
import ConfigProvider from "providers/ConfigProvider";
import { ApiEndpointProvider } from "ApiEndpointContext";
import { DomainProvider } from "DomainContext";
import ErrorBoundary from "ErrorBoundary";
import Simulations from "components/Pages/Simulations";
import Reporting from "components/Reporting";
import UnimplementedPage from "components/Pages/UnimplementedPage";
import LegalPage from "components/LegalPages/LegalPage";
import * as Sentry from "@sentry/react";
import DataProvider from "providers/DataProvider";
import MobileLoginTitle from "components/MobileLoginTitle";
import PropTypes from "prop-types";

const sentryEnabled = !process.env.REACT_APP_DISABLE_SENTRY;

Sentry.init({
  dsn: sentryEnabled
    ? "https://696e1849c783172041f2de0bd0a6272e@o4508086168125440.ingest.de.sentry.io/4508103480705104"
    : undefined,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/soregies.hellio\.dev/],
  // Session Replay
  replaysSessionSampleRate: 0.1, //  This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, //  If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function Layout({ children }) {
  return (
    <div className="flex md:w-screen md:h-screen md:block p-4 md:p-0 mt-4 md:mt-0 flex-col gap-8 ">
      <MobileLoginTitle />
      {children}
    </div>
  );
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: (
      <ApiEndpointProvider>
        <AuthProvider>
          <Outlet />
        </AuthProvider>
      </ApiEndpointProvider>
    ),
    children: [
      {
        path: "/login",
        element: (
          <AuthGuard connected={false} forceLogout>
            <Layout>
              <Login />
            </Layout>
          </AuthGuard>
        ),
      },
      {
        path: "/forgot-password",
        element: (
          <AuthGuard connected={false} redirectTo="/">
            <Layout>
              <ForgotPassword />
            </Layout>
          </AuthGuard>
        ),
      },
      {
        path: "/reset-password",
        element: (
          <AuthGuard connected={false} forceLogout>
            <Layout>
              <ResetPassword />
            </Layout>
          </AuthGuard>
        ),
      },
      {
        path: "/create-account",
        element: (
          <AuthGuard connected={false} redirectTo="/">
            <Layout>
              <CreateAccount />
            </Layout>
          </AuthGuard>
        ),
      },
      {
        path: "/email-confirmation",
        element: (
          <AuthGuard connected={false} redirectTo="/">
            <EmailConfirmation />
          </AuthGuard>
        ),
      },
      {
        path: "/",
        element: (
          <AuthGuard connected={true} redirectTo="/login">
            <App />
          </AuthGuard>
        ),
        children: [
          {
            path: "/",
            element: <Home />,
          },
          {
            path: "mes-simulations",
            element: <Simulations />,
          },
          {
            path: "mes-simulations/simulateur",
            element: <Simulateur />,
          },
          {
            path: "mes-simulations/archives",
            element: <Simulations archive />,
          },
          {
            path: "simulations-clients",
            element: <Simulations />,
          },
          {
            path: "simulations-clients/:fileID",
            element: <SimulationClientsDetails />,
          },
          {
            path: "dossiers/:fileID",
            element: <MyFilesDetails />,
          },
          {
            path: "dossiers/:fileID/:tab",
            element: <MyFilesDetails />,
          },
          {
            path: "mes-projets/:fileID",
            element: <MyFilesDetails />,
          },
          {
            path: "mes-projets/:fileID/:tab",
            element: <MyFilesDetails />,
          },
          {
            path: "dossiers",
            element: <Dossiers />,
          },
          {
            path: "mes-projets",
            element: <Dossiers />,
          },
          {
            path: "depot-emmy",
            element: <DepotEmmy />,
          },
          {
            path: "indicateurs",
            element: <Indicateurs />,
          },
          {
            path: "verser-une-prime",
            element: <VerserUnePrime />,
          },

          {
            path: "/reporting",
            element: <Reporting />,
          },

          {
            path: "/parametres/",
            element: <Parametres />,
          },

          {
            path: "/parametres/:tabID",
            element: <Parametres />,
          },
          {
            path: "/parametres/:tabID/:userID",
            element: <Parametres />,
          },
          {
            path: "actualite",
            element: <UnimplementedPage title="Actualité" />,
          },
          {
            path: "compte",
            element: <MyAccount />,
          },
          {
            path: "mention",
            element: <LegalPage page="mention" />,
          },
          {
            path: "cgu",
            element: (
              <LegalPage page="cgu" downloadUrl="/CGU SORÉGIES CEE.pdf" />
            ),
          },
          {
            path: "policy",
            element: (
              <LegalPage
                page="policy"
                downloadUrl="/Politique de Confidentialité SORÉGIES CEE.pdf"
              />
            ),
          },
          {
            path: "cgv",
            element: <LegalPage page="cgv" />,
          },
          // {
          //   path: "faq",
          //   element: <LegalPage page="faq" />,
          // },

          {
            path: "deconnexion",
            element: <Disconnect />,
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ConfigProvider>
        <DomainProvider>
          <DataProvider>
            <RouterProvider router={router} />
          </DataProvider>
        </DomainProvider>
      </ConfigProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
