import React from "react";
import { ReactComponent as Logo } from "images/logo_simple.svg";

function MobileLoginTitle() {
  return (
    <>
      <div className="md:hidden flex items-center justify-center gap-2">
        <Logo className="w-8 h-8 md:hidden" />
        <span className="text-Bleu-fonc-Soregies text-center font-poppins text-xs font-semibold leading-5 tracking-tighter md:hidden">
          Espace bénéficiare
        </span>
      </div>
      <div className="md:hidden flex flex-col w-full text-center font-semibold items-center justify-center gap-6 text-Bleu-fonc-Soregies">
        <h1 className="font-poppins text-base leading-6">
          Bienvenue sur votre
          <br />
          Espace bénéficiaire Sorégies
        </h1>
        <h2 className="font-inter text-xs leading-4.5">
          Les primes économies d’énergie
          <br />
          Une aide pour vos travaux
        </h2>
      </div>
    </>
  );
}

export default MobileLoginTitle;
