import React, { useState, useCallback } from "react";
import { useAuth } from "providers/AuthProvider";
import { DossierList } from "components/DossierList";
import Main from "components/Main";
import Search from "components/Search";
import { removeAccents } from "utils/string";

export default function Dossiers() {
  const [search, setSearch] = useState("");
  const { isManager, isPartner, isCustomer } = useAuth();

  const setFilters = useCallback(() => {
    if (search) {
      const cleanSearch = search.trim();
      const searchWords = cleanSearch.split(/\s+/).filter((str) => str !== "");

      const mapKeywords = (field, subFields) => ({
        $and: searchWords.map((word) => ({
          $or: subFields.map((subField) => ({
            [field]: {
              [subField]: {
                $containsi: subField === "slug" ? removeAccents(word) : word,
              },
            },
          })),
        })),
      });

      if (isManager || isPartner) {
        // Recherche par nom et prénom du bénéficiaire ou par référence
        return {
          $or: [
            {
              reference: { $containsi: cleanSearch },
            },
            mapKeywords("beneficiaire", ["slug"]),
          ],
        };
      } else {
        // Recherche par nom d'offre ou adresse du chantier
        return {
          $or: [
            { nom_offre: { $containsi: cleanSearch } },
            mapKeywords("chantiers", ["adresse", "ville", "code_postal"]),
          ],
        };
      }
    }
    return {};
  }, [isManager, isPartner, search]);

  return (
    <Main
      title={isCustomer ? "Mes projets" : "Mes dossiers"}
      header={
        <Search
          setSearch={setSearch}
          placeholder={
            isManager || isPartner
              ? "Bénéficiaire ou référence…"
              : "Recherche opération ou chantier…"
          }
        />
      }
    >
      <DossierList setFilters={setFilters} />
    </Main>
  );
}

Dossiers.defaultProps = {};

Dossiers.propTypes = {};
