import React, { useState, useEffect } from "react";
import { useAuth } from "providers/AuthProvider";
import { Link, useSearchParams } from "react-router-dom";
import Input from "components/Input";
import AuthForm from "./AuthForm";
import { useConfig } from "providers/ConfigProvider";

import { z } from "zod";
import formErrors from "constants/formErrors";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormControl, FormField, FormItem, FormMessage } from "components/Form";
import { translateMessage } from "functions";

const formSchema = z.object({
  identifier: z
    .string({ message: formErrors.requiredErrorMessage })
    .email({ message: formErrors.emailErrorMessage }),
  password: z.string({ message: formErrors.requiredErrorMessage }),
});

export default function Login() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultValues] = useState({
    email: searchParams.get("email"),
    simulationId: searchParams.get("simulationId"),
    simulationCode: searchParams.get("simulationCode"),
    token: searchParams.get("token"),
    error: searchParams.get("error"),
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const { isCustomerUrl } = useConfig();
  const auth = useAuth();

  const form = useForm({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      identifier: defaultValues.email ?? "",
      password: "",
    },
  });

  useEffect(() => {
    if (defaultValues.error === "expired") {
      form.setError("identifier", {
        type: "manual",
        message: formErrors.tokenErrorMessage,
      });
    }
  }, [form, defaultValues.error]);

  useEffect(() => {
    setSearchParams({});
  }, [setSearchParams]);

  // Auto login avec le token
  useEffect(() => {
    if (defaultValues.token) {
      auth.login(null, null, defaultValues);
    }
  }, [defaultValues, auth]);

  const submit = async ({ identifier, password }) => {
    try {
      setLoading(true);
      await auth.login(identifier, password, defaultValues);
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setLoading(false);
      form.setValue("password", "");
      form.setError("password", {
        type: "manual",
        message: translateMessage(error.message),
      });
    }
  };

  const cancelProps = isCustomerUrl
    ? {
        cancelUrl: "/create-account",
        cancelText: "Je n'ai pas encore de compte, je crée un compte.",
      }
    : {};

  return (
    <AuthForm
      title="Connexion"
      submitText="Se connecter"
      submit={form.handleSubmit(submit)}
      loading={loading}
      success={success}
      {...cancelProps}
      {...form}
    >
      <FormField
        name="identifier"
        control={form.control}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                className="w-full"
                label="E-mail"
                placeholder="E-mail"
                disabled={form.formState.isSubmitting}
                required
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        name="password"
        control={form.control}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                className="w-full"
                label="Mot de passe"
                type="password"
                autoComplete="on"
                placeholder="Mot de passe"
                disabled={form.formState.isSubmitting}
                required
                {...field}
              />
            </FormControl>
            <FormMessage />
            <Link
              className="text-light-color underline text-xs"
              to="/forgot-password"
            >
              Mot de passe oublié
            </Link>
          </FormItem>
        )}
      />
    </AuthForm>
  );
}
