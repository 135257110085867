import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ConditionChecker from "./ConditionChecker";

const TruncatedFileName = ({ filename, maxLength = 10, className }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isTruncated = filename?.length > maxLength;
  const name = isTruncated
    ? filename.substring(0, maxLength) + "..."
    : filename;
  const spanRef = useRef(null);

  const handleClick = () => {
    if (isTruncated) {
      setIsExpanded(!isExpanded);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      // Si on est en mode étendu et qu'on clique ailleurs que sur notre élément
      if (
        isExpanded &&
        spanRef.current &&
        !spanRef.current.contains(e.target)
      ) {
        setIsExpanded(false);
      }
    };

    // Ajouter les écouteurs pour mobile et desktop
    document.addEventListener("mouseup", handleClickOutside);

    // Nettoyage des écouteurs quand le composant se démonte
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [isExpanded]); // Ne se déclenche que si isExpanded change

  if (!filename) return null;

  return (
    <span
      ref={spanRef}
      onClick={handleClick}
      title={filename}
      className={`cursor-pointer relative ${className}`}
    >
      <ConditionChecker condition={isTruncated && isExpanded}>
        <div className="absolute bg-black/70 p-2 whitespace-normal rounded-md text-white top-0 left-0 min-w-56 -translate-y-3/4 w-full ">
          {filename}
        </div>
      </ConditionChecker>
      {name}
    </span>
  );
};

TruncatedFileName.propTypes = {
  filename: PropTypes.string.isRequired,
  className: PropTypes.string,
  maxLength: PropTypes.number,
};

export default TruncatedFileName;
