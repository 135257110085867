import { useMemo, useCallback } from "react";
import { useApiEndpoint } from "ApiEndpointContext";
import { useAuth } from "providers/AuthProvider";

/**
 * Renvoie la fonction permettant d'apeller l'API Strapi, ainsi qu'une fonction abort.
 * @returns {function[]} fetchApi, abort
 */
export default function useFetchApi() {
  const apiEndpoint = useApiEndpoint();
  const auth = useAuth();
  const abortController = useMemo(() => new AbortController(), []);
  const abort = abortController.abort;

  /**
   * Apelle l'API Strapi
   * @param {string} path Chemin vers le endpoint de l'API Strapi (auth/change-password)
   * @param {object} params Paramètres de la requête
   * @param {object} [params.params] Paramètres de la requête
   * @param {object} [params.body] Corps de la requête (POST)
   * @param {object} [params.method] Méthode HTTP
   * @param {object} [params.options] Options à fournir à la fonction fetch
   * @param {boolean} [params.raw=false] Renvoie le résultat brut de fetch si true
   * @param {string} [params.token] Token de connexion
   * @returns {object} Résultat de la requête
   * @throws {object} Erreur de l'API
   * @throws {AbortError} Si le composant a été unmount pendant l'appel
   */
  const fetchApi = useCallback(
    async (
      path,
      { params, body, method, options = {}, raw = false, token } = {},
    ) => {
      const searchParams = params ? `?${new URLSearchParams(params)}` : "";
      const res = await fetch(`${apiEndpoint}/api/${path}${searchParams}`, {
        signal: abortController.signal,
        method: method ?? (body ? "POST" : "GET"),
        headers: {
          "Content-Type": "application/json",
          ...(token || auth?.token
            ? { Authorization: `Bearer ${token || auth.token}` }
            : {}),
        },
        ...(body ? { body: JSON.stringify(body) } : {}),
        ...options,
      });
      if (raw) {
        return res;
      }
      if (res.ok) {
        return await res.json();
      }

      if (res.status === 401) {
        return window.location.replace(`${window.location.origin}/login`);
      }

      let data;
      try {
        data = await res.json();
      } catch (e) {
        throw new Error(res.statusText);
      }
      throw data?.error ?? data;
    },
    [abortController, apiEndpoint, auth?.token],
  );

  return [fetchApi, abort];
}
